import { Helmet } from "react-helmet"

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Field, Form, HousingAlert, Icon, Sender } from "@garantidos/components"

import If from "@mobi/components/If"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import AriaSelect from "../../components/AriaSelect"
import Splitted from "../../components/Splitted"

import useComplementaryLead from "./hooks"

import "./style.scss"

import { fields } from "./fields"

const ComplementaryLead = () => {
  const {
    form,
    errorMessage,
    setErrorMessage,
    onSubmitCallback,
    handlePrevStep,
    hasBankData,
    isMobile,
    handleSelectChange,
    onlyNumbersOnInput,
    title
  } = useComplementaryLead()

  const {
    documentType,
    complementaryDocument,
    issuingDate,
    issuingAgency,
    bankData,
    bankCode,
    bankAgencyNumber,
    bankAccountNumber,
    bankAccountDigitNumber
  } = fields

  const { isValid } = form

  return (
    <div className="complementaryLead">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Splitted
        contentType="lead"
        data-aos="fade"
        data-aos-duration="150"
        data-aos-offset="-1000"
      >
        <div
          className="complementaryLead__formWrapper"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-offset="-1000"
        >
          <Stepper title="conte mais sobre você" index={2} max={4} />
          <HousingAlert
            message={errorMessage}
            setMessage={setErrorMessage}
            focusOnText
            type="error"
          />
          <Text as="h1" className="complementaryLead__title">
            agora, precisamos dos seus documentos para continuar
          </Text>
          <Form onSubmit={onSubmitCallback} form={form}>
            <Text as="h2" className="complementaryLead__text">
              documento
            </Text>
            <Field
              field={documentType}
              form={form}
              required
              native
              data-testid={documentType.name}
              onChange={(e) => handleSelectChange("TipoDoDocumento", e.value)}
            />
            <Field
              field={complementaryDocument}
              form={form}
              type="tel"
              inputmode="numeric"
              maxLength={20}
              required
              data-testid={complementaryDocument.name}
            />
            <div className="complementaryLead__fieldColumns">
              <Field
                field={issuingDate}
                form={form}
                data-testid={issuingDate.name}
                aria-labelledby=""
                aria-label="data de emissão, dia: 2 dígitos, mês: 2 dígitos, ano: 4 dígitos"
              />
              <Field
                field={issuingAgency}
                form={form}
                maxLength={30}
                required
                data-testid={issuingAgency.name}
              />
            </div>
            <Text as="h2" className="complementaryLead__textBank">
              dados bancários
            </Text>
            <div className="complementaryLead__card">
              <Icon
                icon="conta-corrente"
                className="complementaryLead__cardIcon"
              />
              <Text as="p">
                informe a conta para resgate de valores no encerramento do
                grupo.
              </Text>
            </div>

            <Text as="p" className="complementaryLead__radioLabel">
              deseja informar seus dados bancários?
            </Text>
            <div className="complementaryLead__radioButton" role="radiogroup">
              <Field
                field={bankData}
                form={form}
                required
                onInput={(e) =>
                  handleSelectChange("DadosBancarios", e.target.value)
                }
              />
            </div>

            <If
              condition={hasBankData}
              renderIf={
                <>
                  <AriaSelect
                    form={form}
                    field={bankCode}
                    label={bankCode.label}
                    placeholder={bankCode.placeholder}
                    name={bankCode.name}
                    options={bankCode.options}
                    searchPlaceholder={bankCode.searchPlaceholder}
                    onChange={(value) =>
                      handleSelectChange("SeuBanco", value, "bankCode", true)
                    }
                  />

                  <div className="complementaryLead__fieldColumns">
                    <Field
                      field={bankAgencyNumber}
                      form={form}
                      type="tel"
                      inputmode="numeric"
                      maxLength="9"
                      onInput={onlyNumbersOnInput}
                    />
                    <Field
                      field={bankAccountNumber}
                      form={form}
                      type="tel"
                      inputmode="numeric"
                      maxLength="20"
                      onInput={onlyNumbersOnInput}
                    />
                    <Field
                      field={bankAccountDigitNumber}
                      form={form}
                      type="tel"
                      inputmode="numeric"
                      maxLength="2"
                    />
                  </div>
                </>
              }
            />
            <Sender
              onPrev={handlePrevStep}
              isNextDisabled={!isValid}
              data_testid="submit"
            />
          </Form>
        </div>
      </Splitted>
    </div>
  )
}

export default ComplementaryLead

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:ComoFuncioOConsorcio",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "3-two-way",
      step_name: "two-way",
      step_order: 3
    }
  }

  trackPageview({ ga, segment })
}

const advance = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ComoFuncioOConsorcio:Simular",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ComoFuncioOConsorcio"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "two-way"
    }
  }

  trackEvent({ ga, segment })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ComoFuncioOConsorcio:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ComoFuncioOConsorcio"
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "two-way"
    }
  }

  trackEvent({ ga, segment })
}

export { pageview, advance, back }

import { trackEvent } from "@garantidos/utils/tracking"

const trackAccordion = (trackId) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:ComoFuncioOConsorcio:${trackId}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ComoFuncioOConsorcio"
    }
  }

  const segment = {
    event: "faq item opened",
    data: {
      question: trackId,
      step: "two-way"
    }
  }

  trackEvent({ ga, segment })
}

export { trackAccordion }

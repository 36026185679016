import { objectToList } from "../utils"
import { formatters } from "../utils"

const usePayloadFormatters = (fields) => {
  const formatSendPayload = (payload) => {
    const sanitizedPayload = sanitizePayload(payload)
    const payloadKeys = Object.keys(sanitizedPayload)
    const fieldList = objectToList(fields).filter(({ value: field }) => {
      return payloadKeys.includes(field.name)
    })
    const formattedList = fieldList.map(({ value: field }) => {
      const fieldName = field.name
      const formatter = field.sendFormat
      const options = field.options
      const value = payload[fieldName]
      return [fieldName, formatSendField(value, formatter, options)]
    })
    return Object.fromEntries(formattedList)
  }

  const formatSendField = (value, formatter, options = []) => {
    if (!formatter) return value
    return formatters.sendFormatters[formatter]({ value, options })
  }

  const formatFetchPayload = (payload) => {
    const sanitizedPayload = sanitizePayload(payload)
    const payloadKeys = Object.keys(sanitizedPayload)
    const fieldList = objectToList(fields).filter(({ value: field }) => {
      return payloadKeys.includes(field.name)
    })
    const formattedList = fieldList.map(({ value: field }) => {
      const fieldName = field.name
      const formatter = field.fetchFormat
      const options = field.options
      // const dropdown = field?.type
      const value = payload[fieldName]
      return [fieldName, formatFetchField(value, formatter, options)]
    })
    return Object.fromEntries(formattedList)
  }

  const formatFetchField = (value, formatter, options) => {
    if (!formatter) return value
    return formatters.fetchFormatters[formatter]({ value, options })
  }

  const sanitizePayload = (payload) => {
    const sanitizedPayload = {}
    for (const key in payload) {
      if (payload[key] === undefined) continue
      sanitizedPayload[key] = payload[key]
    }
    return sanitizedPayload
  }

  return { formatSendPayload, formatFetchPayload }
}

export default usePayloadFormatters

import { useContext } from "react"

// Contexts
import { getProposalContext } from "@garantidos/contexts"

// Steps
import * as stepsData from "../pages/Proposal/steps"

const { ProposalContext, ProposalDataProvider } = getProposalContext({
  stepsData
})

const useProposalContext = () => {
  return useContext(ProposalContext)
}

const useProposalDataProvider = () => {
  return ProposalDataProvider
}

export { useProposalContext, useProposalDataProvider, ProposalContext }

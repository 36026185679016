import { useEffect, useMemo, useState } from "react"

const OPTIMIZE_LOAD_TIMEOUT = 10000

const useOptimize = (experimentId) => {
	const [ready, setReady] = useState(false)
	const [variant, setVariant] = useState(undefined)
	const [optimizeLoadTimeout, setOptimizeLoadTimeout] = useState()

	const clearOptimizeLoadTimeout = () => {
		if (optimizeLoadTimeout === undefined) return
		clearTimeout(optimizeLoadTimeout)
	}

	const setVariantByValue = (value) => {
		setReady(true)
		clearOptimizeLoadTimeout()
		setVariant(typeof value !== "string" ? "0" : value)
	}

	const setVariantFromGlobalState = () => {
		setVariantByValue(window.google_optimize.get(experimentId))
	}

	const setOptimizeCallback = ({ callback, remove }) => {
		typeof window !== "undefined" &&
			window.gtag &&
			window.gtag("event", "optimize.callback", {
				name: experimentId,
				callback,
				remove
			})
	}

	useEffect(() => {
		setOptimizeLoadTimeout(
			setTimeout(() => setVariantFromGlobalState(), OPTIMIZE_LOAD_TIMEOUT)
		)

		setOptimizeCallback({ callback: setVariantByValue })

		return () => {
			setOptimizeCallback({ callback: setVariantByValue, remove: true })
		}
	}, [])

	return { ready, variant }
}

export default useOptimize

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const contactTypeParser = {
  PHONE: {
    gaText: "ContatoTelefone"
  },
  WHATSAPP: {
    gaText: "ContatoWhatsApp"
  }
}

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:ResumoDoConsorcio",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "7-summary-simulation",
      step_name: "summary-simulation",
      step_order: 7
    }
  }

  trackPageview({ ga, segment })
}

const knowMore = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ResumoDoConsorcio:ConhecerMelhorConsorcio",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ResumoDoConsorcio"
    }
  }

  trackEvent({ ga })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ResumoDoConsorcio:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ResumoDoConsorcio"
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "summary-simulation"
    }
  }

  trackEvent({ ga, segment })
}

const advance = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ResumoDoConsorcio:IniciarContratacao",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ResumoDoConsorcio"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "summary-simulation"
    }
  }

  trackEvent({ ga, segment })
}

const faqItem = (trackId) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:ResumoDoConsorcio:${trackId}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ResumoDoConsorcio"
    }
  }

  trackEvent({ ga })
}

const dropFlow = (contactType) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:ResumoDoConsorcio:${contactTypeParser[contactType]?.gaText}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ResumoDoConsorcio"
    }
  }

  trackEvent({ ga })
}

const submitForm = () => {}

const contactClicked = (contactType) => {
  const segment = {
    event: "asset not chosen",
    data: {
      step: "summary-simulation",
      contact_type: contactType
    }
  }

  trackEvent({ segment })
}

const hiringStarted = (proposalData) => {}

export {
  pageview,
  knowMore,
  back,
  advance,
  faqItem,
  dropFlow,
  submitForm,
  contactClicked,
  hiringStarted
}

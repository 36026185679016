export const assetsCard = {
  "VEICULOS LEVES": {
    icon: "veiculo",
    image: {
      filename: "consorcio-categoria-carros",
      alt: "Ilustração de uma mulher dirigindo um carro com uma passageira ao lado"
    }
  },
  IMOVEL: {
    icon: "home",
    image: {
      filename: "consorcio-categoria-imoveis",
      alt: "Ilustação de um homem segurando uma xícara de café visto através de um janela aberta"
    }
  },
  MOTOCICLETAS: {
    icon: "moto",
    image: {
      filename: "consorcio-categoria-motos",
      alt: "Ilustração de um motoqueiro visto através de um retrovisor"
    }
  },
  "VEICULOS PESADOS": {
    icon: "caminhao",
    image: {
      filename: "consorcio-categoria-pesados",
      alt: "Ilustração de um homem pilotando um caminhão visto de lado"
    }
  }
}

import Checkbox from "@mobi/ds/components/Checkbox"
import Input from "@mobi/ds/components/Input"
import Radio from "@mobi/ds/components/Radio"
import Select from "@mobi/ds/components/Select"

import CustomRadioOption from "./components/CustomRadio"
import HiddenRadioOption from "./components/HiddenRadio"
import IdsCurrency from "./components/IdsCurrency"
import IdsSelect from "./components/IdsSelect"

const fieldComponents = {
  text: Input.Text,
  name: Input.Name,
  email: Input.Email,
  cellphone: Input.Cellphone,
  currency: Input.Currency,
  "ids-currency": IdsCurrency,
  quantity: Input.Quantity,
  birthdate: Input.Birthdate,
  quantity: Input.Quantity,
  cpf: Input.Cpf,
  cnpj: Input.Cnpj,
  cep: Input.Cep,
  token: Input.Token,
  radio: Radio,
  checkbox: Checkbox,
  customRadio: Radio,
  hiddenRadio: Radio,
  select: Select,
  "ids-select": IdsSelect,
}

const radioTypes = ["radio", "customRadio", "hiddenRadio"]

const customRadioClasses = {
  customRadio: "fieldCustomRadio",
  hiddenRadio: "fieldHiddenRadio",
}

const radioChildren = {
  customRadio: CustomRadioOption,
  hiddenRadio: HiddenRadioOption,
}

export { fieldComponents, radioTypes, customRadioClasses, radioChildren }

import IconAssessoria from "@mobi/ds/static/icons/outlined/assessoria.svg"
import IconBeneficios from "@mobi/ds/static/icons/outlined/beneficios.svg"
import IconDebito from "@mobi/ds/static/icons/outlined/debito.svg"

import IconAplicativos from "icons/apps-store.svg"
import IconCard from "icons/card.svg"
import IconDownload from "icons/download.svg"
import IconMobileAdd from "icons/mobile-add.svg"

export const benefitsItems = [
  {
    icon: (
      <IconBeneficios
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "Ofertas exclusivas para correntistas"
  },
  {
    icon: (
      <IconDebito
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "Pague via débito automático"
  },
  {
    icon: (
      <IconAplicativos
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "Preenchimento simplificado dos campos para a contratação"
  },
  {
    icon: (
      <IconAssessoria
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "Dê lance, acompanhe assembleias e mais pelo app"
  }
]

export const nextStepsItems = [
  {
    icon: (
      <IconDownload
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "aponte a câmera do seu celular para o QR ao lado"
  },
  {
    icon: (
      <IconMobileAdd
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "acesse o app e faça o seu login"
  },
  {
    icon: (
      <IconCard
        width={20}
        height={20}
        className="redirectApp__listItemIcon"
        aria-hidden
      />
    ),
    text: "continue a sua simulação pelo App"
  }
]

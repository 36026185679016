import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import If from "@mobi/components/If"

import { getStaticImageByFilename } from "./helpers"

const Img = ({ file, ...props }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    formats: [PNG]
                    breakpoints: [750, 1080, 1366, 1920]
                    quality: 100
                  )
                }
              }
            }
          }
        }
      `}
      render={({ images }) => {
        const image = getStaticImageByFilename({
          images,
          filename: file
        })
        return (
          <If
            condition={image}
            renderIf={
              <GatsbyImage
                image={getImage(image?.node?.childImageSharp)}
                {...props}
              />
            }
          />
        )
      }}
    />
  )
}

export default Img

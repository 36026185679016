import { Header } from "@garantidos/components"

import { getLogoUrl, getStepsPrevUrl } from "utils"

const StepsHeader = () => {
  return (
    <Header
      home={false}
      prevUrl={getStepsPrevUrl()}
      logoUrl={getLogoUrl()}
      prevLabel="voltar para o início"
    />
  )
}

export default StepsHeader

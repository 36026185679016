import { useEffect, useState } from "react"

import { getVWOVariantPromise } from "@garantidos/utils"

/**
 * @param {string} experimentId o ID do teste a/b cadastrado na plataforma da VWO, ex: "8"
 * @returns {string} ex: "1"
 */

function useVWOVariant({ experimentId }) {
  const [isLoading, setIsLoading] = useState(true)
  const [variantValue, setVariantValue] = useState("")

  useEffect(() => {
    async function handleGetVariant() {
      setIsLoading(true)
      try {
        const combinationChosen = await getVWOVariantPromise({ experimentId })
        setVariantValue(combinationChosen)
        setIsLoading(false)
      } catch {
        setVariantValue(undefined)
        setIsLoading(false)
      }
    }

    handleGetVariant()
  }, [experimentId])

  return { variantValue, isLoading }
}

export default useVWOVariant

import { Helmet } from "react-helmet"

import { Field, Form, HousingAlert } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import useAlternativeFlow from "./hooks"

import "./style.scss"

const AlternativeFlow = () => {
  const {
    pageTitle,
    image,
    title,
    info,
    Buttons,
    form,
    fields,
    isMobile,
    onSubmitCallback,
    errorMessage,
    setErrorMessage,
    assetLimits,
    proposalData,
    errorCode
  } = useAlternativeFlow()

  const { assetValue } = fields
  return (
    <div
      className="alternativeFlow"
      data-aos="fade"
      data-aos-duration="150"
      data-aos-offset="-1000"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="alternativeFlow__contentContainer">
        <div className="alternativeFlow__imageContainer">
          <If condition={isMobile}>
            <HousingAlert
              message={errorMessage}
              setMessage={setErrorMessage}
              focusOnText
              type="error"
            />
          </If>

          <Img
            file={image.filename}
            alt={image.alt}
            className="alternativeFlow__image"
            width={isMobile ? "202px" : "539px"}
            height={isMobile ? "134px" : "358px"}
            aria-hidden
          />
        </div>

        <div className="alternativeFlow__infoContainer">
          <If condition={!isMobile}>
            <div className="alternativeFlow__errorAlertContainer">
              <HousingAlert
                message={errorMessage}
                setMessage={setErrorMessage}
                focusOnText
                type="error"
              />
            </div>
          </If>

          <div className="alternativeFlow__textContainer">
            <Text as="h1" className="alternativeFlow__title">
              {title}
            </Text>
            {info.map((text) => (
              <Text
                key={text.substring(5, 10)}
                as="p"
                className="alternativeFlow__text"
              >
                {text}
              </Text>
            ))}
          </div>
          <If
            condition={errorCode !== errorsEnum.CPF_NO_GROUP}
            renderIf={
              <Form
                onSubmit={onSubmitCallback}
                form={form}
                className="alternativeFlow__form"
              >
                <Text as="h2" className="alternativeFlow__label">
                  qual é o valor do bem desejado?
                </Text>
                <Field
                  field={assetValue}
                  form={form}
                  data-testid={assetValue.name}
                  required
                  description={`digite um valor entre ${assetLimits.min} e ${assetLimits.max}`}
                />
                <div className="alternativeFlow__buttonsWrapper">{Buttons}</div>
              </Form>
            }
          />

          <If
            condition={errorCode === errorsEnum.CPF_NO_GROUP}
            renderIf={
              <div className="alternativeFlow__textContainer">
                <div className="alternativeFlow__buttonsWrapper">{Buttons}</div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default AlternativeFlow

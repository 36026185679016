import { Icon } from "@garantidos/components/"

import Text from "@mobi/ds/components/Text"

import WomanMotorcycle from "images/woman-motorcycle-02.svg"

import "./style.scss"

import { headerTexts } from "./data"

const FinishedHeader = ({ hasCredit, hasAsset }) => {
  const checkMode = () => {
    if (hasAsset) return "afterAsset"

    if (hasCredit) return "default"

    return "rejectedCpf"
  }

  const { title, subtitle, icon } = headerTexts[checkMode()]

  return (
    <div className="finishedHeader">
      <div className="finishedHeader__container">
        <div className="finishedHeader__content">
          <Icon icon={icon} className="finishedHeader__icon" />
          <Text as="h1" className="finishedHeader__text">
            {title}
          </Text>
          <Text as="p" className="finishedHeader__subtitle">
            {subtitle}
          </Text>
        </div>
        <WomanMotorcycle
          className="finishedHeader__hightlightImage"
          aria-hidden
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="500"
          data-aos-offset="-1000"
        />
      </div>
    </div>
  )
}

export default FinishedHeader

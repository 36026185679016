import { formatters } from "@garantidos/utils"
import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

import removeSpecialChars from "@mobi/utils/formatters/removeSpecialChars"

const { removeWhiteSpaces } = formatters

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:DadosComplementares",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "8-proposal-lead",
      step_name: "proposal-lead",
      step_order: "08"
    }
  }

  trackPageview({ ga, segment })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:DadosComplementares:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:DadosComplementares"
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "proposal-lead"
    }
  }

  trackEvent({ ga, segment })
}

const advance = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:DadosComplementares:Proximo",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:DadosComplementares"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "proposal-lead"
    }
  }

  trackEvent({ ga, segment })
}

const politicallyExposed = () => {}

const selectChange = (property, value) => {
  if (typeof value === "boolean") {
    value = value ? "Sim" : "Nao"
  }

  if (!property || !value || typeof value !== "string") return

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:DadosComplementares:${property}:${removeSpecialChars(
      removeWhiteSpaces(value.normalize("NFD"))
    )}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:DadosComplementares"
    }
  }

  trackEvent({ ga })
}

export { pageview, back, advance, politicallyExposed, selectChange }

import { useEffect, useState } from "react"

import { proposalHttpController } from "@garantidos/services"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useLikerComponent = () => {
  const [eventLabel, setEventLabel] = useState(null)
  const [likertComment, setLikertComment] = useState(null)

  const { proposalData, errorHandler } = useProposalContext()

  const { fromError } = proposalData

  useEffect(() => {
    eventLabel &&
      trackers.likertInteraction(eventLabel, fromError, likertComment)
  }, [eventLabel])

  const handleSubmitCallback = async ({ experience, description }) => {
    setEventLabel("CliqueEnviar")
    setEventLabel(description)
    try {
      const payload = {
        likert_rate: experience,
        likert_comment: description
      }
      await proposalHttpController({ payload, method: "sendLikert" })
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleExperienceCallback = ({ experience }) => {
    const experienceParser = {
      BAD: "Ruim",
      NOT_SATISFIED: "NaoGostei",
      NOT_SURE: "SeiLa",
      LIKED: "Gostei",
      LOVED: "Adorei"
    }
    setEventLabel(experienceParser[experience])
  }

  const handleOptionCallback = ({ option }) => {
    setEventLabel(`CliqueMelhoria${option}`)
  }

  return {
    handleSubmitCallback,
    handleExperienceCallback,
    handleOptionCallback
  }
}

export default useLikerComponent

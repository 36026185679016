import { trackEvent } from "@garantidos/utils/tracking"

import HeaderDS from "@mobi/ds/components/Header"

const Header = ({
  trackMenuOpenAccount,
  trackOpenAccount,
  utmsHeaderOpenAccount,
  utmsHeaderMenuOpenAccount,
  utmsHeaderMenuCheckingAccount,
  ...props
}) => {
  const handleOpenAccountLink = () => {
    // const trackingInfo = {
    // segment: "button_clicked_personal-account-fluxo-de-origem"
    // }
    // trackEvent(trackingInfo)
    trackEvent(trackOpenAccount)
  }

  const handleMenuOpenAccountLink = () => {
    trackEvent(trackMenuOpenAccount)
  }

  return (
    <HeaderDS
      handleMenuOpenAccountLink={handleMenuOpenAccountLink}
      handleOpenAccountLink={handleOpenAccountLink}
      utmsHeaderOpenAccount={utmsHeaderOpenAccount}
      utmsHeaderMenuOpenAccount={utmsHeaderMenuOpenAccount}
      utmsHeaderMenuCheckingAccount={utmsHeaderMenuCheckingAccount}
      prevRole="button"
      {...props}
    />
  )
}

export default Header

import { useEffect, useState } from "react"

import { getItem, getUrlQueryParams, setItem } from "@garantidos/utils"

const useCrossModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasUrlParams, setHasUrlParams] = useState(false)
  const [hasReferrer, setHasReferrer] = useState(false)
  const [referrerUrl, setReferrerUrl] = useState(null)

  const buttons = {
    primary: {
      action: () => setIsVisible(false),
      label: "continuar"
    }
  }

  useEffect(() => {
    if (getItem("crossModalViewed")) return
    setReferrerUrl(document.referrer)

    const searchUrl = window.location?.search
    const { utm_sou, utm_med } = getUrlQueryParams(searchUrl) || {}

    if (utm_sou === "icarros" && utm_med === "cross-sell") {
      setHasUrlParams(true)
    }
  }, [])

  useEffect(() => {
    referrerUrl?.includes("www.icarros.com.br") && setHasReferrer(true)
  }, [referrerUrl])

  useEffect(() => {
    if (hasReferrer || hasUrlParams) {
      setIsVisible(true)
      setItem("crossModalViewed", true)
    }
  }, [hasReferrer, hasUrlParams])

  return { isVisible, setIsVisible, buttons }
}

export default useCrossModal

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import "./style.scss"

import { contentImage } from "./data"

const Splitted = ({
  contentType = "softlead",
  contentRender = () => {},
  children,
  headerContent = null,
  hasFloatingButton = false,
  ...props
}) => {
  const isMobile = useMediaQuery("mobile")
  const imageCustomClass = `ilustrationSplitted__image--${contentType}`
  const imageComplementCustomClass = `ilustrationSplitted__imageComplement--${contentType}`

  const content = contentImage[contentType] || {}

  return (
    <div
      className={`ilustrationSplitted ${
        hasFloatingButton ? "ilustrationSplitted--floatingButton" : ""
      } `}
      {...props}
    >
      <If
        condition={!isMobile}
        renderIf={
          <div className="ilustrationSplitted__imageWrapper">
            <div className="ilustrationSplitted__content">
              <If
                condition={contentRender.length > 0}
                renderIf={contentRender(content)}
                renderElse={
                  <>
                    <Text as="p" className="ilustrationSplitted__title">
                      {content.title}
                    </Text>
                    <Text as="p" className="ilustrationSplitted__subtitle">
                      {content.subtitle}
                    </Text>
                    <img
                      src={content.image?.url}
                      alt={content.image?.alt}
                      className={`ilustrationSplitted__image ${imageCustomClass}`}
                    />
                    <span
                      className={`ilustrationSplitted__imageComplement ${imageComplementCustomClass}`}
                    />
                  </>
                }
              />
            </div>
          </div>
        }
      />
      <div className="ilustrationSplitted__contentScroll">
        {headerContent}
        <div className="ilustrationSplitted__contentWrapper">
          <div className="ilustrationSplitted__contentInnerContainer">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Splitted

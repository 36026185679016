import { useEffect } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { proposalHttpController } from "@garantidos/services"
import { focusOnLogo, preventNumberOnInput, setItem } from "@garantidos/utils"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import validatorsDictionary from "pages/Proposal/hooks/validators"
import { steps } from "pages/Proposal/steps"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const SMS_ERROR_MESSAGE = "120"

const useSoftlead = () => {
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const { next } = useStep()
  const {
    sendProposalData,
    stepInfo,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    cpfData
  } = useProposalContext()
  const { formatSendPayload } = usePayloadFormatters(fields)
  const { nextStep } = stepInfo
  const { hashedCpf } = cpfData
  const isMobile = useMediaQuery("mobile")

  const {
    softlead: { title }
  } = steps

  const handleLinkTracking = (trackingLabel) => {
    trackers.linkTracking(trackingLabel)
  }

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      trackers.advance()
      trackers.formStarted()

      const payload = formatSendPayload(formData)

      payload.mobile = isMobile
      const response = await sendProposalData({
        payload,
        clearStepNaviagation: false
      })
      const { currentStep } = response.stepNavigation
      setItem("eq3_uuid", response?.stepData?.eq3_uuid)
      next(currentStep)
    } catch (error) {
      if (
        error?.statusCode === 400 &&
        error?.requestMessage === SMS_ERROR_MESSAGE
      ) {
        next(nextStep)
        return
      }
      trackers.modalError(error?.message)
      errorHandler(error)
    }
  }

  useEffect(() => {
    trackers.pageview(hashedCpf)
    focusOnLogo()
  }, [])

  return {
    onSubmitCallback,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    preventNumberOnInput,
    title,
    handleLinkTracking
  }
}

export default useSoftlead

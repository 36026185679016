import { useEffect, useRef, useState } from "react"

import { focusOnLogo } from "@garantidos/utils"

import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { useProposalContext } from "contexts/proposal"

import { categoryOptions, loadingData } from "./data"
import * as trackers from "./trackings"

const EMPTY_GROUP_ERROR_MESSAGE =
  "No momento, não encontramos grupos disponíveis para este cpf"

const useCategory = () => {
  const [loading, setLoading] = useState(false)
  const [loadingDone, setLoadingDone] = useState(false)
  const refNextStep = useRef("")

  const { next, prev } = useStep()
  const {
    stepInfo,
    fetchProposalData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    cpfData
  } = useProposalContext()

  const { redirectToRejectionPage } = useRejectionRedirect()

  const { previousStep, nextStep, currentFlow } = stepInfo

  const { hashedCpf } = cpfData

  const {
    category: { title: pageTitle }
  } = steps

  const setNextStepRef = () => {
    if (!refNextStep.current) refNextStep.current = nextStep
  }

  useEffect(() => {
    setNextStepRef()
    trackers.pageview(hashedCpf)
    focusOnLogo()
  }, [])

  const handleLoadingDone = () => {
    setLoading(false)
    next(refNextStep.current)
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()
      await fetchProposalData({ step: previousStep })

      prev(previousStep)
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handleNextStep = async (category, tracking) => {
    try {
      trackers.categorySelected(tracking, category)
      setLoading(true)

      const payload = { category }

      await sendProposalData({ payload, hasLoading: false })

      setLoadingDone(true)
    } catch (error) {
      setLoading(false)
      if (error?.statusCode === 422) {
        errorHandler(error)
        return
      }
      if (
        error.statusCode === 404 &&
        error.requestMessage === EMPTY_GROUP_ERROR_MESSAGE
      ) {
        redirectToRejectionPage(errorsEnum.CPF_NO_CREDIT)
        return
      }
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  return {
    handlePrevStep,
    handleNextStep,
    errorMessage,
    setErrorMessage,
    title: pageTitle,
    loading,
    loadingDone,
    handleLoadingDone,
    loadingData,
    categoryOptions,
    loaderPageview: trackers.loaderPageview,
    stepperInfo: { index: 1, max: 3 }
  }
}

export default useCategory

const VALUE_RANGE = [
  {
    value: {
      min_asset_value: 0,
      max_asset_value: 50_000
    },
    label: "R$0 a R$50 mil"
  },
  {
    value: {
      min_asset_value: 50_000,
      max_asset_value: 100_000
    },
    label: "R$50 mil a R$100 mil"
  },
  {
    value: {
      min_asset_value: 100_000,
      max_asset_value: 250_000
    },
    label: "R$100 mil a R$250 mil"
  },
  {
    value: {
      min_asset_value: 250_000,
      max_asset_value: 500_000
    },
    label: "R$250 mil a R$500 mil"
  },
  {
    value: {
      min_asset_value: 500_000,
      max_asset_value: 1_000_000
    },
    label: "R$500 mil a R$1 milhão"
  }
]

const UF_OPTIONS = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" }
]

const DOCUMENT_TYPE_OPTIONS = [
  { label: "RG", value: "RG" },
  { label: "CNH", value: "CNH" }
]

const BANK_CODES = [
  {
    value: "1",
    label: "BCO DO BRASIL S.A."
  },
  {
    value: "3",
    label: "BCO DA AMAZONIA S.A."
  },
  {
    value: "4",
    label: "BCO DO NORDESTE DO BRASIL S.A."
  },
  {
    value: "10",
    label: "CREDICOAMO"
  },
  {
    value: "21",
    label: "BCO BANESTES S.A."
  },
  {
    value: "25",
    label: "BCO ALFA S.A."
  },
  {
    value: "33",
    label: "BCO SANTANDER (BRASIL) S.A."
  },
  {
    value: "36",
    label: "BCO BBI S.A."
  },
  {
    value: "37",
    label: "BCO DO EST. DO PA S.A."
  },
  {
    value: "41",
    label: "BCO DO ESTADO DO RS S.A."
  },
  {
    value: "47",
    label: "BCO DO EST. DE SE S.A."
  },
  {
    value: "63",
    label: "BANCO BRADESCARD"
  },
  {
    value: "69",
    label: "BCO CREFISA S.A."
  },
  {
    value: "70",
    label: "BRB - BCO DE BRASILIA S.A."
  },
  {
    value: "74",
    label: "BCO. J.SAFRA S.A."
  },
  {
    value: "77",
    label: "BANCO INTER"
  },
  {
    value: "82",
    label: "BANCO TOPAZIO S.A."
  },
  {
    value: "83",
    label: "BCO DA CHINA BRASIL S.A."
  },
  {
    value: "84",
    label: "UNIPRIME DO BRASIL - COOP"
  },
  {
    value: "85",
    label: "COOPCENTRAL AILOS"
  },
  {
    value: "89",
    label: "CREDISAN CC"
  },
  {
    value: "94",
    label: "BANCO FINAXIS"
  },
  {
    value: "96",
    label: "BCO B3 S.A."
  },
  {
    value: "97",
    label: "CREDISIS CENTRAL DE COOPERATIVAS DE CRED"
  },
  {
    value: "98",
    label: "CREDIALIANCA CCR"
  },
  {
    value: "99",
    label: "UNIPRIME COOPCENTRAL LTDA."
  },
  {
    value: "104",
    label: "CAIXA ECONOMICA FEDERAL"
  },
  {
    value: "107",
    label: "BCO BOCOM BBM S.A."
  },
  {
    value: "114",
    label: "CENTRAL COOPERATIVA DE CREDITO NO ESTADO"
  },
  {
    value: "120",
    label: "BCO RODOBENS S.A."
  },
  {
    value: "121",
    label: "BCO AGIBANK S.A."
  },
  {
    value: "122",
    label: "BCO BRADESCO BERJ S.A."
  },
  {
    value: "124",
    label: "BCO WOORI BANK DO BRASIL S.A."
  },
  {
    value: "125",
    label: "BANCO GENIAL"
  },
  {
    value: "130",
    label: "CARUANA SCFI"
  },
  {
    value: "132",
    label: "ICBC DO BRASIL BM S.A."
  },
  {
    value: "133",
    label: "CRESOL CONFEDERACAO"
  },
  {
    value: "136",
    label: "CONF NAC COOP CENTRAIS UNICRED"
  },
  {
    value: "159",
    label: "CASA CREDITO S.A. SCM"
  },
  {
    value: "173",
    label: "BRL TRUST DTVM SA"
  },
  {
    value: "174",
    label: "PEFISA S.A. - C.F.I."
  },
  {
    value: "177",
    label: "GUIDE"
  },
  {
    value: "189",
    label: "HS FINANCEIRA"
  },
  {
    value: "190",
    label: "SERVICOOP"
  },
  {
    value: "191",
    label: "NOVA FUTURA CTVM LTDA."
  },
  {
    value: "208",
    label: "BANCO BTG PACTUAL S.A."
  },
  {
    value: "212",
    label: "BANCO ORIGINAL"
  },
  {
    value: "213",
    label: "BCO ARBI S.A."
  },
  {
    value: "218",
    label: "BCO BS2 S.A."
  },
  {
    value: "224",
    label: "BCO FIBRA S.A."
  },
  {
    value: "237",
    label: "BCO BRADESCO S.A."
  },
  {
    value: "243",
    label: "BANCO MASTER"
  },
  {
    value: "246",
    label: "BCO ABC BRASIL S.A."
  },
  {
    value: "254",
    label: "PARANA BCO S.A."
  },
  {
    value: "260",
    label: "NU PAGAMENTOS - IP"
  },
  {
    value: "265",
    label: "BCO FATOR S.A."
  },
  {
    value: "266",
    label: "BCO CEDULA S.A."
  },
  {
    value: "269",
    label: "BCO HSBC S.A."
  },
  {
    value: "280",
    label: "WILL FINANCEIRA S.A.CFI"
  },
  {
    value: "281",
    label: "CCR COOPAVEL"
  },
  {
    value: "300",
    label: "BCO LA NACION ARGENTINA"
  },
  {
    value: "318",
    label: "BCO BMG S.A."
  },
  {
    value: "320",
    label: "BCO CCB BRASIL S.A."
  },
  {
    value: "322",
    label: "CCR DE ABELARDO LUZ"
  },
  {
    value: "324",
    label: "CARTOS SCD S.A."
  },
  {
    value: "329",
    label: "QI SCD S.A."
  },
  {
    value: "332",
    label: "ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INST"
  },
  {
    value: "336",
    label: "BCO C6 S.A."
  },
  {
    value: "341",
    label: "ITAU UNIBANCO S.A."
  },
  {
    value: "350",
    label: "CREHNOR LARANJEIRAS"
  },
  {
    value: "376",
    label: "BCO J.P. MORGAN S.A."
  },
  {
    value: "389",
    label: "BCO MERCANTIL DO BRASIL S.A."
  },
  {
    value: "394",
    label: "BCO BRADESCO FINANC. S.A."
  },
  {
    value: "406",
    label: "ACCREDITO SCD S.A."
  },
  {
    value: "412",
    label: "SOCIAL BANK S/A"
  },
  {
    value: "421",
    label: "CC LAR CREDI"
  },
  {
    value: "422",
    label: "BCO SAFRA S.A."
  },
  {
    value: "428",
    label: "CREDSYSTEM SCD S.A."
  },
  {
    value: "456",
    label: "BCO MUFG BRASIL S.A."
  },
  {
    value: "464",
    label: "BCO SUMITOMO MITSUI BRASIL S.A."
  },
  {
    value: "487",
    label: "DEUTSCHE BANK S.A.BCO ALEMAO"
  },
  {
    value: "600",
    label: "BCO LUSO BRASILEIRO S.A."
  },
  {
    value: "604",
    label: "BCO INDUSTRIAL DO BRASIL S.A."
  },
  {
    value: "610",
    label: "BCO VR S.A."
  },
  {
    value: "611",
    label: "BCO PAULISTA S.A."
  },
  {
    value: "613",
    label: "OMNI BANCO S.A."
  },
  {
    value: "623",
    label: "BANCO PAN"
  },
  {
    value: "630",
    label: "BCO LETSBANK S.A."
  },
  {
    value: "633",
    label: "BCO RENDIMENTO S.A."
  },
  {
    value: "634",
    label: "BCO TRIANGULO S.A."
  },
  {
    value: "637",
    label: "BCO SOFISA S.A."
  },
  {
    value: "643",
    label: "BCO PINE S.A."
  },
  {
    value: "653",
    label: "BANCO VOITER"
  },
  {
    value: "654",
    label: "BCO DIGIMAIS S.A."
  },
  {
    value: "655",
    label: "BCO VOTORANTIM S.A."
  },
  {
    value: "707",
    label: "BCO DAYCOVAL S.A"
  },
  {
    value: "739",
    label: "BCO CETELEM S.A."
  },
  {
    value: "741",
    label: "BCO RIBEIRAO PRETO S.A."
  },
  {
    value: "743",
    label: "BANCO SEMEAR"
  },
  {
    value: "745",
    label: "BCO CITIBANK S.A."
  },
  {
    value: "746",
    label: "BCO MODAL S.A."
  },
  {
    value: "748",
    label: "BCO COOPERATIVO SICREDI S.A."
  },
  {
    value: "752",
    label: "BCO BNP PARIBAS BRASIL S A"
  },
  {
    value: "753",
    label: "NOVO BCO CONTINENTAL S.A. - BM"
  },
  {
    value: "755",
    label: "BOFA MERRILL LYNCH BM S.A."
  },
  {
    value: "756",
    label: "BANCO SICOOB S.A."
  },
  {
    value: "757",
    label: "BCO KEB HANA DO BRASIL S.A."
  }
].sort((a, b) => a.label.localeCompare(b.label))

const OCCUPATION_OPTIONS = [
  { value: "26", label: "CONTADOR" },
  { value: "27", label: "ENGENHEIRO" },
  { value: "102", label: "ARQUITETO" },
  { value: "103", label: "AGRONOMO" },
  { value: "104", label: "QUIMICO" },
  { value: "105", label: "DESENHISTA INDUSTRIAL" },
  { value: "106", label: "FISICO" },
  { value: "107", label: "GEOLOGO" },
  { value: "108", label: "TECNOLOGO" },
  { value: "111", label: "MEDICO" },
  { value: "112", label: "VETERINARIO E ZOOTECNISTA" },
  { value: "113", label: "ENFERMEIRO E NUTRICIONISTA" },
  { value: "114", label: "FISIOTERAPEUTA E TERAPEUTA OCUPACIONAL" },
  { value: "115", label: "DENTISTA" },
  { value: "116", label: "BIOLOGO E BIOMEDICO" },
  { value: "117", label: "FARMACEUTICO" },
  { value: "118", label: "FONOAUDIOLOGO" },
  { value: "121", label: "ECONOMISTA" },
  { value: "122", label: "ESTATISTICO" },
  { value: "123", label: "ATUARIO E MATEMATICO" },
  { value: "125", label: "ADMINISTRADOR" },
  { value: "126", label: "ANALISTA DE SISTEMAS" },
  { value: "127", label: "GEOGRAFO" },
  { value: "128", label: "METEOROLOGISTA" },
  { value: "131", label: "ADVOGADO" },
  { value: "132", label: "PSICOLOGO" },
  { value: "133", label: "SOCIOLOGO" },
  { value: "134", label: "ASSISTENTE SOCIAL" },
  {
    value: "135",
    label: "BIBLIOTECARIO, ARQUIVOLOGISTA, MUSEOLOGO E ARQUEOLOGO"
  },
  { value: "136", label: "COMUNICOLOGO" },
  { value: "137", label: "RELACOES PUBLICAS" },
  { value: "138", label: "PROFISSIONAL DE LETRAS E DE ARTES" },
  { value: "142", label: "PROFESSOR DE ENSINO SUPERIOR" },
  { value: "143", label: "PROFESSOR DE ENSINO FUNDAMENTAL E MEDIO" },
  { value: "144", label: "DIRETOR DE ESTABELECIMENTO DE ENSINO" },
  {
    value: "145",
    label: "OUTROS TRABALHADORES DE NIVEL SUPERIOR LIGADOS AO ENSINO"
  },
  { value: "151", label: "TECNICOS DE CONTABILIDADE E DE ESTATISTICA" },
  { value: "152", label: "TECNICOS EM BIOLOGIA" },
  { value: "153", label: "TECNICOS EM AGRIMENSURA, TECNICOS EM AGRONONIA" },
  { value: "154", label: "TECNICOS EM QUIMICA" },
  { value: "155", label: "TECNICOS EM MECANICA" },
  {
    value: "156",
    label:
      "TECNICOS EM ELETRICIDADE, TECNICOS EM ELETRONICA, TECNICOS EM TELECOMUNICACOES"
  },
  { value: "157", label: "TECNICOS EM LABORATORIOS E RAIOS-X" },
  { value: "158", label: "DESENHISTA TECNICO" },
  { value: "159", label: "OUTROS TECNICOS" },
  { value: "161", label: "EMPRESARIO E PRODUTOR DE ESPETACULOS PUBLICOS" },
  { value: "162", label: "ATOR E DIRETOR DE ESPETACULOS PUBLICOS" },
  { value: "163", label: "CANTOR E COMPOSITOR" },
  { value: "164", label: "MUSICO" },
  { value: "165", label: "BAILARINO E COREOGRAFO" },
  {
    value: "166",
    label:
      "COMENTARISTA DE RADIO/TELEVISAO, LOCUTOR DE RADIO/TELEVISAO E RADIALISTA"
  },
  { value: "167", label: "OPERADOR DE CAMERA DE CINEMA E TELEVISAO" },
  { value: "168", label: "ATLETA PROFISSIONAL E TECNICO EM DESPORTOS" },
  { value: "171", label: "JORNALISTA" },
  { value: "172", label: "PUBLICITARIO" },
  { value: "181", label: "PILOTO DE AERONAVES" },
  { value: "182", label: "COMISSARIO DE BORDO" },
  { value: "183", label: "COMANDANTE DE EMBARCACOES" },
  { value: "191", label: "ARTISTA PLASTICO, PINTOR, ESCULTOR E ASSEMELHADOS" },
  { value: "192", label: "DESENHISTA COMERCIAL" },
  { value: "193", label: "DECORADOR" },
  { value: "199", label: "OUTROS DESENHISTAS" },
  {
    value: "201",
    label:
      "DEPUTADO ESTADUAL, FEDERAL, MEMBROS DO PODER LEGISLATIVO, SENADOR E VEREADOR"
  },
  {
    value: "203",
    label:
      "MIN PUBLICO, POD EXEC, SEC MUNIC/EST, PREFEITO, GOV/MIN ESTADO E PRES REPUBLIC"
  },
  {
    value: "205",
    label:
      "DESEMBARGADOR, JUIZ, MEMBROS DO PODER JUDICIARIO, MINISTRO DO TRIBUNAL SUPERIOR"
  },
  { value: "211", label: "PROCURADOR E ASSEMELHADOS" },
  { value: "212", label: "DIPLOMATA" },
  { value: "213", label: "FISCAL" },
  { value: "214", label: "DELEGADO DE POLICIA" },
  {
    value: "215",
    label: "OCUPANTE DE CARGO DE DIRECAO E ASSESSORAMENTO SUPERIOR"
  },
  { value: "216", label: "OFICIAL DAS FORCAS ARMADAS E FORCAS AUXILIARES" },
  {
    value: "291",
    label: "OCUPANTE DE CARGO DE DIRECAO E ASSESSORAMENTO INDUSTRIAL"
  },
  { value: "292", label: "AGENTE ADMISTRATIVO" },
  { value: "293", label: "SERVENTUARIO DA JUSTICA" },
  { value: "294", label: "TABELIAO" },
  { value: "295", label: "MILITAR EM GERAL" },
  { value: "296", label: "SERVIDOR PUBLICO FEDERAL" },
  { value: "297", label: "SERVIDOR PUBLICO ESTADUAL" },
  { value: "298", label: "SERVIDOR PUBLICO MUNICIPAL" },
  { value: "301", label: "DIRETOR DE EMPRESAS" },
  { value: "302", label: "GERENTE" },
  { value: "391", label: "CHEFE INTERMEDIARIO" },
  {
    value: "392",
    label: "TRABALHADOR DOS SERVICOS A CONTABILIDADE, DE CAIXA E ASSEMELHADOS"
  },
  {
    value: "393",
    label:
      "SECRETARIO, ESTENOGRAFO, DATILOGRAFO, RECEPCIONISTA, TELEFONISTA E ASSEMELHADOS"
  },
  { value: "394", label: "AUXILIAR DE ESCRITORIO E ASSEMELHADOS" },
  { value: "395", label: "BANCARIO E ECONOMIARIO" },
  { value: "396", label: "SECURITARIO" },
  {
    value: "401",
    label: "SUPERVISOR, INSPETOR, AGENTE DE COMPRAS E VENDAS E ASSEMELHADOS"
  },
  {
    value: "402",
    label: "REPRESENTANTE COMERCIAL, CAIXEIRO VIAJANTE, VENDEDOR PRACISTA"
  },
  { value: "403", label: "CORRETOR DE IMOVEIS, SEGUROS E TITULOS E VALORES" },
  { value: "404", label: "LEILOEIRO, AVALIADOR E ASSEMELHADOS" },
  { value: "405", label: "AGENCIADOR DE PROPAGANDA" },
  { value: "409", label: "OUTROS COMERCIARIOS" },
  { value: "411", label: "VENDEDOR DE COMERCIO VAREJISTA E ATACADISTA" },
  { value: "412", label: "JORNALEIRO" },
  { value: "413", label: "FEIRANTE" },
  { value: "491", label: "DEMONSTRADOR" },
  { value: "492", label: "MODELO DE MODAS" },
  {
    value: "501",
    label: "PORTEIRO DE EDIFICIO, ASCENSORISTA, GARAGISTA E FAXINEIRO"
  },
  {
    value: "511",
    label:
      "CABELEIREIRO, BARBEIRO, MANICURE, PEDICURE, MAQUILADOR E ESTETICISTA"
  },
  {
    value: "521",
    label: "GOVERNANTA DE HOTEL, CAMAREIRO, PORTEIRO, COZINHEIRO, GARCOM"
  },
  { value: "531", label: "MOTORISTA DE VEICULOS DE TRANSPORTE DE PASSAGEIROS" },
  { value: "532", label: "MOTORISTA DE VEICULOS DE TRANSPORTE DE CARGA" },
  { value: "533", label: "CONTRAMESTRE DE EMBARCACOES" },
  { value: "534", label: "MARINHEIRO E ASSEMELHADOS" },
  {
    value: "535",
    label: "MAQUINISTA E FOGUISTA DE EMBARCACOES, LOCOMOTIVAS E ASSEMELHADOS"
  },
  {
    value: "541",
    label: "MECANICO DE MANUTENCAO DE VEICULOS AUTOMOTORES E MAQUINAS"
  },
  {
    value: "542",
    label:
      "ELETRICISTA MANUT VEICULOS, MAQUINAS/APARELHOS ELETRO-ELETRONICOS E TELECOM"
  },
  {
    value: "543",
    label: "LANTERNEIRO DE VEICULOS METALICOS E PINTOR DE VEICULOS METALICOS"
  },
  {
    value: "544",
    label: "BOMBEIRO DE INSTALACOES DE GAS, AGUA, ESGOTO E ASSEMELHADOS"
  },
  { value: "591", label: "ALFAIATE" },
  { value: "592", label: "PROTETICO" },
  { value: "593", label: "DESPACHANTE E DESPACHANTE AUDUEIRO" },
  { value: "594", label: "AGENTE DE VIAGEM E GUIA DE TURISMO" },
  { value: "595", label: "AGENTE DE SERVICOS FUNERARIOS E EMBALSAMADOR" },
  { value: "596", label: "AUXILIAR DE LABORATORIO" },
  { value: "597", label: "ESTIVADOR, CARREGADOR, EMBALADOR E ASSEMELHADOS" },
  { value: "598", label: "EMPREGADO DOMESTICO" },
  { value: "601", label: "TRABALHADOR AGRICOLA, DA PECUARIA" },
  { value: "603", label: "TRABALHADOR FLORESTAL" },
  { value: "604", label: "PESCADOR (TRABALHO DE PESCA)" },
  { value: "605", label: "GARIMPEIRO" },
  { value: "609", label: "OUTROS TRABALHADORES RURAIS" },
  {
    value: "701",
    label: "CONTRAMESTRE DA PRODUCAO INDUSTRIAL E MESTRE DE PRODUCAO INDUSTRIAL"
  },
  {
    value: "702",
    label:
      "MEC MANUT/MONTADOR INDUSTRIAL, PREPARADOR/OPERADOR DE MAQ/APARELHOS DE PROD IND"
  },
  { value: "703", label: "ELETRICISTA DE PRODUCAO INDUSTRIAL" },
  { value: "704", label: "TRABALHADOR DE INSTALACOES E PROCESSAMENTO QUIMICO" },
  { value: "705", label: "TRABALHADOR DE FABRICACAO DE ROUPAS" },
  {
    value: "706",
    label:
      "TRABALHADOR DE TRATAMENTO DE FUMO E DE FABRICACAO DE CIGARROS E CHARUTOS"
  },
  { value: "707", label: "METALURGICO E SIDERURGICO" },
  { value: "708", label: "TRABALHADOR DE USINAGEM DE METAIS" },
  { value: "709", label: "TRABALHADOR EM CONTRUCAO CIVIL" },
  {
    value: "710",
    label: "TRABALHADOR DE FABRICACAO E PREPARACAO DE ALIMENTOS E BEBIDAS"
  },
  { value: "711", label: "TRABALHADOR EM ARTES GRAFICAS" },
  {
    value: "712",
    label: "TRABALHADOR DE FABRICACAO DE PRODUTOS TEXTEIS (EXCETO ROUPAS)"
  },
  {
    value: "713",
    label: "MARCENEIRO, TRABALHADOR DE FABRICACAO DE ARTEFATOS DE MADEIRA"
  },
  { value: "714", label: "TRABALHADOR DE FABRICACAO DE PAPEL E PAPELAO" },
  {
    value: "715",
    label: "TRABALHADOR DE FABRICACAO DE CALCADOS E ARTEFATOS DE COURO"
  },
  { value: "716", label: "TRABALHADOR DE FABRICACAO DE BORRACHA E PLASTICO" },
  { value: "717", label: "JOALHEIRO E OURIVES" },
  { value: "719", label: "OUTROS TRABALHADORES DA INDUSTRIA" },
  {
    value: "901",
    label: "PROPRIETARIO DE ESTABELECIMENTO AGRICOLA, DA PECUARIA E FLORESTAL"
  },
  { value: "902", label: "PROPRIETARIO DE ESTABELECIMENTO COMERCIAL" },
  { value: "903", label: "PROPRIETARIO DE ESTABELECIMENTO INDUSTRIAL" },
  {
    value: "904",
    label: "PROPRIETARIO DE ESTABELECIMENTO DE PRESTACAO DE SERVICOS"
  },
  { value: "905", label: "PROPRIETARIO DE MICROEMPRESA" },
  {
    value: "906",
    label: "PROPRIETARIO DE IMOVEIS, RECEBENDO RENDIMENTO DE ALUGUEL"
  },
  {
    value: "907",
    label:
      "CAPITALISTA, RECEBENDO RENDIMENTO DE APLICACAO DE CAPITAL EM ATIVOS FINANCEIROS"
  },
  { value: "910", label: "SACERDOTE OU MEMBRO DE SEITAS OU ORDENS RELIGIOSAS" },
  { value: "921", label: "MILITAR REFORMADO" },
  { value: "922", label: "FUNCIONARIO PUBLICO CIVIL APOSENTADO" },
  { value: "923", label: "APOSENTADO (EXCETO FUNCIONARIO PUBLICO)" },
  { value: "924", label: "PENSIONISTA" },
  { value: "931", label: "BOLSISTA, ESTAGIARIO E ASSEMELHADOS" },
  { value: "949", label: "ESPOLIO" },
  { value: "999", label: "OUTROS" }
].sort((a, b) => a.label.localeCompare(b.label))

const MARITAL_STATUS_OPTIONS = [
  { value: "1", label: "Casado(a)" },
  { value: "3", label: "União Estável" },
  { value: "2", label: "Solteiro(a)" },
  { value: "6", label: "Viúvo(a)" },
  { value: "8", label: "Desquitado(a)" },
  { value: "9", label: "Divorciado(a)" },
  { value: "10", label: "Outro" }
]

const COUNTRY_OPTIONS = [
  { value: "Afeganistao", label: "Afeganistão" },
  { value: "Africa do Sul", label: "África do Sul" },
  { value: "Albania", label: "Albânia" },
  { value: "Alemanha", label: "Alemanha" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua e Barbuda", label: "Antígua e Barbuda" },
  { value: "Arabia Saudita", label: "Arábia Saudita" },
  { value: "Argelia", label: "Argélia" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Arménia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Austrália" },
  { value: "Austria", label: "Áustria" },
  { value: "Azerbaijao", label: "Azerbaijão" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bangladexe", label: "Bangladexe" },
  { value: "Barbados", label: "Barbados" },
  { value: "Barem", label: "Barém" },
  { value: "Belgica", label: "Bélgica" },
  { value: "Belize", label: "Belize" },
  { value: "Benim", label: "Benim" },
  { value: "Bielorrussia", label: "Bielorrússia" },
  { value: "Bolivia", label: "Bolívia" },
  { value: "Bosnia e Herzegovina", label: "Bósnia e Herzegovina" },
  { value: "Botsuana", label: "Botsuana" },
  { value: "Brasil", label: "Brasil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgária" },
  { value: "Burquina Faso", label: "Burquina Fasso" },
  { value: "Burundi", label: "Burúndi" },
  { value: "Butao", label: "Butão" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Camboja", label: "Camboja" },
  { value: "Camaroes", label: "Camarões" },
  { value: "Canada", label: "Canadá" },
  { value: "Catar", label: "Catar" },
  { value: "Cazaquistao", label: "Cazaquistão" },
  { value: "República Centro-Africana", label: "República Centro-Africana" },
  { value: "Chade", label: "Chade" },
  { value: "Tchéquia", label: "Tchéquia" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Chipre", label: "Chipre" },
  { value: "Colombia", label: "Colômbia" },
  { value: "Comores", label: "Comores" },
  { value: "República do Congo", label: "República do Congo" },
  {
    value: "República Democrática do Congo",
    label: "República Democrática do Congo"
  },
  { value: "Coreia do Norte", label: "Coreia do Norte" },
  { value: "Coreia do Sul", label: "Coreia do Sul" },
  { value: "Costa do Marfim", label: "Costa do Marfim" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croacia", label: "Croácia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curacao", label: "Curaçao" },
  { value: "Dinamarca", label: "Dinamarca" },
  { value: "Djibuti", label: "Djibuti" },
  { value: "Domínica", label: "Domínica" },
  { value: "Egito", label: "Egito" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Emirados Árabes Unidos", label: "Emirados Árabes Unidos" },
  { value: "Equador", label: "Equador" },
  { value: "Eritreia", label: "Eritreia" },
  { value: "Eslovaquia", label: "Eslováquia" },
  { value: "Eslovenia", label: "Eslovênia" },
  { value: "Espanha", label: "Espanha" },
  { value: "Estados Unidos", label: "Estados Unidos" },
  {
    value: "Estados Federados da Micronésia",
    label: "Estados Federados da Micronésia"
  },
  { value: "Estônia", label: "Estônia" },
  { value: "Etiopia", label: "Etiópia" },
  { value: "Essuatíni", label: "Essuatíni" },
  { value: "Ilhas Faroe", label: "Ilhas Faroé" },
  { value: "Fiji", label: "Fiji" },
  { value: "Filipinas", label: "Filipinas" },
  { value: "Finlandia", label: "Finlândia" },
  { value: "Franca", label: "França" },
  { value: "Gabao", label: "Gabão" },
  { value: "Gambia", label: "Gâmbia" },
  { value: "Gana", label: "Gana" },
  { value: "Georgia", label: "Geórgia" },
  { value: "Granada", label: "Granada" },
  { value: "Grecia", label: "Grécia" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guiana", label: "Guiana" },
  { value: "Guine", label: "Guiné" },
  { value: "Guine Equatorial", label: "Guiné Equatorial" },
  { value: "Guine-Bissau", label: "Guiné-Bissau" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungria", label: "Hungria" },
  { value: "Iemen", label: "Iémen" },
  { value: "Índia", label: "Índia" },
  { value: "Ilhas Marshall", label: "Ilhas Marshall" },
  { value: "Ilhas Salomao", label: "Ilhas Salomão" },
  { value: "Indonesia", label: "Indonésia" },
  { value: "Ira", label: "Irã" },
  { value: "Iraque", label: "Iraque" },
  { value: "Irlanda", label: "Irlanda" },
  { value: "Islandia", label: "Islândia" },
  { value: "Israel", label: "Israel" },
  { value: "Italia", label: "Itália" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japao", label: "Japão" },
  { value: "Jordania", label: "Jordânia" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Laos", label: "Laos" },
  { value: "Lesoto", label: "Lesoto" },
  { value: "Letonia", label: "Letônia" },
  { value: "Libano", label: "Líbano" },
  { value: "Liberia", label: "Libéria" },
  { value: "Libia", label: "Líbia" },
  { value: "Listenstaine", label: "Listenstaine" },
  { value: "Lituania", label: "Lituânia" },
  { value: "Luxemburgo", label: "Luxemburgo" },
  { value: "Macedonia", label: "Macedónia" },
  { value: "Macedônia do Norte ", label: "Macedônia do Norte" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malasia", label: "Malásia" },
  { value: "Malavi", label: "Maláui" },
  { value: "Maldivas", label: "Maldivas" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marrocos", label: "Marrocos" },
  { value: "Ilhas Mauricias", label: "Ilhas Maurícias" },
  { value: "Mauritania", label: "Mauritânia" },
  { value: "Mexico", label: "México" },
  { value: "Mianmar", label: "Mianmar" },
  { value: "Mocambique", label: "Moçambique" },
  { value: "Moldavia", label: "Moldávia" },
  { value: "Monaco", label: "Mónaco" },
  { value: "Mongolia", label: "Mongólia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Namibia", label: "Namíbia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Nicaragua", label: "Nicarágua" },
  { value: "Niger", label: "Níger" },
  { value: "Nigeria", label: "Nigéria" },
  { value: "Niue", label: "Niue" },
  { value: "Noruega", label: "Noruega" },
  { value: "Nova Zelandia", label: "Nova Zelândia" },
  { value: "Oma", label: "Omã" },
  { value: "Paises Baixos", label: "Países Baixos" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panamá" },
  { value: "Papua-Nova Guine", label: "Papua-Nova Guiné" },
  { value: "Paquistão", label: "Paquistão" },
  { value: "Paraguai", label: "Paraguai" },
  { value: "Peru", label: "Peru" },
  { value: "Polonia", label: "Polônia" },
  { value: "Portugal", label: "Portugal" },
  { value: "Quenia", label: "Quênia" },
  { value: "Quirguistao", label: "Quirguistão" },
  { value: "Quiribati", label: "Quiribáti" },
  { value: "Reino Unido", label: "Reino Unido" },
  { value: "Republica Centro-Africana", label: "República Centro-Africana" },
  { value: "Republica Dominicana", label: "República Dominicana" },
  { value: "Romenia", label: "Romênia" },
  { value: "Ruanda", label: "Ruanda" },
  { value: "Russia", label: "Rússia" },
  { value: "Samoa", label: "Samoa" },
  { value: "Samoa Americana", label: "Samoa Americana" },
  { value: "Santa Lucia", label: "Santa Lúcia" },
  { value: "Sao Cristovão e Neves", label: "São Cristóvão e Neves" },
  { value: "Sao Marinho", label: "São Marinho" },
  { value: "Sao Martinho", label: "São Martinho" },
  { value: "Sao Tome e Principe", label: "São Tomé e Príncipe" },
  { value: "Sao Vicente e Granadinas", label: "São Vicente e Granadinas" },
  { value: "Seicheles", label: "Seicheles" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serra Leoa", label: "Serra Leoa" },
  { value: "Servia", label: "Sérvia" },
  { value: "Singapura", label: "Singapura" },
  { value: "Siria", label: "Síria" },
  { value: "Somalia", label: "Somália" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Suazilandia", label: "Suazilândia" },
  { value: "Sudao", label: "Sudão" },
  { value: "Sudao do Sul", label: "Sudão do Sul" },
  { value: "Suecia", label: "Suécia" },
  { value: "Suica", label: "Suíça" },
  { value: "Suriname", label: "Suriname" },
  { value: "Tailandia", label: "Tailândia" },
  { value: "Tajiquistao", label: "Tajiquistão" },
  { value: "Tanzania", label: "Tanzânia" },
  { value: "Timor Leste", label: "Timor Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trindade e Tobago", label: "Trindade e Tobago" },
  { value: "Tunisia", label: "Tunísia" },
  { value: "Turquemenistao", label: "Turquemenistão" },
  { value: "Turquia", label: "Turquia" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Ucrania", label: "Ucrânia" },
  { value: "Uganda", label: "Uganda" },
  { value: "Uruguai", label: "Uruguai" },
  { value: "Usbequistao", label: "Usbequistão" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietname", label: "Vietname" },
  { value: "Zambia", label: "Zâmbia" },
  { value: "Zimbabue", label: "Zimbabué" }
].sort((a, b) => a.label.localeCompare(b.label))

export {
  VALUE_RANGE,
  UF_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
  BANK_CODES,
  OCCUPATION_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  COUNTRY_OPTIONS
}

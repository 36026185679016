import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:CorrentistasSimularPeloApp",
    hashedCpf
  }

  const ga4 = {
    eventName: "screen_view",
    customPath: "/consorcio/contratacao/correntista",
    implementationTeam: "shared-experiences-pf:growth-vendas-digitais-e-vitrine"
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "2-redirect-app",
      step_name: "redirect-app",
      step_order: "2"
    }
  }

  trackPageview({ ga, segment, ga4 })
}

const advanceToApp = (hashedCpf) => {
  const ga = {
    label: "BTN:ConsorcioE2E:CorrentistasSimularPeloApp:IrParaApp",
    category: "Clique",
    action: "ConsorcioE2E",
    hashedCpf
  }

  const ga4 = {
    eventName: "click",
    customPath: "/consorcio/contratacao/correntista",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    detail: "btn:ir-para-o-app"
  }

  const segment = {
    event: "continue on app",
    data: {
      step: "redirect-app"
    }
  }

  trackEvent({ ga, segment, ga4 })
}

const advanceToBrowser = (hashedCpf) => {
  const ga = {
    label: "BTN:ConsorcioE2E:CorrentistasSimularPeloApp:ContinuarAquiNoSite",
    category: "Clique",
    action: "ConsorcioE2E",
    hashedCpf
  }

  const segment = {
    event: "continue on browser",
    data: {
      step: "redirect-app"
    }
  }

  trackEvent({ ga, segment })
}

const error = (errorMessage) => {
  const ga4 = {
    eventName: "alert",
    customPath: "/consorcio/contratacao/correntista",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    description: errorMessage
  }
  trackEvent({ ga4 })
}

export { pageview, advanceToApp, advanceToBrowser, error }

import { Field, Form, HousingAlert } from "@garantidos/components"

import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import useSmsEdit from "./hooks"

import "./style.scss"

const SmsEdit = ({
  telephone,
  setTelephone,
  handleResetTimer,
  setSuccessMessage,
  toggleModal,
  clearVerificationCode
}) => {
  const {
    fields,
    form,
    onSubmitCallback,
    errorMessage,
    setErrorMessage,
    focusOnPhoneInput
  } = useSmsEdit({
    telephone,
    setTelephone,
    handleResetTimer,
    setSuccessMessage,
    toggleModal,
    clearVerificationCode
  })

  const { isValid } = form
  const { phone } = fields
  return (
    <div className="smsEdit">
      <HousingAlert
        type="error"
        message={errorMessage}
        setMessage={setErrorMessage}
        closeAlertCallback={focusOnPhoneInput}
      />

      <Text as="h2" className="smsEdit__title">
        alterar número do celular
      </Text>

      <Form onSubmit={onSubmitCallback} form={form} className="smsEdit__form">
        <Field field={phone} form={form} data-testid={phone.name} />
        <Button
          fluid
          type="submit"
          disabled={!isValid}
          className="smsEdit__button"
        >
          confirmar alteração
        </Button>
      </Form>
    </div>
  )
}

export default SmsEdit

import { Helmet } from "react-helmet"

import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import Button from "@mobi/ds/components/Button"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import Splitted from "../../components/Splitted"

import linksEnum from "pages/Proposal/constants/linksEnum"

import useLeadAddress from "./hooks"

import "./style.scss"

const LeadAddress = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    onCepResult,
    errorMessage,
    setErrorMessage,
    title,
    onlyNumbersOnInput,
    handleCepTracking
  } = useLeadAddress()
  const { isValid } = form
  const { cep, street, state, city, neighborhood, number, complement } = fields

  return (
    <div className="proposalLeadAddress">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Splitted
        contentType="lead"
        data-aos="fade"
        data-aos-duration="150"
        data-aos-offset="-1000"
      >
        <div
          className="proposalLeadAddress__formWrapper"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-offset="-1000"
        >
          <Stepper title="conte mais sobre você" index={3} max={4} />
          <HousingAlert
            message={errorMessage}
            setMessage={setErrorMessage}
            focusOnText
            type="error"
          />
          <h1 className="proposalLeadAddress__title">
            pra terminarmos, passa pra gente seu endereço
          </h1>
          <Text as="h2" className="proposalLeadAddress__text">
            endereço
          </Text>

          <Form onSubmit={onSubmitCallback} form={form}>
            <Field
              field={cep}
              onResult={onCepResult}
              form={form}
              data-testid={cep.name}
            />
            <Button
              variant="action"
              tagName="a"
              href={linksEnum.BUSCA_CEP}
              target="_blank"
              rel="noreferrer"
              className="proposalLeadAddress__link"
              onClick={handleCepTracking}
            >
              não sabe o CEP?
            </Button>
            <Field field={street} form={form} data-testid={street.name} />
            <div className="proposalLeadAddress__fieldColumns">
              <Field
                field={number}
                form={form}
                data-testid={number.name}
                inputmode="numeric"
                onInput={onlyNumbersOnInput}
              />
              <Field
                field={complement}
                form={form}
                required={false}
                data-testid={complement.name}
              />
            </div>
            <Field
              field={neighborhood}
              form={form}
              data-testid={neighborhood.name}
            />
            <div className="proposalLeadAddress__fieldColumns proposalLeadAddress__fieldColumns--differentWidth">
              <Field
                field={city}
                form={form}
                data-testid={city.name}
                readOnly
              />
              <Field
                field={state}
                form={form}
                data-testid={state.name}
                isReadOnly
              />
            </div>
            <Sender
              onPrev={handlePrevStep}
              isNextDisabled={!isValid}
              nextLabel="avançar"
              data_testid="submit"
            />
          </Form>
        </div>
      </Splitted>
    </div>
  )
}

export default LeadAddress

import { fieldsCommonOptions } from "@garantidos/data"

import {
  COUNTRY_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  OCCUPATION_OPTIONS
} from "pages/Proposal/constants/dropdownOptions"

const { NATIONALITY_OPTIONS } = fieldsCommonOptions

export const fields = {
  nationality: {
    type: "ariaSelect",
    name: "nationality",
    label: "nacionalidade",
    placeholder: "Selecione a nacionalidade",
    searchPlaceholder: "buscar nacionalidade",
    options: NATIONALITY_OPTIONS,
    fetchFormat: "ariaSelect"
  },
  tax_residence: {
    type: "radio",
    name: "tax_residence",
    options: [
      {
        id: "residency_sim",
        value: "nao",
        label: "não",
        checked: true
      },
      {
        id: "residency_nao",
        value: "sim",
        label: "sim"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  },
  tax_residence_country: {
    type: "ariaSelect",
    name: "tax_residence_country",
    label: "em qual país você possui residência fiscal?",
    placeholder: "Selecione o país",
    searchPlaceholder: "buscar país que possui residência fiscal",
    options: COUNTRY_OPTIONS,
    fetchFormat: "ariaSelect"
  },
  birthdate: {
    type: "birthdate",
    name: "birthdate",
    label: "data de nascimento",
    sendFormat: "date",
    fetchFormat: "date",
    rules: {
      minAge: 18,
      maxAge: 99
    }
  },
  gender: {
    type: "select",
    name: "gender",
    label: "gênero",
    placeholder: "",
    options: [
      { label: "FEMININO", value: "F" },
      { label: "MASCULINO", value: "M" }
    ],
    sendFormat: "select",
    fetchFormat: "select"
  },
  occupation: {
    type: "ariaSelect",
    name: "occupation",
    label: "profissão",
    placeholder: "Selecione a profissão",
    searchPlaceholder: "buscar profissão",
    options: OCCUPATION_OPTIONS,
    fetchFormat: "ariaSelect"
  },
  politically_exposed: {
    type: "radio",
    name: "politically_exposed",
    options: [
      {
        id: "politically_exposed_nao",
        value: "nao",
        label: "não",
        checked: true
      },
      {
        id: "politically_exposed_sim",
        value: "sim",
        label: "sim"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  },
  monthly_income: {
    inputMode: "decimal",
    type: "currency",
    name: "monthly_income",
    label: "renda mensal"
  },
  marital_status: {
    type: "select",
    name: "marital_status",
    label: "estado civil",
    placeholder: "selecione",
    options: MARITAL_STATUS_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  partner_name: {
    type: "name",
    name: "partner_name",
    label: "nome completo",
    sendFormat: "text"
  },
  partner_cpf: {
    type: "cpf",
    name: "partner_cpf",
    label: "cpf",
    sendFormat: "cpf",
    fetchFormat: "cpf",
    rules: {
      distinctCpf: true
    }
  },
  partner_document: {
    type: "text",
    name: "partner_document",
    label: "informe o rg ou cnh"
  },
  partner_birthdate: {
    type: "birthdate",
    name: "partner_birthdate",
    label: "data de nascimento",
    sendFormat: "date",
    fetchFormat: "date",
    rules: {
      minAge: 18,
      maxAge: 99
    }
  }
}

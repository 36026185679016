import { useEffect, useRef, useState } from "react"

import { focusOnLogo, formatters } from "@garantidos/utils"

import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useAsset = () => {
  const [availableAssets, setAvailableAssets] = useState([])

  const [infoMessage, setInfoMessage] = useState("")
  const callerId = useRef(null)
  const { next, prev } = useStep()
  const {
    stepInfo,
    fetchProposalData,
    sendProposalData,
    proposalData,
    cpfData,
    isLoading
  } = useProposalContext()

  const { redirectToRejectionPage } = useRejectionRedirect()
  const { fromSummary } = proposalData
  const { hashedCpf } = cpfData

  const { assets, simulation_type, category } = proposalData

  const { previousStep, nextStep } = stepInfo
  const {
    asset: { title }
  } = steps

  const handleAssetSelect = async (assetCode, assetOrder, isMain = false) => {
    if (isLoading) return
    try {
      const selectedAsset = proposalData.assets[assetOrder]
      const { asset_value, installment_value, remaining_months } = selectedAsset
      trackers.advance(simulation_type, fromSummary, isMain)
      trackers.assetSelected({
        assetOrder,
        isMain,
        assetValue: asset_value,
        installmentValue: installment_value,
        months: remaining_months
      })

      const payload = { asset_code: assetCode }

      await sendProposalData({ payload, flow: fromSummary ? "full-group" : "" })
      next(nextStep)
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back(simulation_type, fromSummary)

      await fetchProposalData({ step: previousStep })

      prev(previousStep)
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handleDropFlow = async (contactType = "PHONE") => {
    if (isLoading) return
    try {
      trackers.dropFlow(contactType, simulation_type, fromSummary)
      trackers.submitForm()
      trackers.contactClicked(contactType)

      const payload = { contact_whatsapp: contactType === "WHATSAPP" }

      await sendProposalData({ payload, flow: "bypass", step: "send-lead" })

      next("finished")
    } catch (error) {
      if (error?.statusCode === 409) {
        redirectToRejectionPage(errorsEnum.SAME_DOCUMENT_PROPOSAL)
        return
      }
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const getAvailableAssets = (rawAssets) => {
    if (!rawAssets || !rawAssets.length) return []
    const mountedAssets = rawAssets.map((asset) => ({
      assetCode: asset.asset_code,
      assetValue: formatters.toCurrency(asset.asset_value),
      installmentValue: formatters.toCurrency(asset.installment_value),
      remainingMonths: asset.remaining_months,
      percentageTotalFee: `${formatters.floatToDisplayableNumber(
        asset.percentage_total_fee / asset.remaining_months
      )}% ao mês`,
      reserveFund: `${formatters.floatToDisplayableNumber(
        asset.reserve_fund
      )}%`,
      category
    }))

    return mountedAssets
  }

  const handleCloseInfo = () => {
    setInfoMessage("")
    if (callerId.current) {
      callerId.current.focus()
      callerId.current = null
    }
  }

  const handleAccordionOpen = () => {
    trackers.moreOptions(simulation_type, fromSummary)
  }

  useEffect(() => {
    const assetOptions = getAvailableAssets(assets)
    setAvailableAssets(assetOptions)
    trackers.pageview(hashedCpf, simulation_type, fromSummary)
    focusOnLogo()
  }, [])

  return {
    availableAssets,
    handlePrevStep,
    handleAssetSelect,
    handleDropFlow,
    title,
    infoMessage,
    handleCloseInfo,
    handleAccordionOpen,
    simulation_type,
    fromSummary
  }
}

export default useAsset

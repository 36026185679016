import { useEffect, useState } from "react"

import { proposalHttpController } from "@garantidos/services"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useLikerComponent = () => {
  const [eventLabel, setEventLabel] = useState(null)

  const { errorHandler } = useProposalContext()

  const focusOnFeedbackText = () => {
    setTimeout(() => {
      const text = document.querySelector("div.ds-likert__container--send p")
      if (text) {
        text.setAttribute("tabindex", "0")
        text.focus()
      }
    }, 380)
  }

  useEffect(() => {
    eventLabel && trackers.likertInteraction(eventLabel)
  }, [eventLabel])

  const handleSubmitCallback = async ({ experience, options, description }) => {
    focusOnFeedbackText()
    setEventLabel("CliqueEnviar")
    try {
      const payload = {
        likert_rate: experience,
        likert_comment: description
      }
      await proposalHttpController({ payload, method: "sendLikert" })
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleExperienceCallback = ({ experience }) => {
    const experienceParser = {
      BAD: "Ruim",
      NOT_SATISFIED: "NaoGostei",
      NOT_SURE: "SeiLa",
      LIKED: "Gostei",
      LOVED: "Adorei"
    }
    setEventLabel(experienceParser[experience])
  }

  const handleOptionCallback = ({ option }) => {
    setEventLabel(`CliqueMelhoria${option}`)
  }

  return {
    handleSubmitCallback,
    handleExperienceCallback,
    handleOptionCallback
  }
}

export default useLikerComponent

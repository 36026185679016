import { useEffect, useRef, useState } from "react"

import { useForm } from "@garantidos/hooks"
import { focusOnLogo, formatters, scrollToTop } from "@garantidos/utils"

import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { summaryData, summaryFaq } from "./data"
import * as trackers from "./trackings"

const useSimulationSummary = () => {
  const { next, prev } = useStep()
  const { form } = useForm({ context: ProposalContext })
  const [formattedData, setFormattedData] = useState({})
  const [isAboutModalVisible, setIsAboutModalVisible] = useState(false)

  const aboutModalButtonRef = useRef(null)

  const {
    proposalData,
    cpfData,
    isLoading,
    sendProposalData,
    fetchProposalData,
    stepInfo,
    errorHandler
  } = useProposalContext()

  const { hashedCpf } = cpfData
  const { previousStep, nextStep } = stepInfo
  const {
    simulationSummary: { title }
  } = steps
  const { redirectToRejectionPage } = useRejectionRedirect()

  const handleTrackItemFaq = (trackId, i) => {
    trackers.faqItem(trackId)

    setTimeout(() => {
      const element = document.querySelector(
        `.simulationSummary__formWrapper #ds-panel-${i}`
      )
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "center" })
    }, 250)
  }

  const onSubmitCallback = async () => {
    if (isLoading) return
    try {
      const payload = {}
      trackers.advance()
      trackers.hiringStarted(proposalData)

      await sendProposalData({ payload })
      next(nextStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleDropFlow = async (contactType = "PHONE") => {
    if (isLoading) return
    try {
      trackers.dropFlow(contactType)
      trackers.submitForm()
      trackers.contactClicked(contactType)

      const payload = { contact_whatsapp: contactType === "WHATSAPP" }

      await sendProposalData({ payload, flow: "bypass", step: "send-lead" })

      next("finished")
    } catch (error) {
      if (error?.statusCode === 409) {
        redirectToRejectionPage(errorsEnum.SAME_DOCUMENT_PROPOSAL)
        return
      }
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()

      await fetchProposalData({ step: previousStep })

      prev(previousStep)
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const getBidInfo = (bidAmount, bidPercentage) => {
    if (bidAmount && bidPercentage)
      return {
        highlight: `a média dos lances vencedores nos últimos 3 meses foi de ${formatters.floatToDisplayableNumber(
          bidPercentage
        )}%`,
        text: `Ou seja, um lance de ${formatters.toCurrency(
          bidAmount
        )} tem grandes chances de antecipar sua contemplação`
      }
    return null
  }

  const handleCloseModal = () => {
    setIsAboutModalVisible(false)
    aboutModalButtonRef.current.focus()
  }

  const handleAboutButtonClick = (e) => {
    trackers.knowMore()
    aboutModalButtonRef.current = e.target
    setTimeout(
      () =>
        document
          .getElementsByClassName("ds-modal-container__close-button")[0]
          .focus(),
      1500
    )
    setIsAboutModalVisible(true)
  }

  useEffect(() => {
    const formattedSummaryData = summaryData(proposalData, getBidInfo)
    setFormattedData(formattedSummaryData)
    trackers.pageview(hashedCpf)
    focusOnLogo()
    setTimeout(() => {
      scrollToTop()
    }, 100)
  }, [])

  return {
    title,
    formattedData,
    summaryFaq: summaryFaq(formattedData, handleTrackItemFaq),
    form,
    onSubmitCallback,
    handleDropFlow,
    handlePrevStep,
    handleAboutButtonClick,
    isAboutModalVisible,
    handleCloseModal
  }
}

export default useSimulationSummary

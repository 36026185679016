import If from "@mobi/components/If"
import Alert from "@mobi/ds/components/Alert/Alert"

import { iconAlerts } from "./data"

import useHousingAlert from "./hooks"

import "./style.scss"

const HousingAlert = ({
	type = "offer",
	message,
	setMessage,
	returnFocus = true,
	closeAlertCallback = null,
	overrideFocus = false,
	focusOnText = false,
	focusDelay = 0
}) => {
	const { handleCloseAlert, alertElementRef } = useHousingAlert({
		message,
		setMessage,
		returnFocus,
		closeAlertCallback,
		overrideFocus,
		focusOnText,
		focusDelay
	})

	return (
		<If
			condition={!!message}
			renderIf={
				<div
					id="housingAlert"
					className="housingAlert"
					ref={alertElementRef}
					aria-live={focusOnText ? "off" : "assertive"}
				>
					<Alert
						paragraph={true}
						closeAlert={handleCloseAlert}
						icon={iconAlerts[type]}
						fadeIn
						isVisible={!!message}
						message={message}
						variant={type}
					/>
				</div>
			}
		/>
	)
}

export default HousingAlert

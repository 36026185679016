import { Helmet } from "react-helmet"

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import If from "@mobi/components/If"
import Modal from "@mobi/ds/components/Modal"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import AriaSelect from "../../components/AriaSelect"
import Splitted from "../../components/Splitted"
import RejectedResidenceCard from "./components/RejectedResidenceCard"

import useLead from "./hooks"

import "./style.scss"

const Lead = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    proposalData,
    errorMessage,
    setErrorMessage,
    handlePoliticallyExposedModal,
    politicallyExposedModal,
    taxResidenceModal,
    handleTaxResidenceModal,
    handleSelectChange,
    pageTitle,
    handleAnotherSimulation
  } = useLead()

  const { isValid } = form
  const {
    birthdate,
    nationality,
    gender,
    occupation,
    politically_exposed,
    monthly_income,
    marital_status,
    partner_name,
    partner_cpf,
    partner_document,
    partner_birthdate,
    tax_residence,
    tax_residence_country
  } = fields

  const {
    nationality: nationalityValue,
    tax_residence: taxResidence,
    tax_residence_country: taxResidenceCountry
  } = proposalData

  const isNextButtonEnable =
    isValid &&
    nationalityValue &&
    nationalityValue !== "AMERICANA" &&
    (taxResidence === false ||
      (taxResidence &&
        taxResidenceCountry &&
        taxResidenceCountry !== "Estados Unidos"))

  return (
    <div className="proposalLead">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Splitted
        contentType="lead"
        data-aos="fade"
        data-aos-duration="150"
        data-aos-offset="-1000"
      >
        <div
          className="proposalLead__formWrapper"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-offset="-1000"
        >
          <Stepper title="conte mais sobre você" index={1} max={4} />
          <HousingAlert
            message={errorMessage}
            setMessage={setErrorMessage}
            focusOnText
            type="error"
          />
          <Text as="h1" className="proposalLead__title">
            para a contratação do seu consórcio, precisamos de mais alguns dados
          </Text>
          <Text as="h2" className="proposalLead__text">
            sobre você
          </Text>
          <Form onSubmit={onSubmitCallback} form={form}>
            <AriaSelect
              form={form}
              field={nationality}
              label={nationality.label}
              placeholder={nationality.placeholder}
              name={nationality.name}
              dataTestId={nationality.name}
              options={nationality.options}
              searchPlaceholder={nationality.searchPlaceholder}
              onChange={(value) => handleSelectChange("Nacionalidade", value)}
            />

            <If
              condition={nationalityValue && nationalityValue !== "AMERICANA"}
            >
              <>
                <Text as="p" className="proposalLead__questionField">
                  você&nbsp;
                  <span
                    role="button"
                    className="proposalLead__questionFieldLink"
                    tabIndex="0"
                    onClick={(el) => handleTaxResidenceModal(el)}
                  >
                    possui residência fiscal em outro país?
                  </span>
                </Text>
                <div
                  className="proposalLead__cardRadioGroup"
                  role="radio-group"
                  aria-label="possui residência fiscal em outro país?"
                >
                  <Field
                    field={tax_residence}
                    form={form}
                    onInput={(e) =>
                      handleSelectChange("ResidenciaFiscal", e.target.value)
                    }
                  />
                </div>

                <If condition={taxResidence}>
                  <AriaSelect
                    form={form}
                    field={tax_residence_country}
                    label={tax_residence_country.label}
                    placeholder={tax_residence_country.placeholder}
                    name={tax_residence_country.name}
                    options={tax_residence_country.options}
                    searchPlaceholder={tax_residence_country.searchPlaceholder}
                    onChange={(value) =>
                      handleSelectChange(
                        "PaisResidenciaFiscal",
                        value,
                        "tax_residence_country",
                        true
                      )
                    }
                  />
                </If>

                <If
                  condition={
                    taxResidence === false ||
                    (taxResidence &&
                      taxResidenceCountry &&
                      taxResidenceCountry !== "Estados Unidos")
                  }
                >
                  <>
                    <div className="proposalLead__fieldColumns">
                      <Field
                        field={birthdate}
                        form={form}
                        data-testid={birthdate.name}
                        aria-labelledby=""
                        aria-label="data de nascimento, dia: 2 dígitos, mês: 2 dígitos, ano: 4 dígitos"
                      />
                      <Field
                        field={gender}
                        form={form}
                        required
                        native
                        data-testid={gender.name}
                        onChange={(e) => handleSelectChange("Genero", e.value)}
                      />
                    </div>

                    <AriaSelect
                      form={form}
                      field={occupation}
                      label={occupation.label}
                      placeholder={occupation.placeholder}
                      name={occupation.name}
                      dataTestId={occupation.name}
                      options={occupation.options}
                      searchPlaceholder={occupation.searchPlaceholder}
                      onChange={(value) =>
                        handleSelectChange(
                          "Profissao",
                          value,
                          "occupation",
                          true
                        )
                      }
                    />
                    <Text as="p" className="proposalLead__questionField">
                      Sou&nbsp;
                      <span
                        role="button"
                        className="proposalLead__questionFieldLink"
                        tabIndex="0"
                        onClick={(el) => handlePoliticallyExposedModal(el)}
                      >
                        pessoa exposta politicamente
                      </span>
                    </Text>
                    <div
                      className="proposalLead__cardRadioGroup"
                      role="radiogroup"
                    >
                      <Field
                        field={politically_exposed}
                        form={form}
                        onInput={(e) =>
                          handleSelectChange(
                            "PessoaExpostaPoliticamente",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <Field
                      field={monthly_income}
                      form={form}
                      required
                      rules={{ min: 0, max: 999_999_999.99 }}
                      data-testid={monthly_income.name}
                    />
                    <Field
                      field={marital_status}
                      form={form}
                      required
                      native
                      data-testid={marital_status.name}
                      onChange={(e) =>
                        handleSelectChange(
                          "EstadoCivil",
                          e.value,
                          "marital_status",
                          true
                        )
                      }
                    />

                    {(proposalData.marital_status === "1" ||
                      proposalData.marital_status === "3") && (
                      <>
                        <Text as="h2" className="proposalLead__titlePartner">
                          sobre o(a) companheiro(a)
                        </Text>
                        <Field
                          field={partner_name}
                          form={form}
                          data-testid={partner_name.name}
                          auxLabel="nome completo do(a) companheiro(a)"
                        />
                        <Field
                          field={partner_cpf}
                          form={form}
                          data-testid={partner_cpf.name}
                          auxLabel="cpf do(a) companheiro(a)"
                        />
                        <Field
                          field={partner_document}
                          form={form}
                          data-testid={partner_document.name}
                          auxLabel="informe o rg ou cnh do(a) companheiro(a)"
                        />
                        <Field
                          field={partner_birthdate}
                          form={form}
                          data-testid={partner_birthdate.name}
                          aria-labelledby=""
                          aria-label="data de nascimento do(a) companheiro(a), dia: 2 dígitos, mês: 2 dígitos, ano: 4 dígitos"
                        />
                      </>
                    )}
                  </>
                </If>
              </>
            </If>
            <If
              condition={
                (nationalityValue && nationalityValue === "AMERICANA") ||
                (taxResidence &&
                  taxResidenceCountry &&
                  taxResidenceCountry === "Estados Unidos")
              }
            >
              <RejectedResidenceCard
                isAmerican={
                  nationalityValue && nationalityValue === "AMERICANA"
                }
                handleAnotherSimulation={handleAnotherSimulation}
              />
            </If>
            <Sender
              onPrev={handlePrevStep}
              isNextDisabled={!isNextButtonEnable}
              data_testid="submit"
            />
          </Form>
        </div>
      </Splitted>
      <Modal
        type="pop"
        show={politicallyExposedModal}
        onCloseClick={(e) => handlePoliticallyExposedModal(e)}
        customClass="proposalLead__politicallyExposedModal"
      >
        <Text as="h3" className="proposalLead__modalTitle">
          Pessoa Politicamente Exposta
        </Text>
        <Text as="p" className="proposalLead__modalText">
          Pessoa politicamente exposta é alguém que trabalhe ou tenha trabalhado
          nos últimos 5 anos em algum cargo público de confiança, como:
          secretário municipal, vereador, deputado, assessor, etc.
        </Text>
      </Modal>
      <Modal
        type="pop"
        show={taxResidenceModal}
        onCloseClick={(e) => handleTaxResidenceModal(e)}
        customClass="proposalLead__politicallyExposedModal"
      >
        <Text as="h2" className="proposalLead__modalTitle">
          Residência Fiscal
        </Text>
        <Text as="h3" className="proposalLead__modalSubtitle">
          o que é residência fiscal?
        </Text>
        <Text as="p" className="proposalLead__modalText">
          Residência fiscal é o país em que você é considerado contribuinte.
          Você pode ser residente fiscal se tiver cidadania, nacionalidade ou
          visto permanente (tal como um green card) de um país, ou ainda, se
          vive, trabalha ou permanece nesse país por período superior a 183
          dias, consecutivos ou não, em um período de até doze meses.
        </Text>
        <Text as="h3" className="proposalLead__modalSubtitle">
          o que é NIF?
        </Text>
        <Text as="p" className="proposalLead__modalText">
          NIF é o Número de Identificação Fiscal e é semelhante ao CPF
          brasileiro.
        </Text>
        <Text as="h3" className="proposalLead__modalSubtitle">
          onde encontro o NIF?
        </Text>
        <Text as="p" className="proposalLead__modalText">
          Cada país possui um documento diferente mas, normalmente, consta no
          seu cartão de cidadão ou no seu passaporte, ao lado da fotografia.
        </Text>
      </Modal>
    </div>
  )
}

export default Lead

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (trackingInfos, errorCode, hashedCpf) => {
  const trackingEvent = trackingInfos[errorCode]
  if (!trackingEvent) return
  const { pageview } = trackingEvent
  if (!pageview) return
  pageview.ga.hashedCpf = hashedCpf
  trackPageview(pageview)
}

const advance = (ga, segmentTrackingInfos) => {
  const segment = segmentTrackingInfos

  trackEvent({ ga, segment })
}

const formSubmit = () => {}

const elementClicked = () => {}

const buttonCallback = (gaInfo, segmentInfo) => {
  const ga = gaInfo
  const segment = segmentInfo

  trackEvent({ ga, segment })
}

export { pageview, advance, formSubmit, elementClicked, buttonCallback }

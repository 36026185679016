import { useEffect, useRef, useState } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { clearStorage } from "@garantidos/utils"

import { useStep } from "@mobi/libraries/step"

import validatorsDictionary from "pages/Proposal/hooks/validators"
import { steps } from "pages/Proposal/steps"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useLead = () => {
  const [politicallyExposedModal, setPoliticallyExposedModal] = useState(false)
  const [taxResidenceModal, setTaxResidenceModal] = useState(false)
  const callerRef = useRef(null)

  const {
    stepInfo,
    proposalData,
    fetchProposalData,
    sendProposalData,
    cpfData,
    errorMessage,
    setErrorMessage,
    isLoading,
    errorHandler,
    updateStepInfo,
    updateProposalData
  } = useProposalContext()
  const { next, prev } = useStep()
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const { formatSendPayload } = usePayloadFormatters(fields)

  const { previousStep, nextStep } = stepInfo
  const { hashedCpf } = cpfData
  const {
    lead: { title: pageTitle }
  } = steps

  const onSubmitCallback = async (formData) => {
    try {
      trackers.advance()

      const payload = formatSendPayload(formData)

      await sendProposalData({ payload })
      next(nextStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()

      await fetchProposalData({ step: previousStep })
      prev(previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const handlePoliticallyExposedModal = (e) => {
    e.preventDefault()
    if (politicallyExposedModal) {
      setPoliticallyExposedModal(false)
      callerRef.current.focus()
      callerRef.current = null
      return
    }

    callerRef.current = document.activeElement
    setPoliticallyExposedModal(true)
    trackers.politicallyExposed()

    setTimeout(() => {
      const element = document.querySelector(
        ".ds-button.ds-modal-container__close-button.ds-button--high-contrast"
      )
      if (element) element.focus()
    }, 300)
  }

  const handleTaxResidenceModal = (e) => {
    e.preventDefault()
    if (taxResidenceModal) {
      setTaxResidenceModal(false)
      callerRef.current.focus()
      callerRef.current = null
      return
    }

    callerRef.current = document.activeElement
    setTaxResidenceModal(true)

    setTimeout(() => {
      const element = document.querySelector(
        ".ds-button.ds-modal-container__close-button.ds-button--high-contrast"
      )
      if (element) element.focus()
    }, 300)
  }

  const handleAnotherSimulation = async () => {
    const newStepInfo = {
      flow: "hire",
      current_step: "softlead",
      previous_step: "lead",
      next_step: "sms"
    }

    updateStepInfo(newStepInfo)
    await updateProposalData({ payload: {}, clear: true })
    clearStorage()
    next("softlead")
  }

  const handleSelectChange = (
    property,
    value,
    inputName = "",
    byLabel = false
  ) => {
    if (byLabel) {
      value = fields[inputName].options.find(
        (option) => option.value === value
      )?.label
    }
    value && trackers.selectChange(property, value)
  }

  useEffect(() => {
    trackers.pageview(hashedCpf)
  }, [])

  useEffect(() => {
    if (proposalData.tax_residence) return
    delete proposalData.tax_residence_country
  }, [proposalData.tax_residence])

  return {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    proposalData,
    errorMessage,
    setErrorMessage,
    handlePoliticallyExposedModal,
    politicallyExposedModal,
    taxResidenceModal,
    handleTaxResidenceModal,
    handleSelectChange,
    pageTitle,
    handleAnotherSimulation
  }
}

export default useLead

import { useContext, useEffect, useRef, useState } from "react"
import { useWatch } from "react-hook-form"

import { formatters } from "../../utils"
import {
  customRadioClasses,
  fieldComponents,
  radioChildren,
  radioTypes,
} from "./types"

import { useFieldRules } from "../../hooks"

const useField = ({
  field,
  context,
  validatorsDictionary,
  props,
  control,
  unregister = null,
  setValue,
  setError,
  formValidators,
  updateFormValidators,
  clearErrors,
}) => {
  const { updateProposalData, proposalData, errorFields, setErrorFields } =
    useContext(context)
  const { mountValidators } = useFieldRules({ field, validatorsDictionary })
  const [currencyValue, setCurrencyValue] = useState(0)
  const [selectValue, setSelectValue] = useState(null)

  const initialized = useRef({
    value: false,
    removeErrorField: false,
    validators: false,
    errorFields: false,
  })

  const value = useWatch({ control, name: field.name })

  const setValueFromProposalData = () => {
    const { name, fetchFormat, type } = field
    const rawValue = proposalData[name]
    if (rawValue === undefined) return
    const setValueOptions = {
      shouldValidate: true,
      shouldDirty: false,
      shouldTouch: false,
    }
    const formatter = formatters.fetchFormatters[fetchFormat]
    let value = formatter
      ? formatter({ value: rawValue, options: field.options })
      : rawValue
    type === "currency" && setCurrencyValue(value)
    type === "select" && setSelectValue(rawValue)
    value = type === "quantity" ? value.toString() : value
    setValue(name, value, setValueOptions)
  }

  const removeErrorField = () => {
    if (!errorFields[field.name]) return
    const errors = {}
    for (const key in errorFields) {
      if (key !== field.name) {
        errors[key] = errorFields[key]
      }
    }
    setErrorFields(errors)
  }

  useEffect(() => {
    if (setValue) setValueFromProposalData()
    return () => {
      if (unregister) {
        unregister(field.name)
      }
    }
  }, [])

  useEffect(() => {
    if (!initialized.current.value) {
      initialized.current.value = true
      return
    }
    if (value === undefined) return
    const formatter = formatters.sendFormatters[field.sendFormat]
    const payload = {
      [field.name]: formatter
        ? formatter({ value, options: field.options })
        : value,
    }
    updateProposalData({ payload })
  }, [value])

  useEffect(() => {
    if (!initialized.current.removeErrorField) {
      initialized.current.removeErrorField = true
      return
    }
    removeErrorField()
  }, [proposalData])

  useEffect(() => {
    if (!initialized.current.validators) {
      initialized.current.validators = true
      return
    }
    updateFormValidators(
      field.name,
      mountValidators({ proposalData, errorFields }),
    )
  }, [proposalData])

  useEffect(() => {
    if (!initialized.current.errorFields) {
      initialized.current.errorFields = true
      return
    }
    if (errorFields[field.name]) {
      setError(field.name)
    }
    updateFormValidators(
      field.name,
      mountValidators({ proposalData, errorFields }),
    )
  }, [errorFields])

  const {
    name = "",
    label = "",
    placeholder = "",
    options = [],
    type,
    inputType = "",
    inputMode = "",
  } = field
  const fieldValue = proposalData[name]

  const mountRadioComponents = () => {
    const radioComponents = options.map((option, index) => {
      const {
        id,
        value,
        label,
        data_testid,
        description,
        ariaLabel = "",
      } = option
      const children = getRadioChildren(type, option)

      const fieldProps = {
        ...mountFieldProps(type),
        key: index,
        id,
        value,
        "data-testid": data_testid,
        label: children ? "" : label,
        children,
        className: getRadioClass(type, value),
      }

      const Component = fieldComponents[type]
      return (
        <Component
          {...fieldProps}
          {...(label && description
            ? { ariaLabel: `${label} ${description}` }
            : { ariaLabel: `${ariaLabel}` })}
        />
      )
    })
    return radioComponents
  }

  const mountFieldProps = (type) => {
    const commonProps = {
      name,
      control,
    }

    const nonRadioProps = {
      label,
      placeholder,
      validators: formValidators[name],
      formProps: { control, setValue, setError, clearErrors },
    }

    if (radioTypes.includes(type)) {
      return {
        ...commonProps,
        ...props,
      }
    }

    if (type === "currency") {
      return {
        ...commonProps,
        ...nonRadioProps,
        defaultValue: currencyValue,
        shouldResetOn: currencyValue,
        inputmode: inputMode,
        ...props,
      }
    }

    if (type === "select") {
      return {
        ...commonProps,
        ...nonRadioProps,
        defaultValue: selectValue,
        options,
        type: inputType,
        clearOnFocus: false,
        native: window.matchMedia("(max-width: 767px)").matches, // TODO: utilizar "useMediaQuery"
        ...props,
      }
    }

    if (type === "token") {
      return {
        ...commonProps,
        ...nonRadioProps,
        type: inputType,
        ...props,
      }
    }

    return {
      ...commonProps,
      ...nonRadioProps,
      ...props,
    }
  }

  const getRadioChildren = (type, option) => {
    const Option = radioChildren[type]
    if (!Option) return null
    const { image, imageAlt, label, Icon, description } = option
    return (
      <Option
        image={image}
        imageAlt={imageAlt}
        label={label}
        Icon={Icon}
        description={description}
      />
    )
  }

  const getRadioClass = (type, value) => {
    const radioClass =
      fieldValue === value && type === "hiddenRadio"
        ? "fieldHiddenRadio--selected"
        : ""
    return `${customRadioClasses[type] || ""} ${radioClass}`
  }

  const mountFieldComponent = () => {
    if (radioTypes.includes(type)) {
      const radioComponents = mountRadioComponents()
      return radioComponents
    }

    const fieldProps = mountFieldProps(type)
    const Component = fieldComponents[type]
    return <Component {...fieldProps} />
  }

  useEffect(() => {
    if (props.auxLabel) {
      const element = document.querySelector(`#ds-label-${name}`)
      if (element) {
        element.setAttribute("aria-label", props.auxLabel)
      }
    }
  }, [])

  return {
    FieldComponent: mountFieldComponent(),
  }
}

export default useField

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:ConsorcioE2E:TokenSMS", hashedCpf }

  const segment = {
    event: "page viewed",
    data: {
      step: "2-sms",
      step_name: "sms",
      step_order: 2
    }
  }

  trackPageview({ ga, segment })
}

const wrongCode = () => {
  const ga = {
    category: "Objeto Visto",
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:TokenSMS:CodigoIncorreto",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:TokenSMS"
    }
  }

  trackEvent({ ga })
}

const resendToken = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:TokenSMS:ReenviarCodigo",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:TokenSMS"
    }
  }

  const segment = "resend code"

  trackEvent({ ga, segment })
}

const editNumber = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:TokenSMS:EditarNumero",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:TokenSMS"
    }
  }

  trackEvent({ ga })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:TokenSMS:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:TokenSMS:Voltar"
    }
  }
  const segment = {
    event: "return step",
    data: {
      step: "sms"
    }
  }

  trackEvent({ ga, segment })
}

const confirmToken = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:TokenSMS:Continuar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:TokenSMS"
    }
  }
  const segment = {
    event: "advance step",
    data: {
      step: "sms"
    }
  }

  trackEvent({ ga, segment })
}

const finishedTimer = () => {
  const segment = "new code waiting over"
  trackEvent({ segment })
}

export {
  pageview,
  wrongCode,
  resendToken,
  editNumber,
  back,
  confirmToken,
  finishedTimer
}

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:ConsorcioE2E:NosConhecendo", hashedCpf }
  const ga4 = {
    event_name: "screen_view",
    customPath: "/consorcio/contratacao/dados-pessoais",
    implementationTeam: "shared-experiences-pf:growth-vendas-digitais-e-vitrine"
  }
  const segment = {
    event: "page viewed",
    data: {
      step: "1-softlead",
      step_name: "softlead",
      step_order: 1
    }
  }
  trackPageview({ ga, segment, ga4 })
}

const advance = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:NosConhecendo:Avancar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:NosConhecendo"
    }
  }

  // Padronizar payloads de segment entre os projetos. Uns vao como string e outros como payload com parametros
  const segment = {
    event: "advance step",
    data: {
      step: "softlead"
    }
  }

  trackEvent({ ga, segment })
}

const formStarted = () => {}

const linkTracking = ({ gaLabel, ga4Label }) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:NosConhecendo:${gaLabel}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:NosConhecendo"
    }
  }
  const ga4 = {
    eventName: "visualization",
    customPath: "/consorcio/contratacao/dados-pessoais",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    detail: ga4Label
  }

  trackEvent({ ga, ga4 })
}

const modalError = (message) => {
  const ga4 = {
    eventName: "alert",
    customPath: "/consorcio/contratacao/dados-pessoais",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    description: message
  }
  trackEvent({ ga4 })
}

export { pageview, advance, formStarted, linkTracking, modalError }

import { Helmet } from "react-helmet"

import { HousingAlert } from "@garantidos/components"

import { Button, If, Text } from "@mobi/ds"

import ExternalLinkIcon from "icons/external-link.svg"
import WomanCelebratingIllustration from "images/woman-celebrating.svg"

import StepsHeader from "../../components/StepsHeader"

import useRedirectApp from "./hooks"

import "./style.scss"

import { benefitsItems, nextStepsItems } from "./data"

const RedirectApp = () => {
  const {
    handleRedirectApp,
    isLoading,
    title,
    qrCodeBase64,
    hasMobileView,
    errorMessage,
    setErrorMessage
  } = useRedirectApp()

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <If condition={!hasMobileView} renderIf={<StepsHeader />} />

      <div className="redirectApp">
        <HousingAlert
          message={errorMessage}
          setMessage={setErrorMessage}
          focusOnText
          type="error"
        />
        <div className="redirectApp__container">
          <div
            className="redirectApp__heading"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-offset="-1000"
          >
            <Text as="h1" size="xxl" className="redirectApp__title">
              que tal continuar sua simulação pelo app do Itaú?
            </Text>
            <WomanCelebratingIllustration
              className="redirectApp__image"
              aria-hidden
            />
          </div>

          <div
            className="redirectApp__card"
            data-aos={hasMobileView ? "" : "fade-right"}
            data-aos-duration="500"
            data-aos-offset="-1000"
          >
            <div>
              <Text as="h2" size="md" className="redirectApp__subtile">
                veja as vantagens que você encontrará por lá:
              </Text>
              <ul className="redirectApp__list">
                {benefitsItems.map((item) => (
                  <li className="redirectApp__listItem">
                    {item.icon}
                    <Text
                      as="p"
                      size="md"
                      className="redirectApp__listItemText"
                    >
                      {item.text}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
            <If
              condition={hasMobileView}
              renderIf={
                <Button
                  onClick={handleRedirectApp}
                  className="redirectApp__redirectButton"
                  fluid
                  isLoading={isLoading}
                  aria-label="ir para o aplicativo"
                  rightIcon={<ExternalLinkIcon />}
                >
                  ir para o app
                </Button>
              }
              renderElse={
                <>
                  <div className="redirectApp__divider" />

                  <div className="redirectApp__qrCodeContainer">
                    {qrCodeBase64 && (
                      <img
                        src={qrCodeBase64}
                        className="redirectApp__qrcode"
                        alt="Imagem do QR Code que redireciona para o app do Itaú"
                      />
                    )}

                    <div>
                      <Text as="h2" size="md" className="redirectApp__subtile">
                        próximos passos
                      </Text>
                      <ul className="redirectApp__list">
                        {nextStepsItems.map((item) => (
                          <li className="redirectApp__listItem">
                            {item.icon}
                            <Text
                              as="p"
                              size="md"
                              className="redirectApp__listItemText"
                            >
                              {item.text}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RedirectApp

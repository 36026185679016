import { Icon } from "@garantidos/components"

import Accordion from "@mobi/ds/components/Accordion"
import Text from "@mobi/ds/components/Text"

import useTwoWayInfo from "./hooks"

import "./style.scss"

const Info = ({ showOnModal = false }) => {
  const { items, footerTexts } = useTwoWayInfo()
  return (
    <div className="twoWayInfo twoWay__addComponent">
      <div
        className={`twoWayInfo__content ${
          showOnModal ? "twoWayInfo__content--onModal" : ""
        }`}
      >
        <Text
          as={showOnModal ? "h3" : "h2"}
          size="lg"
          className="twoWayInfo__subtitle"
        >
          entenda como funciona cada etapa do consórcio
        </Text>
        <div className="twoWayInfo__faq">
          <Accordion items={items} oneAtATime />
        </div>
        <div className="twoWayInfo__footer">
          <Icon icon="aviso" className="twoWayInfo__icon" aria-hidden />
          <Text
            as={showOnModal ? "h4" : "h3"}
            size="lg"
            className="twoWayInfo__footerTitle"
          >
            o consórcio não é como um empréstimo ou financiamento!
          </Text>
          {footerTexts.map((text) => (
            <Text as="p" className="twoWayInfo__footerText" key={text}>
              {text}
            </Text>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Info

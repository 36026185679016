import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:EscolhaDoConsorcio",
    hashedCpf
  }
  const segment = {
    event: "page viewed",
    data: {
      step: "4-category",
      step_name: "category",
      step_order: 4
    }
  }

  trackPageview({ ga, segment })
}

const loaderPageview = () => {
  const ga = {
    label: "IT:NL:NCC:Consorcio:Loader"
  }

  trackPageview({ ga })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:EscolhaDoConsorcio:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:EscolhaDoConsorcio"
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "category"
    }
  }

  trackEvent({ ga, segment })
}

const categorySelected = (tracking, category) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:EscolhaDoConsorcio:${tracking}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:EscolhaDoConsorcio"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "category",
      category
    }
  }

  trackEvent({ ga, segment })
}

export { pageview, loaderPageview, back, categorySelected }

import { useEffect, useLayoutEffect, useState } from "react"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import generateQrCode from "@mobi/utils/events/qrcode"

import { steps } from "pages/Proposal/steps"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useRedirectApp = () => {
  const hasMobileView = useMediaQuery("tablet")

  const {
    cpfData,
    isLoading,
    errorMessage,
    setErrorMessage,
    errorHandler,
    sendProposalData,
    proposalData
  } = useProposalContext()
  const { hashedCpf } = cpfData
  const { app_link } = proposalData

  const {
    redirectApp: { title }
  } = steps

  useEffect(() => {
    trackers.pageview(hashedCpf)
  }, [])

  const handleRedirectApp = async () => {
    if (isLoading) return
    const payload = { redirect_app: true }
    try {
      await sendProposalData({
        payload
      })
      trackers.advanceToApp(hashedCpf)
      window.open(app_link, "_self")
    } catch (error) {
      trackers.error(error?.message)
      errorHandler(error)
    }
  }

  const [qrCodeBase64, setQrCodeBase64] = useState("")

  useLayoutEffect(() => {
    async function getQrCodeBase64() {
      await generateQrCode(app_link, {}, (_, base64) => {
        setQrCodeBase64(base64)
      }).catch((error) => {
        trackers.error(error?.message)
      })
    }
    if (!hasMobileView && !qrCodeBase64 && app_link) {
      getQrCodeBase64()
    }
  }, [hasMobileView, app_link])

  return {
    handleRedirectApp,
    isLoading,
    title,
    qrCodeBase64,
    hasMobileView,
    errorMessage,
    setErrorMessage
  }
}

export default useRedirectApp

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { proposalHttpController } from "@garantidos/services"

import { useStep } from "@mobi/libraries/step"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "../../../Softlead/fields"

const useEmailEdit = ({
  toggleModal,
  setSuccessMessage,
  newEmail,
  setNewEmail
}) => {
  const {
    setIsLoading,
    errorMessage,
    setErrorMessage,
    errorHandler,
    fetchProposalData,
    stepInfo,
    proposalData
  } = useProposalContext()
  const { previousStep } = stepInfo
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const EDIT_EMAIL_ERROR_MESSAGE = "Numero maximo de tentativas atingido"
  const { formatSendPayload } = usePayloadFormatters(fields)
  const { prev } = useStep()

  const onSubmitCallback = async (formData) => {
    try {
      if (newEmail === proposalData.proponent_email) {
        setErrorMessage("o e-mail inserido é igual ao e-mail já informado")
        return
      }
      setIsLoading(true)
      const payload = formatSendPayload(formData)
      await proposalHttpController({ payload, method: "editEmail" })
      setErrorMessage()
      toggleModal(false)
      setNewEmail(proposalData.proponent_email)
      setSuccessMessage("e-mail atualizado com sucesso")
    } catch (error) {
      if (
        error?.statusCode === 422 &&
        error?.requestMessage === EDIT_EMAIL_ERROR_MESSAGE
      ) {
        errorHandler(error)
        setErrorMessage(
          "email alterado 3 vezes Você será redirecionado ao início"
        )
        setTimeout(() => {
          fetchProposalData({ step: previousStep })
          prev(previousStep)
        }, 3000)
        return
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    fields,
    onSubmitCallback,
    form,
    errorMessage,
    setErrorMessage,
    setSuccessMessage
  }
}

export default useEmailEdit

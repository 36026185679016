import { Helmet } from "react-helmet"

import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import If from "@mobi/components/If"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import Splitted from "../../components/Splitted"
import SuggestionCard from "./components/SuggestionCard"

import useSimulation from "./hooks"

import "./style.scss"

const Simulation = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    assetLimits,
    installmentLimits,
    title,
    simulationTypeSelected,
    handlePurchaseTimeInput,
    handleSimulationTypeInput
  } = useSimulation()

  const { isValid } = form
  const {
    assetInputValue,
    installmentInputValue,
    purchaseTime,
    simulationType
  } = fields

  return (
    <div className="simulation">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Splitted
        contentType="simulation"
        data-aos="fade"
        data-aos-duration="150"
        data-aos-offset="-1000"
      >
        <div
          className="simulation__formWrapper"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-offset="-1000"
        >
          <Stepper title="vamos simular seu consórcio" index={2} max={3} />
          <HousingAlert
            message={errorMessage}
            setMessage={setErrorMessage}
            focusOnText
            type="error"
          />
          <Text as="h1" className="simulation__title">
            conta mais sobre o seu objetivo
          </Text>
          <Form
            onSubmit={onSubmitCallback}
            form={form}
            className="simulation__form"
          >
            <Text as="p" className="simulation__label">
              Como você prefere simular?
            </Text>
            <div className="simulation__simulationType" role="radiogroup">
              <Field
                field={simulationType}
                form={form}
                data-testid={simulationType.name}
                onInput={handleSimulationTypeInput}
                required
              />
            </div>

            <div className="simulation__inputContainer">
              <If
                condition={simulationTypeSelected === "installment_value"}
                renderIf={
                  <Field
                    key={installmentInputValue.name}
                    field={installmentInputValue}
                    form={form}
                    data-testid={installmentInputValue.name}
                    description={`valor entre ${installmentLimits.min} e ${installmentLimits.max}`}
                  />
                }
                renderElse={
                  <Field
                    key={assetInputValue.name}
                    field={assetInputValue}
                    form={form}
                    data-testid={assetInputValue.name}
                    description={`valor entre ${assetLimits.min} e ${assetLimits.max}`}
                  />
                }
              />
              <If
                condition={simulationTypeSelected === "installment_value"}
                renderIf={<SuggestionCard />}
              />
            </div>

            <Text
              as="p"
              className="simulation__labelWhen"
              id="purchase-time-label"
              tabindex="0"
            >
              Quando você espera ter o seu bem?
            </Text>
            <div className="simulation__radioGroup" role="radiogroup">
              <Field
                field={purchaseTime}
                form={form}
                onInput={handlePurchaseTimeInput}
                required
              />
            </div>
            <Sender
              className="simulation__sender"
              onPrev={handlePrevStep}
              isNextDisabled={!isValid}
              nextLabel="continuar"
              data_testid="submit"
            />
          </Form>
        </div>
      </Splitted>
    </div>
  )
}

export default Simulation

import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import getData from "./data"

import "./style.scss"

const MarketplaceCards = ({
	app = "",
	marketplaceCallback = () => {},
	cards = "all"
}) => {
	return (
		<>
			<div className="marketplaceWrapper">
				{getData(app, cards).map((card, index) => (
					<div
						className="marketplaceCard"
						id={`marketplaceCard-${index}`}
						key={index}
					>
						<div className="marketplaceCard__thumb">
							<img
								src={card.Image}
								alt={card.imageAlt}
								className="marketplaceCard__image"
								aria-hidden
							/>
						</div>
						<div className="marketplaceCard__content">
							<Text className="marketplaceCard__title" as="h3">
								{card.title}
							</Text>
							<Text className="marketplaceCard__text" as="p">
								{card.text}
							</Text>
						</div>
						<div className="marketplaceCard__cta">
							<Button
								className="marketplaceCard__button"
								tagName={"a"}
								href={card.ctaUrl}
								target="_blank"
								rel="noreferrer"
								role="button"
								onClick={() => marketplaceCallback(card.productName, index)}
								{...(card.ctaAriaLabel
									? { "aria-label": card.ctaAriaLabel }
									: {})}
							>
								{card.ctaLabel}
							</Button>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default MarketplaceCards

import {
  BANK_CODES,
  DOCUMENT_TYPE_OPTIONS
} from "pages/Proposal/constants/dropdownOptions"

export const fields = {
  documentType: {
    type: "select",
    name: "complementary_document_type",
    label: "tipo do documento",
    placeholder: "",
    options: DOCUMENT_TYPE_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  complementaryDocument: {
    type: "text",
    name: "complementary_document_number",
    label: "número do documento",
    rules: {
      distinctDocument: true,
      limitCharacter: true
    }
  },
  issuingDate: {
    type: "birthdate",
    name: "complementary_document_issue_date",
    label: "data de emissão",
    sendFormat: "date",
    fetchFormat: "date",
    rules: {
      validIssuingDate: true
    }
  },
  issuingAgency: {
    type: "text",
    name: "complementary_document_issuing_agency",
    label: "órgão emissor",
    sendFormat: "text",
    fetchFormat: "text"
  },
  bankData: {
    type: "radio",
    name: "bank_data",
    label: "deseja informar seus dados bancários?",
    options: [
      {
        id: "bank_data_no",
        value: "nao",
        label: "não"
      },
      {
        id: "bank_data_yes",
        value: "sim",
        label: "sim"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  },
  bankCode: {
    type: "ariaSelect",
    name: "bank_code",
    label: "seu banco",
    placeholder: "Selecione o banco",
    searchPlaceholder: "buscar bancos",
    options: BANK_CODES,
    fetchFormat: "ariaSelect"
  },
  bankAgencyNumber: {
    type: "text",
    name: "bank_agency_number",
    label: "agência"
  },
  bankAccountNumber: {
    type: "text",
    name: "bank_account_number",
    label: "conta"
  },
  bankAccountDigitNumber: {
    type: "text",
    name: "bank_account_digit_number",
    label: "dígito"
  }
}

import { Controller } from "react-hook-form"

import numberFormatter from "@mobi/utils/formatters/number"
import mergeDeep from "@mobi/utils/object/mergeDeep"

import { IdsInput } from "@ids/react"

export default function IdsCurrency({
  name,
  formProps: { control },
  defaultValue = 0,
  rules = { min: null, max: null },
  validators: { rules: propRules = {}, messages: propMessages = {} } = {},
  disabled,
  required,
  ...rest
}) {
  const DEFAULT_MESSAGES = {
    required: "campo obrigatório",
    ...(rules.min && {
      min: `o valor deve ser maior que R$ ${numberFormatter({
        value: rules.min,
      })}`,
    }),
    ...(rules.max && {
      max: `o valor deve ser menor que R$ ${numberFormatter({
        value: rules.max,
      })}`,
    }),
  }

  const DEFAULT_RULES = {
    validate: {
      ...(rules.min && { min: (value) => value >= rules.min }),
      ...(rules.max && { max: (value) => value <= rules.max }),
    },
  }

  const formRules = mergeDeep(DEFAULT_RULES, propRules, { required })
  const errorMessages = mergeDeep(DEFAULT_MESSAGES, propMessages)

  function formatCurrency(value = 0) {
    const formattedValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    })
    return formattedValue
  }

  function parseCurrency(value) {
    if (!value) return 0

    let parsedValue = String(value)
    parsedValue = parsedValue.replace(/\D/g, "")
    parsedValue = parsedValue.trim()
    parsedValue = parsedValue.replace(/(\d{2})$/, ".$1")

    return parseFloat(parsedValue)
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={formRules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <IdsInput
          {...rest}
          name={field.name}
          prefix="R$"
          autoComplete="off"
          inputMode="numeric"
          ref={field.ref}
          disabled={field.disabled || disabled}
          required={required}
          value={formatCurrency(field.value)}
          defaultValue={formatCurrency(defaultValue)}
          onChange={(e) => {
            const inputValue = e.currentTarget.value
            const parsedInputValue = parseCurrency(inputValue)
            field.onChange(parsedInputValue)
          }}
          onBlur={field.onBlur}
          formFieldProps={{
            errorMessage: errorMessages[fieldState.error?.type],
            invalid:
              fieldState.isTouched && fieldState.isDirty && fieldState.invalid,
          }}
        />
      )}
    />
  )
}

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:ConsorcioE2E:NaoCorrentista", hashedCpf }
  const ga4 = {
    eventName: "screen_view",
    customPath: "/consorcio/contratacao/nao-correntista",
    implementationTeam: "shared-experiences-pf:growth-vendas-digitais-e-vitrine"
  }
  trackPageview({ ga, ga4 })
}

const marketplaceTracking = (product) => {
  const marketplaceParser = {
    CreditoImobiliario: {
      gaText: "SimuleAgora",
      ga4Detail: "btn:simule-agora",
      segment: "crossell-imob-ncc-consortium"
    },
    Emprestimo: {
      gaText: "ConfiraNossosProdutos",
      ga4Detail: "btn:confira-nossos-produtos",
      segment: "crossell-shopping-ncc-consortium"
    },
    FinanciamentoVeiculo: {
      gaText: "QueroUmCarroNovo",
      ga4Detail: "btn:quero-um-carro-novo",
      segment: "crossell-vehicles-ncc-consortium"
    }
  }

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:NaoCorrentista:${marketplaceParser[product]?.gaText}`,
    page: {
      nome: "NaoCorrentista"
    }
  }

  const ga4 = {
    eventName: "click",
    customPath: "/consorcio/contratacao/nao-correntista",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    detail: marketplaceParser[product]?.ga4Detail
  }

  trackEvent({ ga, ga4, segment: marketplaceParser[product]?.segment })
}

const openAccountTracking = (ev) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:NaoCorrentista:${ev?.target?.text}`,
    page: {
      nome: "NaoCorrentista"
    }
  }
  const ga4 = {
    eventName: "click",
    customPath: "/consorcio/contratacao/nao-correntista",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    detail: "btn:abra-sua-conta"
  }

  const segment = "crossell-click-ncc-consortium"

  trackEvent({ ga, ga4, segment })
}

const error = (errorMessage) => {
  const ga4 = {
    eventName: "alert",
    customPath: "/consorcio/contratacao/nao-correntista",
    implementationTeam:
      "shared-experiences-pf:growth-vendas-digitais-e-vitrine",
    description: errorMessage
  }
  trackEvent({ ga4 })
}

export { pageview, marketplaceTracking, openAccountTracking, error }

import { useEffect } from "react"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import { useStep } from "@mobi/libraries/step"

import "./style.scss"

const Sender = ({
	id,
	onNext = null,
	onPrev = null,
	isNextDisabled = false,
	showNext = true,
	showPrev = true,
	nextLabel = "próximo",
	prevLabel = "voltar",
	nextButtonClass = "",
	prevButtonClass = "",
	className = "",
	data_testid = "",
	isLoading = false,
	floatingButton = false
}) => {
	const { isFirst } = useStep()
	const shouldShowPrevButton = !isFirst && showPrev

	const handleClickPrev = () => {
		if (!onPrev) return
		onPrev()
	}

	const handleClickNext = (event) => {
		if (!onNext) return
		event.preventDefault()
		onNext()
	}

	return (
		<div
			className={`sender ${
				floatingButton ? "sender--floating" : ""
			} ${className} `}
		>
			<If
				condition={shouldShowPrevButton}
				renderIf={
					<Button
						type="button"
						variant="secondary"
						className={`sender__button ${prevButtonClass}`}
						fluid
						disabled={isLoading}
						onClick={handleClickPrev}
						id="sender_back_button"
						isLoading={isLoading}
					>
						{prevLabel}
					</Button>
				}
			/>

			<If
				condition={showNext}
				renderIf={
					<Button
						id={id}
						type="submit"
						className={`sender__button ${nextButtonClass}`}
						disabled={isNextDisabled || isLoading}
						fluid
						onClick={handleClickNext}
						data-testid={data_testid}
						isLoading={isLoading}
					>
						{nextLabel}
					</Button>
				}
			/>
		</div>
	)
}
//

export default Sender

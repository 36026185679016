import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { proposalHttpController } from "@garantidos/services"
// TODO: normalizar como consumir os utils. Se de cada pasta individualmente, ou se por um index na pasta de utils
import { toPhone } from "@garantidos/utils/js/formatters/phone"

import { useStep } from "@mobi/libraries/step"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "../fields"

const useSmsEdit = ({
  toggleModal,
  handleResetTimer,
  setSuccessMessage,
  telephone,
  setTelephone,
  clearVerificationCode
}) => {
  const {
    setIsLoading,
    errorMessage,
    setErrorMessage,
    errorHandler,
    fetchProposalData,
    stepInfo,
    proposalData
  } = useProposalContext()
  const { previousStep } = stepInfo
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const EDIT_SMS_ERROR_MESSAGE = "Numero maximo de tentativas atingido"
  const { formatSendPayload } = usePayloadFormatters(fields)
  const { prev } = useStep()

  const onSubmitCallback = async (formData) => {
    try {
      if (telephone === toPhone(proposalData.proponent_phone)) {
        setErrorMessage("o número inserido é igual ao número já informado")
        return
      }

      setIsLoading(true)
      const payload = formatSendPayload(formData)
      handleResetTimer()
      await proposalHttpController({ payload, method: "editPhoneNumber" })
      setErrorMessage()
      clearVerificationCode()
      setTelephone(toPhone(proposalData.proponent_phone))
      toggleModal(false)
      setSuccessMessage(
        "telefone atualizado e código reenviado, verifique seu novo código no SMS"
      )
    } catch (error) {
      if (
        error?.statusCode === 422 &&
        error?.requestMessage === EDIT_SMS_ERROR_MESSAGE
      ) {
        errorHandler(error)
        setErrorMessage(
          "número alterado 3 vezes Você será redirecionado ao início"
        )
        setTimeout(() => {
          fetchProposalData({ step: previousStep })
          prev(previousStep)
        }, 3000)
        return
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    fields,
    onSubmitCallback,
    form,
    errorMessage,
    setErrorMessage,
    setSuccessMessage
  }
}

export default useSmsEdit

import AriaLabel from "@mobi/components/AriaLabel"
import Text from "@mobi/ds/components/Text"

const StoryblokRichText = ({
  as,
  text,
  linkClassName,
  linkCallback = () => {
    return
  },
  linkAriaLabel,
  ...props
}) => {
  return (
    <>
      {!text.content
        ? !text.content && text
        : text.content?.map((fullText) => (
            <div key={Math.floor(Math.random() * 9999)}>
              <AriaLabel
                ariaText={fullText.content?.map((part) => part.text).join(" ")}
              >
                <Text as={as} {...props}>
                  {fullText.content?.map((text) => {
                    const marks = []

                    text.marks?.map((mark) => {
                      marks.push(mark.type)
                    })

                    if (marks.includes("link"))
                      return (
                        <a
                          className={linkClassName}
                          href={text.marks[0].attrs.href}
                          target="_blank"
                          rel="noreferrer"
                          aria-label={linkAriaLabel || text.text}
                          onClick={() => linkCallback(text.text)}
                          key={Math.floor(Math.random() * 9999)}
                        >
                          {text.text}
                        </a>
                      )
                    else if (marks.includes("bold") && marks.includes("italic"))
                      return (
                        <span aria-hidden={true} key={index}>
                          <i>
                            <strong>{text.text} </strong>
                          </i>
                        </span>
                      )
                    else if (marks.includes("bold"))
                      return (
                        <span
                          aria-hidden={true}
                          key={Math.floor(Math.random() * 9999)}
                        >
                          <strong>{text.text} </strong>
                        </span>
                      )
                    else if (marks.includes("italic"))
                      return (
                        <span
                          aria-hidden={true}
                          key={Math.floor(Math.random() * 9999)}
                        >
                          <i>{text.text} </i>
                        </span>
                      )
                    else if (text.type === "hard_break") return <br />

                    return (
                      <span
                        key={Math.floor(Math.random() * 9999)}
                        aria-hidden={true}
                      >
                        {text.text}{" "}
                      </span>
                    )
                  })}
                </Text>
              </AriaLabel>
            </div>
          ))}
    </>
  )
}

export default StoryblokRichText

export const offerUnavailableMarketplaceCards = [
  {
    productName: "CreditoImobiliario",
    image: "marketplace-imobi",
    imageAlt: "Ilustração de uma mão segurando um cartão de crédito Itaú",
    title: "Crédito Imobiliário",
    text: "Com o Itaú, você realiza o sonho da casa própria com taxas que cabem no seu bolso! Você pode financiar até 90% do valor do imóvel.",
    ctaUrl:
      "https://credito-imobiliario.itau.com.br/?utm_sou=consorcio&utm_med=cross-sell&utm_camp=marketplace",
    ctaLabel: "simule agora"
  },
  {
    productName: "Emprestimo",
    image: "marketplace-loan",
    imageAlt:
      "Empréstimos itaú. Imagem contendo casa, carro, sacola de compras, moeda e chat de atendimento",
    title: "empréstimos para você",
    text: "Empréstimos e produtos exclusivos pré-aprovados para você. Acesse e confira, a gente compara e mostra sua melhor oferta. Sujeito à análise.",
    ctaUrl:
      "https://emprestimo.itau.com.br/?utm_sou=consorcio&utm_med=cross-sell&utm_camp=marketplace",
    ctaLabel: "confira nossos produtos"
  },
  {
    productName: "FinanciamentoVeiculo",
    image: "marketplace-vehicle",
    imageAlt: "Imagem contendo veículo em movimento",
    title: "financiamento de veículos",
    text: "Em busca de um carro novo? Conheça nosso simulador e veja nossa proposta feita sob medida pra você!",
    ctaUrl:
      "https://www.itau.com.br/emprestimos-financiamentos/veiculos/simulacao/?utm_sou=consorcio&utm_med=cross-sell&utm_camp=marketplace",
    ctaLabel: "quero um carro novo"
  }
]

import { useContext, useEffect, useState } from "react"

import { getUrlParams } from "@garantidos/utils"
import {
  getCpfFromStorage,
  getItem,
  scrollToTop,
  setItem,
} from "@garantidos/utils"

import jwt_decode from "jwt-decode"

const useStepHandler = ({
  context,
  cpfPayloadKey,
  retrieveProposalRoute,
  retrieveProposalRecaptcha,
  scrollOnChange,
  onChangeCallback,
  startStep,
  mockData,
  useLoaderComponent,
}) => {
  const [loaded, setLoaded] = useState(false)

  const {
    stepInfo: { currentStep },
    updateProposalData,
    isLoading,
    isLoaderComponentVisible,
    setIsLoaderComponentVisible,
    checkHashedCpf,
    updateCpfData,
    retrieveProposal,
    isStepVisible,
    retrieveProposalRemarketing,
  } = useContext(context)

  const getUuidFromToken = (token) => {
    const { data = null } = jwt_decode(token) ?? {}
    const { uuid = "" } = JSON.parse(data) ?? {}
    return uuid
  }

  useLoaderComponent
    ? setIsLoaderComponentVisible(true)
    : setIsLoaderComponentVisible(false)

  const init = async () => {
    const rmkId = getUrlParams("rmk_id")
    const urlToken = getUrlParams("token")

    setItem("currentStepOneClick", currentStep)

    const sessionToken = JSON.parse(getItem("appToken"))

    if (rmkId || urlToken) {
      try {
        const remarketingId = rmkId || getUuidFromToken(urlToken) || ""
        if (!remarketingId) return
        await retrieveProposalRemarketing({
          route: retrieveProposalRoute,
          recaptcha: retrieveProposalRecaptcha,
          remarketingId,
        })
      } catch {}
    } else if (sessionToken) {
      try {
        await retrieveProposal({
          route: retrieveProposalRoute,
          recaptcha: retrieveProposalRecaptcha,
        })
      } catch {}
    }

    const cpfData = getCpfFromStorage()

    if (cpfData) {
      const { cpf, hashedCpf } = JSON.parse(cpfData)
      const payload = {
        [cpfPayloadKey]: cpf,
      }
      if (!hashedCpf) {
        await checkHashedCpf(cpf)
      } else {
        await updateCpfData({ cpf, hashedCpf })
      }
      await updateProposalData({ payload })
    }

    if (mockData) {
      await loadMockData()
    }

    setLoaded(true)
  }

  const loadMockData = async () => {
    try {
      const step = startStep || currentStep

      if (typeof mockData !== "object") return

      const data = mockData[step]
      if (!data) return

      updateProposalData({ payload: data })
    } catch (error) {
      console.log("Error loading mock data: ", error?.message)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!scrollOnChange) return
    const { scroll, preventScroll } = scrollOnChange
    if (!scroll) return
    if (preventScroll && preventScroll.includes(currentStep)) return
    scrollToTop()
  }, [currentStep])

  useEffect(() => {
    if (onChangeCallback && typeof onChangeCallback === "function") {
      onChangeCallback()
    }
  }, [currentStep])

  return {
    currentStep,
    isLoading,
    isLoaderComponentVisible,
    loaded,
    isStepVisible,
  }
}

export default useStepHandler

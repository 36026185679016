import { useEffect, useState } from "react"

import { useForm } from "@garantidos/hooks"
import { focusOnLogo, formatters } from "@garantidos/utils"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const FULL_ASSET_ERROR_MESSAGE = "Grupo selecionado cheio"
const ERROR_MESSAGE = ["Proposta já enviada", "Proposta em processamento"]
const SAME_DOCUMENT_ERROR_MESSAGE =
  "Já existe uma solicitação de contato para o CPF informado"
const CPF_MANY_SIMULATIONS_ERROR_MESSAGE = "Aguarde a atualização de crédito"

const STEP_CONTEXT = {
  stepId: "consorcio-e2e-summary-11",
  stepName: "summary",
  stepNumber: 11
}

const useSummary = () => {
  const isMobile = useMediaQuery("mobile")
  const [formattedData, setFormattedData] = useState({})
  const [successMessage, setSuccessMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const { form } = useForm({ context: ProposalContext })
  const { next, prev } = useStep()
  const {
    stepInfo,
    fetchProposalData,
    sendProposalData,
    updateProposalData,
    proposalData,
    updateStepInfo,
    cpfData,
    isLoading,
    errorMessage,
    setErrorMessage
  } = useProposalContext()

  const { redirectToRejectionPage } = useRejectionRedirect()
  const { previousStep, nextStep, currentFlow } = stepInfo
  const {
    summary: { title: pageTitle }
  } = steps
  const { hashedCpf } = cpfData
  const [email, setEmail] = useState(proposalData.proponent_email)

  const onSubmitCallback = async () => {
    if (isLoading) return
    try {
      trackers.advance()
      trackers.submitForm()
      trackers.hiringCompleted(proposalData)

      const payload = {}

      await sendProposalData({ payload })
      next(nextStep)
    } catch (error) {
      if (error?.statusCode === 404) {
        redirectToRejectionPage(errorsEnum.CPF_NO_CREDIT_NEW_QUERY)
        return
      }

      if (
        error?.statusCode === 409 &&
        error?.requestMessage === ERROR_MESSAGE[0]
      ) {
        setErrorMessage(ERROR_MESSAGE[0])
        return
      }

      if (
        error?.statusCode === 409 &&
        error?.requestMessage === ERROR_MESSAGE[1]
      ) {
        setErrorMessage(ERROR_MESSAGE[1])
        return
      }

      if (
        error?.statusCode === 409 &&
        error?.requestMessage === SAME_DOCUMENT_ERROR_MESSAGE
      ) {
        redirectToRejectionPage(errorsEnum.SAME_DOCUMENT_PROPOSAL)
        return
      }

      if (
        error?.statusCode === 500 &&
        error?.requestMessage === CPF_MANY_SIMULATIONS_ERROR_MESSAGE
      ) {
        redirectToRejectionPage(errorsEnum.CPF_MANY_SIMULATIONS)
        return
      }

      if (error?.requestMessage === FULL_ASSET_ERROR_MESSAGE) {
        const newStepInfo = {
          current_step: "asset",
          previous_step: "simulation",
          next_step: "summary",
          flow: currentFlow
        }
        updateStepInfo(newStepInfo)
        await updateProposalData({
          payload: {
            fromSummary: true,
            assets: error.step_data.assets
          }
        })
        next("asset")
        return
      }

      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()
      await fetchProposalData({ step: previousStep })
      prev(previousStep)
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const getTitle = (category = "") => {
    const categoryParser = {
      "VEICULOS LEVES": "Seu consórcio de carro",
      IMOVEL: "Seu consórcio de imóvel",
      MOTOCICLETAS: "Seu consórcio de moto",
      "VEICULOS PESADOS": "Seu consórcio de veículo pesado"
    }
    const parsedCategory = categoryParser[category] ?? "seu consórcio"
    return parsedCategory
  }

  const formatData = (objData) => {
    if (!objData) return {}
    const data = {
      assetCode: objData.asset_code,
      title: getTitle(objData.category),
      installmentValue: formatters.toCurrency(objData.installment_value),
      assetValue: formatters.toCurrency(objData.asset_value),
      percentageMonthlyFee: `${formatters.floatToDisplayableNumber(
        objData.percentage_total_fee / objData.remaining_months
      )}%`,
      percentageTotalFee: `${formatters.floatToDisplayableNumber(
        objData.percentage_total_fee
      )}%`,
      reserveFund: Number.isInteger(objData.reserve_fund)
        ? `${objData.reserve_fund}%`
        : `${formatters.floatToDisplayableNumber(objData.reserve_fund)}%`,
      totalValue: formatters.toCurrency(objData.credit_value),

      remainingMonths: objData.remaining_months,
      installmentDueDay: objData.installment_due_day,
      groupSituation:
        objData.group_situation.toLowerCase() === "andamento"
          ? "em andamento"
          : "em formação",
      groupCode: objData.group_code,
      averageBidPercentage: objData.average_bid_percentage
        ? `${formatters.floatToDisplayableNumber(
            objData.average_bid_percentage
          )}%`
        : ""
    }
    return data
  }

  const totalValues = [
    formattedData.assetValue,
    `${formattedData.percentageMonthlyFee} ao mês (${formattedData.percentageTotalFee} total)`,
    `${formattedData.reserveFund} total`,
    formattedData.totalValue
  ]

  const installmentsValues = [
    formattedData.installmentValue,
    "até 3 dias úteis",
    formattedData.remainingMonths,
    `todo dia ${formattedData.installmentDueDay}`
  ]

  const summaryValues = [
    formattedData.groupSituation,
    formattedData.groupCode,
    formattedData.averageBidPercentage
  ]

  const toggleModal = (state = null) => {
    if (state === null) {
      setModalShow(!modalShow)
    } else {
      setModalShow(state)
    }
  }

  const closeAllAlerts = () => {
    setErrorMessage("")
    setSuccessMessage("")
  }

  const editEmailButtonHandler = () => {
    toggleModal(true)
    setTimeout(
      () =>
        document
          .getElementsByClassName("ds-modal-container__close-button")[0]
          .focus(),
      1500
    )
    closeAllAlerts()
  }

  const handleCloseSuccessAlert = () => {
    setSuccessMessage("")
  }

  useEffect(() => {
    const formattedSummaryData = formatData(proposalData)
    setFormattedData(formattedSummaryData)
    trackers.pageview(hashedCpf)
    focusOnLogo()
  }, [])

  return {
    pageTitle,
    formattedData,
    totalValues,
    installmentsValues,
    summaryValues,
    handlePrevStep,
    onSubmitCallback,
    form,
    errorMessage,
    setErrorMessage,
    editEmailButtonHandler,
    email,
    setEmail,
    toggleModal,
    modalType: isMobile ? "center" : "pop",
    modalShow,
    setModalShow,
    successMessage,
    setSuccessMessage,
    handleCloseSuccessAlert
  }
}

export default useSummary

const totals = {
  title: "valores totais",
  labels: ["valor do crédito", "taxa de adm.", "fundo reserva", "total a pagar"]
}
const installments = {
  title: "parcelas",
  labels: [
    "valor médio das parcelas",
    "data 1ª parcela",
    "quantidade de parcelas",
    "data das demais parcelas"
  ]
}
const summary = {
  title: "resumo do grupo",
  labels: ["tipo de grupo", "número do grupo", "lance médio"]
}
const personal = {
  title: "informações pessoais",
  labels: "e-mail"
}

export { totals, installments, summary, personal }

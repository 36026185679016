export const fields = {
  token: {
    type: "token",
    name: "verification_code",
    label: "código"
  },
  phone: {
    type: "cellphone",
    name: "proponent_phone",
    label: "celular cadastrado",
    sendFormat: "phone",
    fetchFormat: "phone"
  }
}

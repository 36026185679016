export const fields = {
  cpf: {
    type: "cpf",
    name: "document_number",
    label: "cpf",
    sendFormat: "cpf",
    fetchFormat: "cpf"
  },
  name: {
    type: "name",
    name: "proponent_name",
    label: "nome completo",
    sendFormat: "text"
  },
  email: {
    type: "email",
    name: "proponent_email",
    label: "e-mail",
    rules: {
      domainMultipleChar: true
    }
  },
  phone: {
    type: "cellphone",
    name: "proponent_phone",
    label: "celular",
    sendFormat: "phone",
    fetchFormat: "phone"
  }
}

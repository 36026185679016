import { useEffect } from "react"

import { focusOnLogo } from "@garantidos/utils"

import { steps } from "pages/Proposal/steps"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useFinishedHiring = () => {
  const { cpfData } = useProposalContext()

  const { hashedCpf } = cpfData

  const {
    finishedHiring: { title }
  } = steps

  const marketplaceCallback = (product) => {
    trackers.marketplaceTracking(product)
  }

  useEffect(() => {
    trackers.pageview(hashedCpf)
    focusOnLogo()
  }, [])

  return {
    marketplaceCallback,
    title
  }
}

export default useFinishedHiring

import ImageMarketplaceAccounts from "./images/marketplace-accounts.jpg"
import ImageMarketplaceClick from "./images/marketplace-click-card.jpg"
import ImageMarketplaceLoan from "./images/marketplace-loan.jpg"
import ImageMarketplaceReneg from "./images/marketplace-reneg.png"
import ImageMarketplaceTagItau from "./images/marketplace-tag.png"
import ImageMarketplaceVehicles from "./images/marketplace-vehicle.jpg"

const getData = (app, cards = "all") => {
	let data = []
	if (cards === "all") {
		data = [
			{
				Image: ImageMarketplaceClick,
				imageAlt: "Ilustração de uma mão segurando um cartão de crédito click",
				title: "cartão de crédito Itaú Click Platinum",
				text: "Peça agora seu cartão Click do Itaú, um cartão livre de anuidades e com limite de até R$10.000 ! Utilizando seu Click, você tem Tag Itaú livre de mensalidade pra sempre e muitos outros benefícios. Sujeito à análise.",
				ctaUrl: `https://cartoes.itau.com.br/formulario/?tracker=0&step=0&card=123&utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "peça já o seu",
				productName: "CartaoCreditoClick"
			},
			{
				Image: ImageMarketplaceLoan,
				imageAlt:
					"Empréstimos itaú. Imagem contendo casa, carro, sacola de compras, moeda e chat de atendimento",
				title: "os melhores empréstimos e produtos para vc",
				text: "Empréstimos e produtos exclusivos pré-aprovados para você. Acesse e confira, a gente compara e mostra sua melhor oferta. Sujeito à análise.",
				ctaUrl: `https://emprestimo.itau.com.br/?utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "confira agora",
				productName: "Emprestimo"
			},
			{
				Image: ImageMarketplaceVehicles,
				imageAlt: "Imagem contendo veículo em movimento",
				title: "financiamentos de veículos",
				text: "Em busca de um carro novo? Conheça nosso simulador e veja nossa proposta feita sob medida pra vc!",
				ctaUrl: `https://www.itau.com.br/emprestimos-financiamentos/veiculos/simulacao/?utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "quero um carro novo",
				productName: "FinanciamentoVeiculo"
			}
		]
	}
	if (cards === "emprestimo") {
		data = [
			{
				Image: ImageMarketplaceLoan,
				imageAlt:
					"Empréstimos itaú. Imagem contendo casa, carro, sacola de compras, moeda e chat de atendimento",
				title: "Aproveite os melhores empréstimos e produtos",
				text: "Empréstimos e produtos exclusivos pré-aprovados para você. Acesse e confira, a gente compara e mostra sua melhor oferta. Sujeito à análise.",
				ctaUrl: `https://emprestimo.itau.com.br/?utm_sou=${app}&utm_med=cross-sell&utm_camp=sobre-imovel-na`,
				ctaLabel: "confira agora",
				productName: "Emprestimo"
			}
		]
	}

	if (cards === "consorcioDefault") {
		data = [
			{
				Image: ImageMarketplaceClick,
				imageAlt: "Ilustração de uma mão segurando um cartão de crédito click",
				title: "cartão de crédito Itaú Click Platinum",
				text: "Peça agora seu cartão Click do Itaú, um cartão livre de anuidades e com limite de até R$10.000 ! Utilizando seu Click, você tem Tag Itaú livre de mensalidade pra sempre e muitos outros benefícios. Sujeito à análise.",
				ctaUrl: `https://cartoes.itau.com.br/formulario/?tracker=0&step=0&card=123&utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "peça já o seu",
				ctaAriaLabel: "peça já o seu cartão click platinum",
				productName: "CartaoCreditoClick"
			},
			{
				Image: ImageMarketplaceAccounts,
				imageAlt:
					"Ilustração de uma mão segurando um celular, ao lado de um balão com um ícone de pessoa e o símbolo de adição. Em plano de fundo tem a ilustração de uma mesa com uma xícara de café.",
				title: "abertura de contas",
				text: "Ainda não é Itaú? Abra sua conta sem sair de casa e aproveite os benefícios do banco que tem tudo pra você!",
				ctaUrl: `https://www.itau.com.br/contas/conta-corrente/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00001342-rvproduto&utm_term=consorcio-x-status-marketplace&utm_sou=${app}&utm_med=x&utm_camp=status&utm_cont=marketplace&utm_ter=23`,
				ctaLabel: "abra sua conta",
				ctaAriaLabel: "abra sua conta",
				productName: "AberturaDeContas"
			},
			{
				Image: ImageMarketplaceTagItau,
				imageAlt:
					"Ilustração de duas tags itaú junto com um texto 'benefício exclusivo'",
				title: "tag itaú",
				text: "Cliente com cartão de crédito ou débito Itaú também tem benefício exclusivo para adquirir sua tag de pedágios, passe sem limites em pedágios e estacionamentos, livre de mensalidades para sempre!!",
				ctaUrl: `https://www.itau.com.br/tagitau/?utm_source=rv&utm_medium=cross&utm_campaign=1016&utm_content=marketplaceveiculos&utm_audience=veiculos&utm_term=11524&utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "quero uma tag",
				ctaAriaLabel: "adquirir tag itaú",
				productName: "TagItau"
			}
		]
	}

	if (cards === "consorcioNoCredit") {
		data = [
			{
				Image: ImageMarketplaceReneg,
				imageAlt: "Ilustração de duas mãos se apertando em sinal de acordo",
				title: "renegociação",
				text: "Buscando resolver suas pendências? Confira nossos canais para identificar e renegociar eventuais pendências com condições especiais.",
				ctaUrl: `https://renegociacao.itau.com.br/?utm_source=consorcio&utm_medium=cross_sell&utm_campaign=marketplace&utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace_na`,
				ctaLabel: "confira agora",
				ctaAriaLabel: "confira agora renegociação",
				productName: "Reneg"
			},
			{
				Image: ImageMarketplaceLoan,
				imageAlt:
					"Empréstimos itaú. Imagem contendo casa, carro, sacola de compras, moeda e chat de atendimento",
				title: "os melhores empréstimos e produtos para você",
				text: "Empréstimos e produtos exclusivos pré-aprovados para você. Acesse e confira, a gente compara e mostra sua melhor oferta. Sujeito à análise.",
				ctaUrl: `https://emprestimo.itau.com.br/?utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "confira agora",
				ctaAriaLabel: "confira agora empréstimos",
				productName: "Emprestimo"
			},
			{
				Image: ImageMarketplaceClick,
				imageAlt: "Ilustração de uma mão segurando um cartão de crédito click",
				title: "cartão de crédito Itaú Click Platinum",
				text: "Peça agora seu cartão Click do Itaú, um cartão livre de anuidades e com limite de até R$10.000 ! Utilizando seu Click, você tem Tag Itaú livre de mensalidade pra sempre e muitos outros benefícios. Sujeito à análise.",
				ctaUrl: `https://cartoes.itau.com.br/formulario/?tracker=0&step=0&card=123&utm_sou=${app}&utm_med=cross-sell&utm_camp=marketplace`,
				ctaLabel: "peça já o seu",
				ctaAriaLabel: "peça já o seu cartão click platinum",
				productName: "CartaoCreditoClick"
			}
		]
	}

	return data
}

export default getData

import { formatters } from "@garantidos/utils"

import AriaLabel from "@mobi/components/AriaLabel"

export const categoryLabels = {
  "VEICULOS LEVES": "um carro novo ou usado",
  IMOVEL: "um imóvel novo ou usado",
  MOTOCICLETAS: "uma motocicleta nova ou usada",
  "VEICULOS PESADOS": "um veículo pesado novo ou usado"
}

export const summaryData = (objData, getBidInfo) => {
  if (!objData) return {}
  return {
    assetCode: objData.asset_code,
    assetValue: formatters.toCurrency(objData.asset_value),
    category: categoryLabels[objData.category],
    installmentValue: formatters.toCurrency(objData.installment_value),
    groupSituation:
      objData.group_situation.toLowerCase() === "andamento"
        ? "grupo em andamento, com prazo mais rápido para terminar"
        : "grupo ainda em formação",
    bidInfo: getBidInfo(objData.bid_amount, objData.average_bid_percentage),
    remainingMonths: objData.remaining_months,
    installmentDueDay: objData.installment_due_day,
    totalValue: formatters.toCurrency(objData.credit_value),
    reserveFund: Number.isInteger(objData.reserve_fund)
      ? `${objData.reserve_fund}%`
      : `${formatters.floatToDisplayableNumber(objData.reserve_fund)}%`,
    percentageMonthlyFee: `${formatters.floatToDisplayableNumber(
      objData.percentage_total_fee / objData.remaining_months
    )}%`,
    percentageTotalFee: Number.isInteger(objData.percentage_total_fee)
      ? `${objData.percentage_total_fee}%`
      : `${formatters.floatToDisplayableNumber(objData.percentage_total_fee)}%`
  }
}

export const summaryFaq = (formattedData, handleTrackItemFaq) => [
  {
    title: ["fundo reserva: ", <b>{`${formattedData.reserveFund} total`}</b>],
    children: (
      <AriaLabel
        ariaText={`Taxa de ${formattedData.reserveFund} do valor do crédito diluída em suas parcelas. Com ela, mantemos a segurança do grupo caso algumas pessoas não estejam com o pagamento em dia`}
      >
        <span aria-hidden>
          <b>Taxa de {formattedData.reserveFund} do valor do crédito</b>,
          diluída em suas parcelas. Com ela, mantemos a segurança do grupo caso
          algumas pessoas não estejam com o pagamento em dia.
        </span>
      </AriaLabel>
    ),
    index: 1,
    onOpen: (i) => handleTrackItemFaq("FundoReserva", i)
  },
  {
    title: [
      <AriaLabel
        ariaText={`taxa de administração média: ${formattedData.percentageMonthlyFee} ao mês`}
      >
        <span aria-hidden>
          taxa de adm. média: <b>{formattedData.percentageMonthlyFee} ao mês</b>
        </span>
      </AriaLabel>
    ],
    children: (
      <AriaLabel
        ariaText={`Taxa que cobre nossos serviços gerenciando o grupo. No total, ${formattedData.percentageTotalFee} do valor do crédito diluída em ${formattedData.percentageMonthlyFee} por mês nas suas parcelas.`}
      >
        <span aria-hidden>
          Taxa que cobre nossos serviços gerenciando o grupo.{" "}
          <b>
            No total, {formattedData.percentageTotalFee} do valor do crédito
            diluída em {formattedData.percentageMonthlyFee} por mês nas suas
            parcelas.{" "}
          </b>
        </span>
      </AriaLabel>
    ),
    index: 2,
    onOpen: (i) => handleTrackItemFaq("TaxaAdmMedia", i)
  },
  {
    title: "fundo comum",
    children: (
      <AriaLabel ariaText="Este valor corresponde ao crédito que você contratou, e permite a compra do bem para as pessoas contempladas.">
        <span aria-hidden>
          Este valor corresponde ao crédito que você contratou, e permite a
          compra do bem para as pessoas contempladas.
        </span>
      </AriaLabel>
    ),
    index: 3,
    onOpen: (i) => handleTrackItemFaq("FundoComum", i)
  }
]

import { useEffect, useRef, useState } from "react"

import classnames from "classnames"

const Icon = ({
  id = "",
  className = "",
  ariaHidden = "true",
  onClick,
  ...props
}) => {
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        const { default: iconImport } = await import(`images/icons/${id}.svg`)
        ImportedIconRef.current = iconImport
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [id])

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return (
      <ImportedIcon
        onClick={onClick}
        className={classnames(`icon`, {
          [id]: id,
          [className]: classnames,
        })}
        id={id}
        role="presentation"
        aria-hidden={ariaHidden}
        {...props}
      />
    )
  }

  return null
}

export default Icon

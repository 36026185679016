import { useContext, useEffect, useRef, useState } from "react"
import { useWatch } from "react-hook-form"

import { useFieldRules } from "@garantidos/hooks"
import { formatters } from "@garantidos/utils"

const useAriaSelect = ({
  placeholder,
  options,
  form,
  field,
  name,
  onChange
}) => {
  const errorMessages = {
    required: "campo obrigatório"
  }

  const [showModal, setShowModal] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [actualPlaceholder, setActualPlaceholder] = useState(placeholder)
  const [currentOptions, setCurrentOptions] = useState(options)
  const [selectedOption, setSelectedOption] = useState("")
  const [actualSelectedOption, setActualSelectedOption] = useState("")
  const [searchStatus, setSearchStatus] = useState("")
  const prevActiveElement = useRef(null)
  const initialized = useRef({
    value: false,
    removeErrorField: false,
    validators: false,
    errorFields: false,
    searchResult: false
  })

  const {
    control,
    setValue,
    context,
    validatorsDictionary,
    unregister,
    updateFormValidators,
    errors,
    getFieldState
  } = form

  const {
    updateProposalData,
    proposalData,
    errorFields,
    setErrorFields,
    setError
  } = useContext(context)

  const fieldValue = useWatch({ control, name })

  const { mountValidators } = useFieldRules({ field, validatorsDictionary })

  const resetSearch = () => {
    setSearchText("")
    setCurrentOptions(options)
    setActualSelectedOption(selectedOption)
  }

  const closeModal = () => {
    setTimeout(() => {
      if (!prevActiveElement.current) return
      prevActiveElement.current.focus()
      prevActiveElement.current = null
    }, 300)
    setShowModal(false)
  }

  const updateControlFieldValue = (value) => {
    const setValueOptions = {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    }
    setValue(name, value, setValueOptions)
    if (onChange) onChange(value)
  }

  const handleModalOpen = () => {
    resetSearch()
    const { activeElement } = document
    if (activeElement) {
      prevActiveElement.current = activeElement
    }
    setShowModal(true)
    setTimeout(() => {
      const modalCloseButton = document.querySelector(
        ".ds-modal-container button.ds-modal-container__close-button"
      )
      if (!modalCloseButton) return
      modalCloseButton.focus()
    }, 400)
  }

  const handleModalClose = (value) => {
    closeModal()
    setSelectedOption(value)
    const selected = options.find((option) => option.value === value)
    if (selected) {
      setActualPlaceholder(selected.label)
    }
    updateControlFieldValue(value)
  }

  const handleClearSelection = () => {
    setSelectedOption("")
    updateControlFieldValue("")
    setActualPlaceholder(placeholder)
    setSelectedOption("")
    closeModal()
  }

  const handleOptionSelected = (e) => {
    const { value } = e.target
    setActualSelectedOption(value)

    const button = document.querySelector("#ds-aria-select-modal-button")
    if (!button) return
    button.focus()
    button.scrollIntoView()
  }

  const filterOptions = (query) => {
    if (!query) setCurrentOptions(options)
    const filteredOptions = options.filter(({ label }) =>
      label.toLowerCase().includes(query.toLowerCase())
    )

    setCurrentOptions(filteredOptions)
  }

  const setValueFromProposalData = () => {
    const { fetchFormat } = field
    const rawValue = proposalData[name]
    if (rawValue === undefined) return
    const setValueOptions = {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    }
    const formatter = formatters.fetchFormatters[fetchFormat]
    const label = formatter({ value: rawValue, options: field.options })
    setValue(name, rawValue, setValueOptions)
    setActualPlaceholder(label)
    setSelectedOption(rawValue)
  }

  const removeErrorField = () => {
    if (!errorFields[name]) return
    const updatedErrors = {}

    Object.keys(errorFields).forEach((key) => {
      if (key !== name) {
        updatedErrors[key] = errorFields[key]
      }
    })
    setErrorFields(updatedErrors)
  }

  const getErrorMessage = () => {
    const { isDirty, isTouched } = getFieldState(name)
    if (!isDirty || !isTouched) return ""
    const error = errors[name]
    if (!error) return ""
    return errorMessages[error.type] || ""
  }

  const hideStatus = () => {
    setSearchStatus("")
  }

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (!initialized.current.searchResult) {
        setSearchStatus(
          `Atualmente exibindo ${currentOptions.length} ${
            currentOptions.length !== 1 ? "opções" : "opção"
          }. Busque por opções para filtrar`
        )
        initialized.current.searchResult = true
      }
      setSearchStatus(
        `Exibindo ${currentOptions.length} ${
          currentOptions.length !== 1 ? "opções" : "opção"
        }`
      )
    }, 1500)
  }, [currentOptions])

  useEffect(() => {
    if (setValue) setValueFromProposalData()
    return () => {
      if (unregister) {
        unregister(field.name)
      }
    }
  }, [])

  useEffect(() => {
    if (!initialized.current.value) {
      initialized.current.value = true
      return
    }
    if (fieldValue === undefined) return
    const payload = {
      [name]: fieldValue
    }
    updateProposalData({ payload })
  }, [fieldValue])

  useEffect(() => {
    if (!initialized.current.removeErrorField) {
      initialized.current.removeErrorField = true
      return
    }
    removeErrorField()
  }, [proposalData])

  useEffect(() => {
    if (!initialized.current.validators) {
      initialized.current.validators = true
      return
    }
    updateFormValidators(
      field.name,
      mountValidators({ proposalData, errorFields })
    )
  }, [proposalData])

  useEffect(() => {
    if (!initialized.current.errorFields) {
      initialized.current.errorFields = true
      return
    }
    if (errorFields[field.name]) {
      setError(field.name)
    }
    updateFormValidators(
      field.name,
      mountValidators({ proposalData, errorFields })
    )
  }, [errorFields])

  useEffect(() => {
    filterOptions(searchText)
  }, [searchText])

  return {
    handleModalOpen,
    handleModalClose,
    actualPlaceholder,
    showModal,
    searchText,
    handleOnChange,
    currentOptions,
    selectedOption,
    actualSelectedOption,
    handleOptionSelected,
    handleClearSelection,
    control,
    errorMessage: getErrorMessage(),
    searchStatus,
    hideStatus,
    setSearchText
  }
}

export default useAriaSelect

import useBankVariant from "hooks/useBankVariant"

const useProposal = ({ pageContext }) => {
  const { currentFlow } = pageContext
  useBankVariant()

  const initialStepData = {
    currentFlow,
    nextStep: "sms"
  }

  const stepChangeCallback = () => {
    const element = document?.querySelector(".ds-header__logo-link")
    element && element.focus()
  }

  return {
    initialStepData,
    stepChangeCallback
  }
}

export default useProposal

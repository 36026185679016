import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const getTrackingPageName = (has_credit, fromRejection) => {
  if (!has_credit) return "SucessoInteresseCPFNaoAprovado"
  if (fromRejection) return "SucessoSolicitacaoContatoErroInstabilidade"
  return "ContatoSolicitadoSucesso"
}

const pageview = (hashedCpf, has_credit, fromRejection) => {
  const ga = {
    label: `IT:NL:NCC:ConsorcioE2E:${getTrackingPageName(
      has_credit,
      fromRejection
    )}`,
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "13-finished with credit",
      step_name: "finished with credit",
      step_order: "13"
    }
  }

  trackPageview({ ga, segment })
}

const marketplaceTracking = (product, has_credit, fromRejection) => {
  const marketplaceParser = {
    CartaoCreditoClick: {
      gaText: "PecaJaOSeu",
      segment: {
        product: "CartaoClick",
        product_order: "01",
        step_name: "with credit"
      }
    },
    ContaIti: {
      gaText: "AbrirContaGratis",
      segment: {
        product: "ContaIti",
        product_order: "02",
        step_name: "with credit"
      }
    },
    AberturaDeContas: {
      segment: {
        product: "AbreContas",
        product_order: "03",
        step_name: "with credit"
      }
    },
    TagItau: {
      gaText: "QueroUmaTag",
      segment: {
        product: "TagItau",
        product_order: "04",
        step_name: "with credit"
      }
    },
    Reneg: {
      gaText: "Reneg",
      segment: {
        product: "Reneg",
        product_order: "04",
        step_name: "with credit"
      }
    },
    Emprestimo: {
      gaText: "Emprestimo",
      segment: {
        product: "Emprestimo",
        product_order: "05",
        step_name: "with credit"
      }
    }
  }

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:${getTrackingPageName(
      has_credit,
      fromRejection
    )}:${marketplaceParser[product]?.gaText}`,
    page: {
      nome: getTrackingPageName(has_credit, fromRejection)
    }
  }

  const segment = {
    event: "marketplace impression",
    data: {
      ...marketplaceParser[product]?.segment
    }
  }

  trackEvent({ ga, segment })
}

export { pageview, marketplaceTracking }

import Text from "@mobi/ds/components/Text"

import { contentAccordions, footerTexts } from "./data"
import * as trackers from "./trackings"

const useTwoWayInfo = () => {
  const mountContent = (content) => (
    <>
      {content.map((text) => (
        <Text as="p" key={text} className="twoWayInfo__faqText">
          {text}
        </Text>
      ))}
    </>
  )

  const handleTrackItem = (trackId, i) => {
    trackers.trackAccordion(trackId)
    setTimeout(() => {
      const element = document.querySelector(`.twoWayInfo__faq #ds-panel-${i}`)
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "center" })
    }, 250)
  }

  const items = contentAccordions.map(
    ({ title, content, trackingInfo }, index) => {
      const children = mountContent(content)
      return {
        trackingInfo,
        title,
        children,
        index: index + 1,
        onOpen: (i) => handleTrackItem(trackingInfo, i)
      }
    }
  )

  return {
    items,
    footerTexts
  }
}

export default useTwoWayInfo

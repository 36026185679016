import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import useSmsLoader from "./hooks"

import "./style.scss"

const Loader = ({ info, isVisible = true }) => {
  useSmsLoader()
  return (
    <If
      condition={isVisible}
      renderIf={
        <div className="smsLoader" aria-live="assertive" role="alert">
          <div className="smsLoader__circleLoaderWrap">
            <div className="smsLoader__leftWrap">
              <div className="smsLoader__loader" />
            </div>

            <div className="smsLoader__rightWrap">
              <div className="smsLoader__loader" />
            </div>
          </div>

          {info === "código confirmado" && (
            <svg
              aria-hidden
              className="smsLoader__iconLoaderCheck"
              x="0px"
              y="0px"
              width="471.197px"
              height="471.197px"
              viewBox="0 0 510 510"
              overflow="inherit"
              preserveAspectRatio="xMidYMid meet"
            >
              <polyline
                className="smsLoader__path"
                fill="none"
                points="227.599,322.099 290.599,259.099 180.599,149.099 "
                stroke="#fff"
                strokeWidth="20"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          <Text as="h1" className="smsLoader__text">
            {info}
            {info === "código confirmado" && (
              <span role="text">, carregamento finalizado</span>
            )}
          </Text>
        </div>
      }
    />
  )
}

export default Loader

import { Icon } from "@garantidos/components/"

import Text from "@mobi/ds/components/Text"

import WomanMotorcycle from "images/woman-motorcycle-02.svg"

import "./style.scss"

import { icon, subtitle, title } from "./data"

const FinishedHiringHeader = () => (
  <div
    className="finishedHiringHeader"
    data-aos="fade"
    data-aos-duration="500"
    data-aos-offset="-1000"
  >
    <div className="finishedHiringHeader__container">
      <div className="finishedHiringHeader__content">
        <Icon icon={icon} className="finishedHiringHeader__icon" />
        <Text as="h1" className="finishedHiringHeader__title">
          {title}
        </Text>
        <Text as="p" className="finishedHiringHeader__subtitle">
          {subtitle}
        </Text>
      </div>
      <WomanMotorcycle
        className="finishedHiringHeader__hightlightImage"
        aria-hidden
        data-aos="fade-left"
        data-aos-delay="100"
        data-aos-duration="500"
        data-aos-offset="-1000"
      />
    </div>
  </div>
)

export default FinishedHiringHeader

import { useEffect } from "react"

import { focusOnLogo } from "@garantidos/utils"

import { steps } from "pages/Proposal/steps"

import { pageview } from "./trackings"

const useOfferUnavailable = () => {
  const openAccountUrl =
    "https://www.itau.com.br/contas/conta-corrente/?utm_sou=consorcio&utm_med=cross-sell&utm_camp=marketplace"
  const {
    offerUnavailable: { title: pageTitle }
  } = steps

  useEffect(() => {
    focusOnLogo()
    pageview()
  }, [])

  return { pageTitle, openAccountUrl }
}

export default useOfferUnavailable

import { useEffect, useState } from "react"

import { STORYBLOK_IMAGE_URL } from "gatsby-env-variables"

import If from "@mobi/components/If"

const StoryblokImage = ({
  imagePng,
  className,
  classNamePicture,
  lazy = true,
  src,
  ...props
}) => {
  const [link, setLink] = useState()

  useEffect(() => {
    if (src) {
      setLink(src)
    } else if (STORYBLOK_IMAGE_URL)
      setLink(
        imagePng?.filename.replace(
          "https://a.storyblok.com/f",
          STORYBLOK_IMAGE_URL,
        ),
      )
    else setLink(imagePng?.filename)
  }, [imagePng, src])

  return (
    <If
      condition={link}
      renderIf={
        <picture className={classNamePicture}>
          <source srcSet={`${link}/m/filters:format(webp)`} type="image/webp" />
          <img
            src={link}
            className={className}
            alt={imagePng?.alt}
            loading={lazy ? "lazy" : "eager"}
            width="100%"
            height="100%"
            {...props}
          />
        </picture>
      }
    />
  )
}

export default StoryblokImage

import { useEffect, useState } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { focusOnLogo, formatters } from "@garantidos/utils"

import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import validatorsDictionary from "pages/Proposal/hooks/validators"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useSimulation = () => {
  const [simulationTypeSelected, setSimulationTypeSelected] =
    useState("asset_value")
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const { next, prev } = useStep()
  const {
    stepInfo,
    fetchProposalData,
    sendProposalData,
    proposalData,
    errorHandler,
    errorMessage,
    setErrorMessage,
    isLoading,
    cpfData
  } = useProposalContext()
  const { formatSendPayload } = usePayloadFormatters(fields)
  const { redirectToRejectionPage } = useRejectionRedirect()

  const {
    max_asset_value,
    min_asset_value,
    max_installment_value,
    min_installment_value
  } = proposalData
  const { previousStep, nextStep } = stepInfo
  const { hashedCpf } = cpfData

  const {
    simulation: { title }
  } = steps

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      const payload = formatSendPayload(formData)

      trackers.advance({
        urgency: payload.purchase_time,
        min_asset: min_asset_value,
        max_asset: max_asset_value,
        min_installment: min_installment_value,
        max_installment: max_installment_value,
        simulation_type: payload.simulation_type,
        asset_value: payload.asset_input_value,
        installment_value: payload.installment_input_value
      })

      await sendProposalData({ payload })

      next(nextStep)
    } catch (error) {
      if (error?.statusCode === 404) {
        redirectToRejectionPage(errorsEnum.SIMULATION_VALUES)
        return
      }
      if (error?.statusCode === 422) {
        errorHandler(error)
        return
      }
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()

      await fetchProposalData({ step: previousStep })

      prev(previousStep)
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handlePurchaseTimeInput = (e) => {
    trackers.purchaseTime(e?.target?.value)
  }

  const handleSimulationTypeInput = (e) => {
    trackers.simulationType(e?.target?.value)
  }

  const handleKeyUp = async (event) => {
    const { trigger } = form
    if (event.keyCode !== 13) return
    await trigger()
    const { isValid } = form
    if (isValid) return
    document.activeElement.blur()

    const elementToFocus = document.querySelector("#purchase-time-label")
    if (elementToFocus) {
      elementToFocus.focus()
    }
  }

  const addKeyUpListener = () => {
    Object.keys(fields).forEach((key) => {
      if (fields[key].type !== "currency") return
      const inputName = fields[key].name
      const input = document.querySelector(`input[name="${inputName}"]`)
      if (!input) return
      input.addEventListener("keyup", (event) => handleKeyUp(event, inputName))
    })
  }

  useEffect(() => {
    trackers.pageview(hashedCpf)
    focusOnLogo()
  }, [])

  useEffect(() => {
    if (proposalData.simulation_type !== undefined)
      setSimulationTypeSelected(proposalData.simulation_type)
  }, [proposalData])

  useEffect(() => {
    setTimeout(() => {
      addKeyUpListener()
    }, 500)
  }, [simulationTypeSelected])

  return {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    assetLimits: {
      max: formatters.toCurrency(max_asset_value ?? 100_000),
      min: formatters.toCurrency(min_asset_value ?? 1000)
    },
    installmentLimits: {
      max: formatters.toCurrency(max_installment_value ?? 10_000),
      min: formatters.toCurrency(min_installment_value ?? 100)
    },
    title,
    simulationTypeSelected,
    handlePurchaseTimeInput,
    handleSimulationTypeInput
  }
}

export default useSimulation

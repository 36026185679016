import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const STEP_CONTEXT = {
  stepId: "consorcio-e2e-asset-6",
  stepName: "asset",
  stepNumber: 6
}

const contactTypeParser = {
  PHONE: {
    gaText: "ContatoTelefone"
  },
  WHATSAPP: {
    gaText: "ContatoWhatsApp"
  }
}

const getTrackingLabel = (simulationType, fromSummary) => {
  if (fromSummary) return "GrupoSelecionadoCheio"
  if (simulationType === "asset_value") return "GruposDeConsorcioValorTotal"
  return "GruposDeConsorcioParcelas"
}

const pageview = (hashedCpf, simulationType, fromSummary) => {
  const ga = {
    label: `IT:NL:NCC:ConsorcioE2E:${getTrackingLabel(
      simulationType,
      fromSummary
    )}`,
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "6-asset",
      step_name: "asset",
      step_order: 6
    }
  }

  trackPageview({ ga, segment })
}

const moreOptions = (simulationType, fromSummary) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:${getTrackingLabel(
      simulationType,
      fromSummary
    )}:ConhecaOutrasOpcoes`,
    page: {
      nome: `IT:NL:NCC:ConsorcioE2E:${getTrackingLabel(
        simulationType,
        fromSummary
      )}`
    }
  }

  trackEvent({ ga })
}

const back = (simulationType, fromSummary) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:${getTrackingLabel(
      simulationType,
      fromSummary
    )}:SimularNovamente`,
    page: {
      nome: `IT:NL:NCC:ConsorcioE2E:${getTrackingLabel(
        simulationType,
        fromSummary
      )}`
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "asset"
    }
  }

  trackEvent({ ga, segment })
}

const advance = (simulationType, fromSummary, isMain) => {
  const optionType = isMain
    ? "ConhecerOpcaoPrincipal"
    : "ConhecerOpcaoVariaveis"

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:${getTrackingLabel(
      simulationType,
      fromSummary
    )}:${optionType}`,
    page: {
      nome: `IT:NL:NCC:ConsorcioE2E:${getTrackingLabel(
        simulationType,
        fromSummary
      )}`
    }
  }

  trackEvent({ ga })
}

const assetSelected = ({
  assetOrder,
  isMain,
  assetValue,
  installmentValue,
  months
}) => {
  const segment = {
    event: "advance step",
    data: {
      step: "asset",
      asset_chosen: isMain ? assetOrder + 1 : assetOrder + 2,
      asset_value: assetValue,
      installment_value: installmentValue,
      months
    }
  }

  trackEvent({ segment })
}

const dropFlow = (contactType, simulationType, fromSummary) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:${getTrackingLabel(simulationType, fromSummary)}:${
      contactTypeParser[contactType]?.gaText
    }`,
    page: {
      nome: `IT:NL:NCC:ConsorcioE2E:${getTrackingLabel(
        simulationType,
        fromSummary
      )}`
    }
  }

  trackEvent({ ga })
}

const submitForm = () => {}

const contactClicked = (contactType) => {
  const segment = {
    event: "asset not chosen",
    data: {
      step: "asset",
      contact_type: contactType
    }
  }

  trackEvent({ segment })
}

export {
  pageview,
  moreOptions,
  back,
  advance,
  assetSelected,
  dropFlow,
  submitForm,
  contactClicked
}

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

import { installments, personal, summary, totals } from "./data"

const SummaryInfo = ({
  totalValues,
  installmentsValues,
  summaryValues,
  email
}) => (
  <div className="summaryInfo">
    <Text as="h3" className="summaryInfo__title">
      {totals.title}
    </Text>

    {totals.labels.map((label, index) => (
      <div
        className="summaryInfo__item"
        aria-label={`${index === 1 ? "taxa de administração média" : label}
          ${totalValues[index]}`}
        role="text"
        key={label}
      >
        <Text as="p" className="summaryInfo__label" aria-hidden>
          <If
            condition={index === 3}
            renderIf={<b>{label}</b>}
            renderElse={label}
          />
        </Text>
        <Text className="summaryInfo__value" aria-hidden>
          <If
            condition={index === 3}
            renderIf={<b>{totalValues[index]}</b>}
            renderElse={totalValues[index]}
          />
        </Text>
      </div>
    ))}

    <Text as="h3" className="summaryInfo__title">
      {installments.title}
    </Text>

    {installments.labels.map((label, index) => (
      <div
        className="summaryInfo__item"
        aria-label={`${label}, ${installmentsValues[index]}`}
        role="text"
        key={label}
      >
        <Text as="p" className="summaryInfo__label" aria-hidden>
          {label}
        </Text>
        <Text className="summaryInfo__value" aria-hidden>
          {installmentsValues[index]}
        </Text>
      </div>
    ))}

    <Text as="h3" className="summaryInfo__title">
      {summary.title}
    </Text>

    {summary.labels.map((label, index) => {
      if (!summaryValues[index]) return <></>
      return (
        <div
          className="summaryInfo__item"
          aria-label={`${label}, ${summaryValues[index]}`}
          role="text"
          key={label}
        >
          <Text as="p" className="summaryInfo__label" aria-hidden>
            {label}
          </Text>
          <Text className="summaryInfo__value" aria-hidden>
            {summaryValues[index]}
          </Text>
        </div>
      )
    })}

    <Text as="h3" className="summaryInfo__title">
      {personal.title}
    </Text>
    <div
      className="summaryInfo__item"
      aria-label={`${personal.labels}, ${email}`}
      role="text"
    >
      <Text as="p" className="summaryInfo__label" aria-hidden>
        {personal.labels}
      </Text>
      <Text className="summaryInfo__value" aria-hidden>
        {email}
      </Text>
    </div>
  </div>
)

export default SummaryInfo

import { objectToList } from "../utils"

const useFieldRules = ({ field, validatorsDictionary }) => {
	const { rules, name } = field

	const mountValidators = ({ proposalData, errorFields = {} }) => {
		const validators = {
			rules: { validate: {} },
			messages: {}
		}

		const errorApi = errorFields[name]

		if (errorApi) {
			validators.rules.validate.errorApi = () => !errorApi
			validators.messages.errorApi = errorApi || ""
		}

		if (rules && Object.entries(rules).length) {
			const rulesList = objectToList(rules)
			rulesList?.forEach(({ key: rule, value: conditionValue }) => {
				validators.rules.validate[rule] = validatorsDictionary[rule].rule({
					conditionValue,
					proposalData
				})
				validators.messages[rule] = validatorsDictionary[rule].message({
					conditionValue,
					rules,
					proposalData
				})
			})
		}

		return validators
	}

	return { mountValidators }
}

export default useFieldRules

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:ConfirmarContratacao",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "11-Summary",
      step_name: "summary",
      step_order: "11"
    }
  }

  trackPageview({ ga, segment })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ConfirmarContratacao:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ConfirmarContratacao"
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "summary"
    }
  }

  trackEvent({ ga, segment })
}

const advance = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ConfirmarContratacao:ConfirmarContratacao",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ConfirmarContratacao"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "summary"
    }
  }

  trackEvent({ ga, segment })
}

const submitForm = () => {}

const hiringCompleted = (proposalData) => {}

export { pageview, back, advance, submitForm, hiringCompleted }

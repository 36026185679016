import { Button, Carousel, CarouselItem, Text } from "@mobi/ds"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import Img from "components/img"

import "./style.scss"

import { offerUnavailableMarketplaceCards } from "../../data"
import { marketplaceTracking } from "../../trackings"

const OfferUnavailableMarketplace = () => {
  const isMobile = useMediaQuery("mobile")
  const isTablet = useMediaQuery("tablet")

  const hasCarouselArrows = isMobile || isTablet
  const hasCarouselBullets = isMobile || isTablet
  const getCarouselGap = () => {
    if (isMobile) return 10

    if (isTablet) return 20

    return 40
  }
  const getCarouselPerView = () => {
    if (isMobile) return 1

    if (isTablet) return 2

    return 3
  }
  const getCarouselFocusAt = () => {
    if (isMobile) return "center"

    return 0
  }

  return (
    <div className="offerUnavailable__marketplace">
      <div data-aos="fade-left" data-aos-duration="500" data-aos-offset="-1000">
        <Text as="h2" className="offerUnavailable__marketplaceTitle">
          selecionados pra você
        </Text>
        <Text as="p" className="offerUnavailable__marketplaceSubtitle">
          achamos que pode te interessar
        </Text>
      </div>

      <div
        className="offerUnavailable__marketplaceCarouselWrapper"
        data-aos="fade-right"
        data-aos-duration="500"
        data-aos-offset="-1000"
      >
        <Carousel
          bullets={hasCarouselBullets}
          arrows={hasCarouselArrows}
          variant="slider"
          options={{
            gap: getCarouselGap(),
            keyboard: false,
            perView: getCarouselPerView(),
            peek: 0,
            focusAt: getCarouselFocusAt()
          }}
          indentifier="offer-unavailable-marketplace-carousel"
          className="outlined-carousel"
        >
          {offerUnavailableMarketplaceCards.map(
            ({
              productName,
              image,
              imageAlt,
              title,
              text,
              ctaUrl,
              ctaLabel
            }) => (
              <CarouselItem key={productName}>
                <div className="offerUnavailable__marketplaceCard">
                  <Img file={image} alt={imageAlt} aria-hidden />
                  <div className="offerUnavailable__marketplaceCardContent">
                    <Text
                      as="h2"
                      className="offerUnavailable__marketplaceCardTitle"
                    >
                      {title}
                    </Text>

                    <Text
                      as="p"
                      className="offerUnavailable__marketplaceCardText"
                    >
                      {text}
                    </Text>

                    <div className="offerUnavailable__marketplaceCardDivider" />

                    <Button
                      className="offerUnavailable__marketplaceCardButton"
                      tagName={"a"}
                      href={ctaUrl}
                      target="_blank"
                      rel="noreferrer"
                      role="button"
                      variant="secondary"
                      onClick={() => marketplaceTracking(productName)}
                    >
                      {ctaLabel}
                    </Button>
                  </div>
                </div>
              </CarouselItem>
            )
          )}
        </Carousel>
      </div>
    </div>
  )
}

export default OfferUnavailableMarketplace

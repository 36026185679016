const blok = {
  title: "",
  steps: [
    {
      title: "escolha do consórcio",
      text: "Você fez a simulação e escolheu o consórcio ideal para o seu momento",
      status: "done"
    },
    {
      title: "assinatura do contrato",
      text: "Em alguns minutos, você vai receber o contrato por e-mail. Assine digitalmente para confirmar e receber o primeiro boleto",
      status: "ongoing"
    },
    {
      title: "pagamento do boleto",
      text: "Pague em até 3 dias o boleto da primeira parcela que você receberá por e-mail para não perder a reserva da sua cota, e seu consórcio já vai estar valendo! Lembre-se: o boleto só é válido por três dias a partir da data de hoje",
      status: "open"
    },
    {
      title: "kit de boas vindas",
      text: "Quando seu consórcio for ativado, você vai receber por e-mail o kit de boas vindas e o acesso ao Portal do Consorciado.\n\nNo kit, você encontra tudo o que precisa saber sobre seu consórcio, e no Portal consegue acompanhar os passos da sua conquista",
      status: "open"
    }
  ]
}

export { blok }

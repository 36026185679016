import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:SucessoContratacao",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "12-finished hiring",
      step_name: "finished hiring",
      step_order: "12"
    }
  }

  trackPageview({ ga, segment })
}

const marketplaceTracking = (product) => {
  const marketplaceParser = {
    CartaoCreditoClick: {
      gaText: "PecaJaOSeu",
      segment: {
        product: "CartaoClick",
        product_order: "01",
        step_name: "with credit"
      }
    },
    AberturaDeContas: {
      segment: {
        product: "CartaoIti",
        product_order: "02",
        step_name: "with credit"
      }
    },
    TagItau: {
      gaText: "QueroUmaTag",
      segment: {
        product: "TagItau",
        product_order: "03",
        step_name: "with credit"
      }
    }
  }

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:SucessoContratacao:${marketplaceParser[product]?.gaText}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:SucessoContratacao"
    }
  }

  const segment = {
    event: "marketplace impression",
    data: {
      ...marketplaceParser[product]?.segment
    }
  }

  trackEvent({ ga, segment })
}

export { pageview, marketplaceTracking }

import { useEffect } from "react"
import { FocusOn } from "react-focus-on"

import { Button, Text } from "@mobi/ds"
// Utils
import isClient from "@mobi/utils/browser/isClient"

// Components
import Icon from "../Icon"
// Styles
import "./index.scss"
// Hooks
import useToast from "./useToast"
import classNames from "classnames"

const Toast = () => {
  const { isOpen, onClose, error } = useToast()

  useEffect(() => {
    if (isClient() && isOpen) {
      const btnClose = document.querySelector(".notification-toast__button")
      btnClose && btnClose.focus()
    }
  }, [isOpen])

  if (!isOpen) return null
  return (
    <FocusOn enabled={isOpen}>
      <div
        className={classNames("notification-toast", {
          "notification-toast--opened": isOpen,
        })}
        role="alertdialog"
        aria-modal="true"
      >
        <div id="toast-container" className="notification-toast__container">
          <button
            className="notification-toast__button"
            onClick={() => onClose()}
            aria-label="Fechar modal"
          >
            <Icon className="notification-toast__iclose" id="icon-close" />
          </button>
          <div className="notification-toast__content">
            <Icon className="notification-toast__warning" id="icon-warning" />
            <Text as="h2" size="xxl" weight={800}>
              {error.title || "desculpe, algo deu errado"}
            </Text>
            <Text as="p" size="md" className="message">
              {error.message || "Mas não se preocupe, volte e tente novamente."}
            </Text>
          </div>
          <Button fluid onClick={() => onClose()}>
            voltar
          </Button>
        </div>
      </div>
    </FocusOn>
  )
}

export default Toast

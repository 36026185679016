import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import StoryblokImage from "../Storyblok/StoryblokImage"
import Spinner from "./spinner.svg"

import useLoadingPage from "./hooks"

import "./style.scss"

const LoadingPage = ({
  info = "",
  texts = [],
  dialog = false,
  headerHeight = "72px",
  loadingDone = false,
  onLoadingDone = () => {},
  pageviewTracking = () => {},
  imageChild,
  image = {
    filename:
      "https://a.storyblok.com/f/159381/540x359/8d875bbf41/ilustra-casa-moto-carro.png",
    alt: "ilustração de uma casa com um carro e uma moto parados em frente, há uma pessoa em cima da moto",
  },
}) => {
  const { primaryText, secondaryText, loadingText } = useLoadingPage({
    info,
    texts,
    dialog,
    pageviewTracking,
    loadingDone,
    onLoadingDone,
  })
  const isMobile = useMediaQuery("mobile")

  return (
    <div
      className="loadingPage"
      id="loadingPage"
      style={{ height: `calc(100vh - ${headerHeight})` }}
    >
      <div className="loadingPage__mainContainer">
        <If
          condition={!isMobile}
          renderIf={
            <div className="loadingPage__imageContainer">
              <If
                condition={!!imageChild}
                renderIf={
                  <div className="loadingPage__image" aria-hidden>
                    {imageChild}
                  </div>
                }
                renderElse={
                  <StoryblokImage
                    imagePng={image}
                    className="loadingPage__image"
                    width="370px"
                    height="244px"
                    aria-hidden
                  />
                }
              />
            </div>
          }
        />
        <div className="loadingPage__infoContainer">
          <Text
            as="h2"
            role="text"
            aria-hidden
            className={`loadingPage__secondaryText loadingPage__text--show ${
              secondaryText?.positionClass || ""
            }`}
          >
            {secondaryText?.text || ""}
          </Text>

          <Text
            as="h1"
            role="log"
            className={`loadingPage__primaryText loadingPage__text--show ${
              primaryText?.positionClass || ""
            }`}
            aria-live="assertive"
          >
            {primaryText?.text || ""}
          </Text>

          <div className="loadingPage__loaderContainer">
            <span
              aria-live="assertive"
              role="alert"
              id="loadingPageStateAnnouncer"
            >
              <Text as="p" className="loadingPage__sr-only">
                {loadingText}
              </Text>
            </span>

            <Spinner className="loadingPage__spinner" aria-hidden />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage

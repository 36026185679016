import { Helmet } from "react-helmet"

import { Button, Text } from "@mobi/ds"

import Img from "components/img"
import CardIcon from "icons/card.svg"
import InvestmentMarket from "icons/investment-market.svg"
import ShoppingBagIcon from "icons/shopping-bag.svg"
import UserExecutive from "icons/user-executive.svg"

import StepsHeader from "../../components/StepsHeader"
import OfferUnavailableMarketplace from "./components/Marketplace"

import useOfferUnavailable from "./hooks"

import "./style.scss"

import { openAccountTracking } from "./trackings"

const OfferUnavailable = () => {
  const { pageTitle, openAccountUrl } = useOfferUnavailable()

  return (
    <>
      <StepsHeader />

      <div className="offerUnavailable">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className="offerUnavailable__openAccount">
          <div
            className="offerUnavailable__heading"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-offset="-1000"
          >
            <Text as="h1" className="offerUnavailable__title">
              infelizmente, ainda não temos uma oferta disponível para você
              nesse canal
            </Text>
            <Text as="p" className="offerUnavailable__subtitle">
              com a conta Itaú, você tem mais chances de contratar o seu
              Consórcio
            </Text>
          </div>
          <div
            className="offerUnavailable__card"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-offset="-1000"
          >
            <div className="offerUnavailable__openAccountImgWrapper">
              <Img
                file="imagem-card-abrir-conta"
                className="offerUnavailable__openAccountImg"
                alt="Imagem de um cartão Itaú Click e ao fundo um smartphone com a logo do Itaú na tela"
                aria-hidden
                objectFit="contain"
              />
            </div>

            <div className="offerUnavailable__openAccountContent">
              <Text as="h2" className="offerUnavailable__openAccountTitle">
                Conta Itaú + Cartão Click
              </Text>

              <ul className="offerUnavailable__openAccountList">
                <li>
                  <UserExecutive aria-hidden />
                  <Text as="p">mensalidade grátis</Text>
                </li>
                <li>
                  <CardIcon aria-hidden />
                  <Text as="p">cartão de crédito Itaú Click sem anuidade</Text>
                </li>
                <li>
                  <InvestmentMarket aria-hidden />
                  <Text as="p">
                    aumente suas chances de contratar o seu consórcio
                  </Text>
                </li>
                <li>
                  <ShoppingBagIcon aria-hidden />
                  <Text as="p">canais de atendimento 24h</Text>
                </li>
              </ul>

              <Button
                className="offerUnavailable__openAccountButton"
                tagName={"a"}
                href={openAccountUrl}
                rel="noreferrer"
                role="button"
                target="_blank"
                onClick={openAccountTracking}
              >
                abra sua conta
              </Button>
            </div>
          </div>
        </div>

        <div className="offerUnavailable__marketplaceWrapper">
          <OfferUnavailableMarketplace />
        </div>
      </div>
    </>
  )
}

export default OfferUnavailable

import AriaLabel from "@mobi/components/AriaLabel"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

const CardInfo = ({ Icon, value }) => (
  <div className="cardInfo">
    <div className="cardInfo__container">
      <div className="cardInfo__textContainer">
        <Icon aria-hidden="true" className="cardInfo__icon" />
        <Text as="p" className="cardInfo__bold">
          o consórcio só estará ativo depois do pagamento da 1ª parcela
        </Text>
      </div>
      <div className="cardInfo__statusContainer">
        <AriaLabel
          ariaText={`Você receberá um boleto de ${value} que deverá ser pago em até 3 dias úteis para que seu consórcio seja ativado`}
        >
          <Text as="p" className="cardInfo__description" aria-hidden>
            Você receberá um boleto de <b>{value}</b> que deverá ser pago em até
            3 dias úteis para que seu consórcio seja ativado
          </Text>
        </AriaLabel>
      </div>
    </div>
  </div>
)

export default CardInfo

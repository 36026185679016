import { useEffect, useRef, useState } from "react"

const useIcon = ({ icon, type }) => {
	const ImportedIconRef = useRef()
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		import(`@mobi/ds/static/icons/${type}/${icon}.svg`)
			.then((res) => {
				ImportedIconRef.current = res.default
				setIsLoaded(true)
			})
			.catch((err) => console.log(err))
	}, [])

	return {
		Icon: isLoaded ? ImportedIconRef.current : ""
	}
}

export default useIcon

import YouTubeVideo from "@garantidos/components/YouTubeVideo"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import useFaqSingle from "./hooks"

const ArrowIcon = require("./icons/up-arrow-orange.svg")

const FaqSingle = ({
  title,
  answerItem = [],
  answerVideoItem = false,
  index: propIndex,
  onClick,
  open = null,
  ...props
}) => {
  const { isOpen, itemId, panelId, index, handleClick } = useFaqSingle({
    open,
    propIndex,
    onClick,
  })

  return (
    <div
      data-testid={`faq-single-${index}`}
      className={`faq-structured ${isOpen ? "faq-structured--open" : ""}`}
      {...props}
      itemscope=""
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <button
        id={itemId}
        aria-controls={panelId}
        aria-expanded={!!isOpen}
        className="faq-structured__topic"
        onClick={() => handleClick(index)}
        data-testid={`faq-structured__topic-${index}`}
        type="button"
      >
        <div className="faq-structured__topic__text-container">
          <Text
            as="p"
            className="faq-structured__topic__text-title"
            itemprop="name"
          >
            {title}
          </Text>
        </div>
        <div className="faq-structured__topic__icon-container">
          <ArrowIcon aria-hidden="true" className="faq-structured__arrow" />
        </div>
      </button>
      <div
        id={panelId}
        aria-labelledby={itemId}
        aria-hidden={!isOpen}
        className="faq-structured__content"
        itemscope=""
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <If
          condition={answerItem.length === 1}
          renderIf={
            <Text
              key={answerItem._uid}
              as="p"
              size="md"
              weight="400"
              className="faq-structured__text"
              itemprop="text"
            >
              {answerItem[0]?.text}
            </Text>
          }
          renderElse={
            <Text
              key={answerItem._uid}
              as="p"
              size="md"
              weight="400"
              className="faq-structured__text"
              itemprop="text"
            >
              {answerItem.map((item) => {
                return (
                  <>
                    {item.text}
                    <br />
                  </>
                )
              })}
            </Text>
          }
        />
        <If
          condition={answerVideoItem.link}
          renderIf={
            <div
              className="faq-structured__videoContainer"
              data-testid={`faq-structured__videoContainer-${index}`}
            >
              <YouTubeVideo
                videoLink={answerVideoItem.link}
                videoTitle={"Vídeo Perguntas Frequentes CGI"}
              />
              <div className="faq-structured__videoText">
                {answerVideoItem?.text}
              </div>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default FaqSingle

import { objectToList } from "@garantidos/utils"

import Address from "./Address/index"
import AlternativeFlow from "./AlternativeFlow"
import Asset from "./Asset/index"
import Category from "./Category/index"
import ComplementaryLead from "./ComplementaryLead"
import Finished from "./Finished"
import FinishedHiring from "./FinishedHiring"
import Lead from "./Lead"
import OfferUnavailable from "./OfferUnavailable"
import RedirectApp from "./RedirectApp"
import Rejection from "./Rejection"
import Simulation from "./Simulation"
import SimulationAsset from "./SimulationAsset"
import SimulationSummary from "./SimulationSummary"
import Sms from "./Sms"
import Softlead from "./Softlead"
import Summary from "./Summary"
import TwoWay from "./TwoWay"

const steps = {
  softlead: {
    name: "softlead",
    component: Softlead,
    title: "Vamos nos conhecer (passo 1 de 3) | Consórcio | Itaú",
    stepNumber: 1
  },

  redirectApp: {
    name: "redirect-app",
    component: RedirectApp,
    title: "Continuar pelo aplicativo | Consórcio | Itaú"
  },

  sms: {
    name: "sms-confirmation",
    component: Sms,
    title: "Vamos nos conhecer (passo 2 de 3) | Consórcio | Itaú",
    stepNumber: 2
  },

  twoWay: {
    name: "two-way",
    component: TwoWay,
    title: "Vamos nos conhecer (passo 3 de 3) | Consórcio | Itaú",
    stepNumber: 3
  },

  category: {
    name: "category",
    component: Category,
    title: "Vamos simular seu consórcio (passo 1 de 3) | Consórcio | Itaú",
    stepNumber: 4
  },

  simulation: {
    name: "simulation",
    component: Simulation,
    title: "Vamos simular seu consórcio (passo 2 de 3) | Consórcio | Itaú",
    stepNumber: 5
  },

  asset: {
    name: "asset",
    component: Asset,
    title: "Vamos simular seu consórcio (passo 3 de 3) | Consórcio | Itaú",
    stepNumber: 6
  },

  simulationSummary: {
    name: "summary-simulation",
    component: SimulationSummary,
    title: "Resumo da carta | Consórcio | Itaú",
    stepNumber: 7
  },

  lead: {
    name: "proposal-lead",
    component: Lead,
    title: "Conte mais sobre você (passo 1 de 3) | Consórcio | Itaú",
    stepNumber: 8
  },

  complementaryLead: {
    name: "lead-complementary",
    component: ComplementaryLead,
    title: "Conte mais sobre você (passo 2 de 3) | Consórcio | Itaú",
    stepNumber: 9
  },

  address: {
    name: "lead-address",
    component: Address,
    title: "Conte mais sobre você (passo 3 de 3)  | Consórcio | Itaú",
    stepNumber: 10
  },

  summary: {
    name: "summary",
    component: Summary,
    title: "Resumo do consórcio | Consórcio | Itaú",
    stepNumber: 11
  },

  finished: {
    name: "finished",
    component: Finished,
    title: "Informações enviadas | Consórcio | Itaú"
  },

  finishedHiring: {
    name: "finished-hiring",
    component: FinishedHiring,
    title: "Contratação concluída | Consórcio | Itaú"
  },

  rejection: {
    name: "rejection",
    component: Rejection,
    title: "Erro | Consórcio | Itaú"
  },

  offerUnavailable: {
    name: "offer-unavailable",
    component: OfferUnavailable,
    title: "Oferta indisponível nesse canal | Consórcio | Itaú"
  },

  alternativeFlow: {
    name: "alternativeFlow",
    component: AlternativeFlow,
    title: "Formulário de contato | Consórcio | Itaú"
  }
}

const apiSteps = Object.fromEntries(
  objectToList(steps).map((step) => [
    step.value.name,
    { name: step.key, component: step.value.component }
  ])
)

export { apiSteps, steps }

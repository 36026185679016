import { trackEvent } from "@garantidos/utils/tracking"

const likertInteraction = (eventLabel) => {
  const ga = {
    category: "Likert",
    label: `BTN:ConsorcioE2E:SucessoContratacao:${eventLabel}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:SucessoContratacao"
    }
  }

  trackEvent({ ga })
}

export { likertInteraction }

import { useEffect } from "react"

import { focusOnLogo, scrollToTop } from "@garantidos/utils"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useTwoWay = () => {
  const {
    stepInfo,
    errorMessage,
    setErrorMessage,
    fetchProposalData,
    sendProposalData,
    errorHandler,
    isLoading,
    cpfData
  } = useProposalContext()

  const isMobile = useMediaQuery("mobile")

  const { hashedCpf } = cpfData

  const {
    twoWay: { title }
  } = steps

  const { redirectToRejectionPage } = useRejectionRedirect()

  const { nextStep } = stepInfo

  const { next, prev } = useStep()

  const onSubmitCallback = async () => {
    if (isLoading) return
    try {
      trackers.advance()

      await sendProposalData({ payload: {} })

      next(nextStep)
    } catch (error) {
      if (error?.statusCode === 422) {
        errorHandler(error)
        return
      }
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    trackers.back()
    try {
      await fetchProposalData({ step: "softlead" })
      prev("softlead")
    } catch (error) {
      redirectToRejectionPage(errorsEnum.ERROR_INSTABILITY)
    }
  }

  useEffect(() => {
    focusOnLogo()
    scrollToTop()
    trackers.pageview(hashedCpf)
  }, [])

  return {
    onSubmitCallback,
    handlePrevStep,
    errorMessage,
    setErrorMessage,
    isMobile,
    title
  }
}

export default useTwoWay

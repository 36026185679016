import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import "./style.scss"

import { cards } from "./data"

const FinishedTips = () => (
  <div className="finishedTips">
    <div
      className="finishedTips__container"
      data-aos="fade"
      data-aos-delay="100"
      data-aos-duration="500"
    >
      <Text as="h2" className="finishedTips__title">
        o que fazer para aumentar minha chance de aprovação?
      </Text>

      <div className="finishedTips__cards">
        {cards?.map((card) => (
          <div className="finishedTips__card" key={card.title}>
            <Img
              file={card.image.filename}
              alt={card.image.alt}
              className="finishedTips__cardImage"
              aria-hidden
              lazy
            />
            <Text as="h3" className="finishedTips__cardTitle">
              {card.title}
            </Text>
            <Text as="p" className="finishedTips__cardDescription">
              {card.description}
            </Text>
          </div>
        ))}
      </div>
      <Text as="h2" className="finishedTips__title">
        entenda como funciona a análise para conquistar a cota de consórcio
      </Text>
      <Text as="p" className="finishedTips__description">
        Avaliamos seus dados com informações disponíveis em cadastros públicos,
        orgãos de proteção ao crédito, Cadastro Positivo, e também seus
        pagamentos em dia e renda.
      </Text>
      <Text as="h2" className="finishedTips__title">
        porque pedimos o seu número de celular
      </Text>
      <Text as="p" className="finishedTips__description">
        Verificaremos no futuro a disponibilidade de adquirir uma cota de
        consórcio e podemos entrar em contato para acelerar a realização do seu
        sonho.
      </Text>
    </div>
  </div>
)

export default FinishedTips

import { trackEvent } from "@garantidos/utils/tracking"

const useWhatsappShare = () => {
  const trackingObject = {
    ga: {
      action: "Simulacao",
      label: "BTN:Consorcio:Sucesso:CompartilharWhatsApp",
      page: {
        nome: "IT:NL:NCC:Consorcio:Sucesso"
      }
    }
  }
  const handleButtonClick = () => {
    trackEvent(trackingObject)
  }

  return {
    handleButtonClick
  }
}

export default useWhatsappShare

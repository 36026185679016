import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:Endereco",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "10-lead-address",
      step_name: "lead-address",
      step_order: "10"
    }
  }

  trackPageview({ ga, segment })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:Endereco:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:Endereco"
    }
  }

  const segment = {
    event: "return step",
    data: {
      step: "lead-address"
    }
  }

  trackEvent({ ga, segment })
}

const advance = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:Endereco:EnviarDados",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:Endereco"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "lead-address"
    }
  }

  trackEvent({ ga, segment })
}

const cep = () => {}

export { pageview, back, advance, cep }

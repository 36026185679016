import { useEffect } from "react"

import { focusOnLogo } from "@garantidos/utils"

import { steps } from "pages/Proposal/steps"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useFinished = () => {
  const { proposalData, cpfData } = useProposalContext()
  const { has_credit, has_asset, fromRejection = false } = proposalData
  const { hashedCpf } = cpfData

  const {
    finished: { title }
  } = steps

  useEffect(() => {
    trackers.pageview(hashedCpf, has_credit, fromRejection)
    focusOnLogo()
  }, [])

  const marketplaceCallback = (product) => {
    trackers.marketplaceTracking(product, has_credit, fromRejection)
  }

  return {
    marketplaceCallback,
    title,
    has_credit,
    has_asset
  }
}

export default useFinished

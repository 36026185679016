import Text from "@mobi/ds/components/Text"

import "./style.scss"

const CustomRadioOption = ({
	image,
	imageAlt,
	Icon,
	label,
	description = ""
}) => {
	return (
		<div className="fieldCustomRadio__labelContainer" role="radio" aria-hidden>
			{image && (
				<img
					src={image}
					alt={imageAlt}
					className="fieldCustomRadio__image"
					aria-hidden
				/>
			)}
			{Icon && (
				<div className="fieldCustomRadio__iconContainer">
					<Icon aria-hidden className="fieldCustomRadio__icon" />
				</div>
			)}
			<div
				className="fieldCustomRadio__textContainer"
				role="text"
				aria-label={`${label} ${description}`}
			>
				<Text as="p" className="fieldCustomRadio__label" aria-hidden>
					{label}
				</Text>
				{!!description && (
					<Text as="p" className="fieldCustomRadio__description" aria-hidden>
						{description}
					</Text>
				)}
			</div>
		</div>
	)
}

export default CustomRadioOption

import { Field, Form, HousingAlert } from "@garantidos/components"

import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import useEmailEdit from "./hooks"

import "./style.scss"

const EmailEdit = ({
  setSuccessMessage,
  toggleModal,
  newEmail,
  setNewEmail
}) => {
  const { fields, form, onSubmitCallback, errorMessage, setErrorMessage } =
    useEmailEdit({
      setSuccessMessage,
      toggleModal,
      newEmail,
      setNewEmail
    })

  const { isValid } = form
  const { email } = fields
  return (
    <div className="emailEdit">
      <HousingAlert
        type="error"
        message={errorMessage}
        setMessage={setErrorMessage}
      />

      <Text as="h2" className="emailEdit__title">
        alterar e-mail
      </Text>

      <Form onSubmit={onSubmitCallback} form={form} className="emailEdit__form">
        <Field field={email} form={form} data-testid={email.name} />
        <Button
          fluid
          type="submit"
          disabled={!isValid}
          className="emailEdit__button"
        >
          confirmar alteração
        </Button>
      </Form>
    </div>
  )
}

export default EmailEdit

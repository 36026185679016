import { useStep } from "@mobi/libraries/step"

const useStepTransition = (setIsStepVisible) => {
  const { next, prev } = useStep()

  const stepNavigator = (direction, step) => {
    setIsStepVisible(false)
    setTimeout(() => {
      direction === "next" ? next(step) : prev(step)
      setIsStepVisible(true)
    }, 400)
  }

  return {
    stepNavigator,
  }
}

export default useStepTransition

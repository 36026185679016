import { Helmet } from "react-helmet"

import { HousingAlert, LoadingPage, Sender } from "@garantidos/components"
import { useDataLayer } from "@garantidos/hooks"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import Splitted from "../../components/Splitted"

import useCategory from "./hooks"

import "./style.scss"

const Category = () => {
  const {
    handlePrevStep,
    handleNextStep,
    errorMessage,
    setErrorMessage,
    title,
    loading,
    loadingDone,
    handleLoadingDone,
    loadingData,
    categoryOptions,
    loaderPageview,
    stepperInfo
  } = useCategory()

  const { info, texts, image } = loadingData

  const categoryDataLayer = useDataLayer({
    key: "category"
  })

  const { index, max } = stepperInfo

  return (
    <div className="category">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <If
        condition={loading}
        renderIf={
          <LoadingPage
            info={info}
            texts={texts}
            dialog
            pageviewTracking={loaderPageview}
            loadingDone={loadingDone}
            onLoadingDone={handleLoadingDone}
            imageChild={<Img file={image.filename} alt={image.alt} />}
          />
        }
        renderElse={
          <Splitted
            contentType="simulation"
            data-aos="fade"
            data-aos-duration="150"
            data-aos-offset="-1000"
          >
            <div
              className="category__formWrapper"
              data-aos="fade-left"
              data-aos-duration="500"
              data-aos-offset="-1000"
            >
              <Stepper
                title="vamos simular seu consórcio"
                index={index}
                max={max}
              />
              <HousingAlert
                message={errorMessage}
                setMessage={setErrorMessage}
                focusOnText
                type="error"
              />
              <Text as="h1" className="category__title" id="category-label">
                escolha sua próxima conquista, sem juros e sem entrada
              </Text>
              <div className="category__options">
                {categoryOptions.map(
                  (
                    {
                      id,
                      value,
                      label,
                      title: titleOption,
                      description,
                      image,
                      tracking
                    },
                    index
                  ) => (
                    <Button
                      key={index}
                      id={id}
                      fluid
                      aria-label={label}
                      data-testid="submit"
                      className="category__option"
                      type="submit"
                      value="simule agora"
                      variant="action"
                      onClick={() => {
                        handleNextStep(value, tracking)
                        categoryDataLayer.setDataLayerValue(id)
                      }}
                    >
                      <span className="category__optionImageContainer">
                        <Img
                          aria-hidden
                          file={image.filename}
                          alt={image.alt}
                          className="category__optionImage"
                          lazy={false}
                          fetchpriority="high"
                        />
                      </span>
                      <span aria-hidden className="category__optionContent">
                        <strong>{titleOption}</strong>
                        {description}
                      </span>
                    </Button>
                  )
                )}
              </div>

              <div id="senderContainer">
                <Sender
                  showNext={false}
                  onPrev={handlePrevStep}
                  nextLabel="avançar"
                  data_testid="submit"
                />
              </div>
            </div>
          </Splitted>
        }
      />
    </div>
  )
}

export default Category

import { Icon } from "@garantidos/components"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import useAssetCard from "./hooks"

import "./style.scss"

import { assetsCard } from "./data"

const AssetCard = ({
  assetCode,
  assetValue,
  installmentValue,
  percentageTotalFee,
  remainingMonths,
  category = "VEICULOS LEVES",
  isHighlight = false,
  simulationType = "asset_value",
  assetOrder,
  handleAssetSelect
}) => {
  const { infoCard, buttonVariant } = useAssetCard({
    isHighlight,
    assetValue,
    installmentValue,
    percentageTotalFee,
    remainingMonths
  })

  return (
    <div className={`assetCard ${isHighlight && "assetCard--highlight"}`}>
      <div className="assetCard__header">
        <If
          condition={isHighlight}
          renderIf={
            <div className="assetCard__tag">
              <Text as="p" className="assetCard__tagText">
                feito com você
              </Text>
            </div>
          }
        />
      </div>
      <If
        condition={isHighlight}
        renderIf={
          <span className="assetCard__imageContainer">
            <Img
              aria-hidden
              file={assetsCard[category].image.filename}
              alt={assetsCard[category].image.alt}
              className="assetCard__image"
              lazy={false}
              fetchpriority="high"
            />
          </span>
        }
        renderElse={
          <Icon icon={assetsCard[category].icon} className="assetCard__icon" />
        }
      />
      <div
        aria-label={`${infoCard[simulationType].mainLabel} ${infoCard[simulationType].mainValue}`}
        role="text"
      >
        <Text as="p" aria-hidden className="assetCard__mainLabel">
          {infoCard[simulationType].mainLabel}
        </Text>
        <Text as="p" aria-hidden className="assetCard__mainValue">
          {infoCard[simulationType].mainValue}
        </Text>
      </div>
      <hr aria-hidden />
      <div
        aria-label={`${infoCard[simulationType].subTextLabel} ${infoCard[simulationType].labelValue}`}
        role="text"
      >
        <Text as="p" className="assetCard__subText" aria-hidden>
          {infoCard[simulationType].subTextLabel}
          <b>{infoCard[simulationType].subTextValue}</b>
        </Text>
      </div>
      <div
        aria-label={`${infoCard[simulationType].labelTax} de ${infoCard[simulationType].taxValue}`}
        role="text"
      >
        <Text as="p" className="assetCard__subText" aria-hidden>
          {infoCard[simulationType].taxLabel}
          <b>{infoCard[simulationType].taxValue}</b>
        </Text>
      </div>
      <Button
        {...buttonVariant}
        className="assetCard__button"
        fluid
        onClick={() => handleAssetSelect(assetCode, assetOrder, isHighlight)}
        aria-label={`conhecer opção ${
          isHighlight ? assetOrder + 1 : assetOrder + 2
        }`}
        data-testid={`asset${assetOrder}`}
      >
        conhecer opção
      </Button>
    </div>
  )
}

export default AssetCard

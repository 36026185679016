import { StepHandler } from "@garantidos/components"

import CrossModal from "components/CrossModal"

import AppLayout from "layout/AppLayout"

import useProposal from "./hooks"

import {
  ProposalContext,
  useProposalDataProvider
} from "../../contexts/proposal"

// Para usar dados mockados
// import mockData from "./mocks"
import { steps } from "./steps"

const Proposal = ({ pageContext }) => {
  const { initialStepData, stepChangeCallback } = useProposal({
    pageContext
  })
  const ProposalDataProvider = useProposalDataProvider()

  return (
    <AppLayout title="Oferta | Consórcio">
      <ProposalDataProvider initialStepData={initialStepData}>
        <StepHandler
          context={ProposalContext}
          steps={steps}
          cpfPayloadKey="document_number"
          retrieveProposalRoute="retrieve-lead"
          fallbackStep="softlead"
          onChangeCallback={stepChangeCallback}
        />
        <CrossModal />
      </ProposalDataProvider>
    </AppLayout>
  )
}

export default Proposal

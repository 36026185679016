import If from "@mobi/components/If"

import useIcon from "./hooks"

const Icon = ({ icon, type = "outlined", ...props }) => {
	const { Icon } = useIcon({ icon, type })

	return <If condition={Icon} renderIf={<Icon aria-hidden {...props} />} />
}

export default Icon

import { useState } from "react"

import isClient from "@mobi/utils/browser/isClient"

const useDataLayer = ({ key }) => {
	const [value, setValue] = useState(() => {
		if (!isClient) return

		const dataLayer = window.dataLayer

		try {
			const dataLayerObject = dataLayer.find((obj) => obj.hasOwnProperty(key))
			console.log("dataLayerObject useDataLayer", dataLayerObject)
			return dataLayerObject ? dataLayerObject[key] : ""
		} catch (error) {
			console.log(error)
		}
	})

	const setDataLayerValue = async (valueToStore) => {
		try {
			setValue(valueToStore)
			if (isClient) {
				const dataLayer = window.dataLayer

				const dataLayerObject = dataLayer.find((obj) => obj.hasOwnProperty(key))

				if (dataLayerObject) {
					dataLayerObject[key] = valueToStore
				} else {
					dataLayer.push({ category: valueToStore })
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	const clearDataLayerItem = async () => {
		try {
			if (isClient) {
				const dataLayer = window.dataLayer
				dataLayer.filter((obj) => !obj.hasOwnProperty(key))
			}
		} catch (error) {
			console.error(error)
		}
	}

	return { clearDataLayerItem, value, setDataLayerValue }
}

export default useDataLayer

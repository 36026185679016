import { HousingAlert } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import "./style.scss"

import { headerCardText, headerTexts } from "./data"

const TwoWayHeader = ({
  errorMessage,
  setErrorMessage,
  showOnModal = false
}) => (
  <div
    className="twoWayInfoHeader"
    data-aos="fade-left"
    data-aos-duration="500"
    data-aos-offset="-1000"
  >
    <div className="twoWayInfoHeader__content">
      <div
        className={`twoWayInfoHeader__container${
          showOnModal ? "--marginAuto" : ""
        }`}
      >
        <If
          condition={!showOnModal}
          renderIf={
            <HousingAlert
              message={errorMessage}
              setMessage={setErrorMessage}
              focusOnText
              type="error"
            />
          }
        />
        <Text
          as={showOnModal ? "h2" : "h1"}
          size="lg"
          className="twoWayInfoHeader__title"
        >
          como funciona o consórcio?
        </Text>
        {headerTexts.map((text) => (
          <Text as="p" className="twoWayInfoHeader__text" key={text}>
            {text}
          </Text>
        ))}
        <div className="twoWayInfoHeader__card">
          <Img
            file="two-way-card"
            alt="Ilustração de um homem tomando café"
            className="twoWayInfoHeader__cardImage"
            width="94.47px"
            height="86px"
            aria-hidden
          />
          <Text as="p" className="twoWayInfoHeader__cardText">
            {headerCardText}
          </Text>
        </div>
      </div>
    </div>
  </div>
)

export default TwoWayHeader

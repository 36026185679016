export const fields = {
  simulationType: {
    type: "radio",
    name: "simulation_type",
    options: [
      {
        id: "simulation_type_asset",
        value: "asset_value",
        label: "pelo valor total",
        checked: true
      },
      {
        id: "simulation_type_installment",
        value: "installment_value",
        label: "pelo valor da parcela"
      }
    ]
  },
  assetInputValue: {
    inputMode: "decimal",
    type: "currency",
    name: "asset_input_value",
    label: "qual é o valor do bem que você deseja?",
    rules: {
      minInputValue: "min_asset_value", // Essa condition é a proprieadade que será lida do proposalData
      maxInputValue: "max_asset_value" // Essa condition é a proprieadade que será lida do proposalData
    }
  },
  installmentInputValue: {
    inputMode: "decimal",
    type: "currency",
    name: "installment_input_value",
    label: "quanto espera pagar por mês?",
    rules: {
      minInputValue: "min_installment_value", // Essa condition é a proprieadade que será lida do proposalData
      maxInputValue: "max_installment_value" // Essa condition é a proprieadade que será lida do proposalData
    }
  },

  purchaseTime: {
    type: "customRadio",
    name: "purchase_time",
    options: [
      {
        id: "purchase_time_futuro",
        value: "future",
        label: "longo prazo",
        description: "pague parcelas menores, em mais tempo",
        data_testid: "simulation_purchase_time_futuro"
      },
      {
        id: "purchase_time_antes",
        value: "asap",
        label: "o quanto antes",
        description: "pague parcelas maiores, em menos tempo",
        data_testid: "simulation_purchase_time_antes"
      }
    ]
  }
}

import { useEffect, useState } from "react"

import { trackPageview } from "@garantidos/utils/tracking"

import { focusOnLogo } from "../../utils"

const PRIMARY = ".loadingPage__primaryText"
const SECONDARY = ".loadingPage__secondaryText"
const HIDE_CLASS = "loadingPage__text--hide"

const useLoadingPage = ({
	info,
	texts,
	dialog,
	loadingDone,
	onLoadingDone,
	pageviewTracking
}) => {
	const [primaryText, setPrimaryText] = useState(null)
	const [secondaryText, setSecondaryText] = useState(null)
	const [showTexts, setShowTexts] = useState([])
	const [currentIndex, setCurrentIndex] = useState(null)
	const [loadingText, setLoadingText] = useState("Carregando...")

	const mountShowTexts = () => {
		if (!texts || !info || !texts.length) return
		const textsToShow = []
		if (info) {
			textsToShow.push({
				duration: 4,
				text: info,
				positionClass: "loadingPage__text--left"
			})
		}
		texts.forEach((text, index) => {
			const positionClass =
				dialog && index % 2 !== 0
					? "loadingPage__text--right"
					: "loadingPage__text--left"
			textsToShow.push({
				duration: 6,
				text,
				positionClass
			})
		})

		setShowTexts(textsToShow)
	}

	const changeIndex = () => {
		if (!showTexts.length) return
		if (currentIndex === null || currentIndex === showTexts.length - 1) {
			setCurrentIndex(0)
			return
		}
		setCurrentIndex(currentIndex + 1)
	}

	const hideTexts = () => {
		const primary = document.querySelector(PRIMARY)
		const secondary = document.querySelector(SECONDARY)
		if (primary) primary.classList.add(HIDE_CLASS)
		if (secondary) secondary.classList.add(HIDE_CLASS)
	}

	const addTexts = () => {
		setTimeout(() => {
			setPrimaryText(showTexts[currentIndex])

			if (primaryText) {
				const prevIndex =
					currentIndex === 0 ? showTexts.length - 1 : currentIndex - 1

				setSecondaryText(showTexts[prevIndex])
			}

			const primaryElement = document.querySelector(PRIMARY)
			const secondaryElement = document.querySelector(SECONDARY)
			if (primaryElement) primaryElement.classList.remove(HIDE_CLASS)
			if (secondaryElement) secondaryElement.classList.remove(HIDE_CLASS)

			setTimeout(() => {
				changeIndex()
			}, showTexts[currentIndex].duration * 1000 - 150)
		}, 150)
	}

	const changeTexts = () => {
		if (currentIndex === null) return
		if (primaryText) hideTexts()
		addTexts()
	}

	useEffect(() => {
		pageviewTracking()
	}, [])

	useEffect(() => {
		focusOnLogo()
		setTimeout(() => {
			mountShowTexts()
		}, 1000)
	}, [])

	useEffect(() => {
		changeIndex()
	}, [showTexts])

	useEffect(() => {
		if (loadingDone) {
			setTimeout(() => {
				setLoadingText("Carregamento finalizado")
				setTimeout(() => {
					onLoadingDone()
				}, 2500)
				return
			}, 2000)
		} else {
			changeTexts()
		}
	}, [currentIndex])

	return {
		primaryText,
		secondaryText,
		loadingText
	}
}

export default useLoadingPage

import IconTelefone from "@mobi/ds/static/icons/outlined/telefone.svg"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

const { CPF_NO_CREDIT, POTENTIAL_CPF, CPF_NO_GROUP } = errorsEnum

const pageTitles = {
  [POTENTIAL_CPF]: "Formulário de contato | Consórcio | Itaú",
  [CPF_NO_CREDIT]: "Formulário de interesse | Consórcio | Itaú",
  [CPF_NO_GROUP]: "Formulário de interesse | Consórcio | Itaú"
}

const image = {
  filename: "rejectioninteractive",
  alt: "ilustração de uma pessoa bebendo um café quente olhando pela janela"
}

const trackingInfos = {
  [POTENTIAL_CPF]: {
    pageview: {
      ga: {
        label: "IT:NL:NCC:ConsorcioE2E:ErroInstabilidade"
      },
      segment: {
        event: "page viewed",
        data: {
          step: "alternative flow",
          step_name: "potential cpf"
        }
      }
    }
  },
  [CPF_NO_CREDIT]: {
    pageview: {
      ga: {
        label: "IT:NL:NCC:ConsorcioE2E:CPFNaoAprovado"
      },
      segment: {
        event: "page viewed",
        data: {
          step: "alternative flow",
          step_name: "without credit"
        }
      }
    }
  },
  [CPF_NO_GROUP]: {
    pageview: {
      ga: {
        label: "IT:NL:NCC:ConsorcioE2E:CPFSemOfertas"
      },
      segment: {
        event: "page viewed",
        data: {
          step: "alternative flow",
          step_name: "error no offers"
        }
      }
    }
  }
}

const titles = {
  [POTENTIAL_CPF]: "infelizmente, não pudemos continuar sua simulação",
  [CPF_NO_CREDIT]: "infelizmente, ainda não temos uma oferta para você",
  [CPF_NO_GROUP]:
    "desculpe, não encontramos uma oferta para o valor selecionado"
}

const infos = {
  [POTENTIAL_CPF]: [
    "Desculpe, estamos passando por instabilidade.",
    "Por favor, insira o valor do bem desejado e entraremos em contato para continuar sua simulação!"
  ],

  [CPF_NO_CREDIT]: [
    "No momento, seu CPF não foi aprovado para contratar o consórcio. Mas isso não é definitivo!",
    "Por favor, insira o valor do bem desejado e entraremos em contato assim que tivermos uma oferta pra você!"
  ],
  [CPF_NO_GROUP]: [
    "Por favor, simule novamente com outro valor e veja nossas ofertas"
  ]
}

const buttonInfos = {
  [POTENTIAL_CPF]: [
    {
      label: "solicitar contato por telefone",
      variant: "secondary",
      Icon: IconTelefone,
      value: "PHONE",
      gaEventObject: {
        action: "ConsorcioE2E",
        label: "BTN:ConsorcioE2E:ErroInstabilidade:ContatoTelefone",
        page: {
          nome: "IT:NL:NCC:ConsorcioE2E:ErroInstabilidade"
        }
      },
      segmentInfo: {
        event: "send lead",
        data: {
          step: "alternative flow",
          step_name: "potential cpf"
        }
      }
    }
  ],
  [CPF_NO_CREDIT]: [
    {
      label: "registrar interesse",
      value: "",
      gaEventObject: {
        action: "ConsorcioE2E",
        label: "BTN:ConsorcioE2E:CPFNaoAprovado:RegistrarInteresse",
        page: {
          nome: "IT:NL:NCC:ConsorcioE2E:CPFNaoAprovado"
        }
      },
      segmentInfo: {
        event: "send lead",
        data: {
          step: "alternative flow",
          step_name: "without credit"
        }
      }
    }
  ],
  [CPF_NO_GROUP]: [
    {
      label: "voltar para o início",
      variant: "secondary",
      action: "restart",
      value: "",
      gaEventObject: {
        action: "ConsorcioE2E",
        label: "BTN:ConsorcioE2E:CPFSemGrupo:VoltarParaInicio",
        page: {
          nome: "IT:NL:NCC:ConsorcioE2E:CPFSemGrupo"
        }
      },
      segmentInfo: {
        event: "return step inicio",
        data: {
          step: "alternative flow",
          step_name: "error no offers"
        }
      }
    },
    {
      label: "simular novamente",
      action: "prevStep",
      value: "",
      gaEventObject: {
        action: "ConsorcioE2E",
        label: "BTN:ConsorcioE2E:CPFSemGrupo:SimularNovamente",
        page: {
          nome: "IT:NL:NCC:ConsorcioE2E:CPFSemGrupo"
        }
      },
      segmentInfo: {
        event: "return step",
        data: {
          step: "alternative flow",
          step_name: "error no offers"
        }
      }
    }
  ]
}

export { pageTitles, image, trackingInfos, titles, infos, buttonInfos }

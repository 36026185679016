export const fields = {
  assetValue: {
    type: "currency",
    name: "asset_input_value",
    label: "insira o valor do bem",
    data_testid: "rejection-interactive_value",
    rules: {
      minInputValue: "min_asset_value", // Essa condition é a proprieadade que será lida do proposalData
      maxInputValue: "max_asset_value" // Essa condition é a proprieadade que será lida do proposalData
    }
  }
}

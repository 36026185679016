import { Helmet } from "react-helmet"

import { HousingAlert } from "@garantidos/components"

import If from "@mobi/components/If"
import Accordion from "@mobi/ds/components/Accordion"
import Button from "@mobi/ds/components/Button"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"
import IconAviso from "@mobi/ds/static/icons/outlined/aviso.svg"

import ContactCard from "../../components/ContactCard/index"
import Splitted from "../../components/Splitted"
import AssetCard from "./components/AssetCard/index"

import useAsset from "./hooks"

import "./style.scss"

const Asset = () => {
  const {
    availableAssets,
    handlePrevStep,
    handleAssetSelect,
    handleDropFlow,
    title,
    infoMessage,
    handleCloseInfo,
    handleAccordionOpen,
    simulation_type,
    fromSummary
  } = useAsset()

  return (
    <div className="asset">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Splitted
        contentType="simulation"
        data-aos="fade"
        data-aos-duration="150"
        data-aos-offset="-1000"
      >
        <div
          className="asset__formWrapper"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-offset="-1000"
        >
          <If
            condition={!fromSummary}
            renderIf={
              <>
                <Stepper
                  title="vamos simular seu consórcio"
                  index={3}
                  max={3}
                />

                <Text as="h1" className="asset__title">
                  pronto! escolha a carta que combina com você
                </Text>
                <div
                  role="text"
                  aria-label="Separamos algumas opções de consórcio que são feitas com você"
                >
                  <Text as="p" aria-hidden className="asset__subtext">
                    Separamos algumas opções de consórcio que são{" "}
                    <b>feitas com você.</b>
                  </Text>
                </div>
              </>
            }
            renderElse={
              <>
                <IconAviso className="asset__icon" />
                <Text as="h1" className="asset__title full">
                  o grupo selecionado já está cheio
                </Text>
                <Text as="p" className="asset__subtext">
                  Mas não se preocupe! Selecionamos outros grupos similares ao
                  escolhido.
                </Text>
              </>
            }
          />
          <HousingAlert
            focusOnText
            message={infoMessage}
            closeAlertCallback={handleCloseInfo}
          />

          <div>
            <div className="asset__assetsList">
              {availableAssets.slice(0, 1).map((asset, index) => (
                <div
                  className="asset__assetsListItem"
                  aria-label={`Opção de consórcio ${index + 1}`}
                  key={asset.assetCode}
                >
                  <AssetCard
                    {...asset}
                    isHighlight
                    simulationType={simulation_type}
                    assetOrder={index}
                    handleAssetSelect={handleAssetSelect}
                  />
                </div>
              ))}
            </div>
            <div className="asset__accordion">
              <Accordion
                title="Conheça outras opções"
                index={1}
                onOpen={handleAccordionOpen}
              >
                <Text as="p" className="asset__info">
                  Conheça mais algumas opções que podem fazer sentido para você
                </Text>
                <ul
                  className="asset__assetsList"
                  aria-label="Lista com opções de grupos de consórcio"
                >
                  {availableAssets.slice(1).map((asset, index) => (
                    <li
                      className="asset__assetsListItem"
                      aria-label={`Opção de consórcio ${index + 2}`}
                      key={asset.assetCode}
                    >
                      <AssetCard
                        {...asset}
                        simulationType={simulation_type}
                        assetOrder={index}
                        handleAssetSelect={handleAssetSelect}
                      />
                    </li>
                  ))}
                </ul>
              </Accordion>
            </div>

            <Text
              as="h2"
              className="asset__subtitle"
              aria-label="não encontrou o consórcio ideal?"
            >
              <span aria-hidden>
                não encontrou o <b>consórcio ideal</b>?
              </span>
            </Text>

            <div className="asset__buttonContainer">
              <Button
                variant="secondary"
                fluid
                className="asset__button"
                onClick={handlePrevStep}
                data-testid="asset_back_button"
              >
                simular novamente
              </Button>
            </div>

            <ContactCard
              text="Você também pode pedir que um dos nossos especialistas te ajudem a escolher o melhor consórcio para o seu momento."
              onClickPhone={() => handleDropFlow("PHONE")}
            />
          </div>
        </div>
      </Splitted>
    </div>
  )
}

export default Asset

/* eslint-disable arrow-body-style */
import { formatters } from "@garantidos/utils"

import removeSpecialChars from "@mobi/utils/formatters/removeSpecialChars"

const { toCurrency } = formatters

const validatorsDictionary = {
  domainMultipleChar: {
    rule: () => (value) => {
      if (!value) return true
      const reg = /@[a-zA-Z0-9]\b/gm
      return !value.match(reg)
    },
    message: () => "email inválido"
  },
  minInputValue: {
    rule: ({ conditionValue, proposalData }) => {
      return (value) => {
        const condition = proposalData[conditionValue]
        if (condition === undefined) return true
        return value >= condition
      }
    },
    message: ({ conditionValue, proposalData }) => {
      const condition = proposalData[conditionValue]
      if (condition === undefined) return ""
      return `digite um valor igual ou acima de ${toCurrency(condition)}`
    }
  },
  maxInputValue: {
    rule: ({ conditionValue, proposalData }) => {
      return (value) => {
        const condition = proposalData[conditionValue]
        if (condition === undefined) return true
        return value <= condition
      }
    },
    message: ({ conditionValue, proposalData }) => {
      const condition = proposalData[conditionValue]
      if (condition === undefined) return ""
      return `digite um valor igual ou abaixo de ${toCurrency(condition)}`
    }
  },

  minAge: {
    rule: ({ conditionValue }) => {
      return (value) => !isUnderAge(value, conditionValue)
    },
    message: ({ conditionValue = 18 }) =>
      `A idade mínima é de ${conditionValue} anos`
  },

  maxAge: {
    rule: ({ conditionValue }) => {
      return (value) => !isAboveOrEqualAge(value, conditionValue)
    },
    message: ({ conditionValue = 18 }) =>
      `A idade máxima é de ${conditionValue} anos`
  },

  distinctCpf: {
    rule: ({ proposalData: { document_number } }) => {
      return (value) => {
        return removeSpecialChars(value) !== document_number
      }
    },
    message: () => "CPF do(a) companheiro(a) não pode ser o mesmo do proponente"
  },

  distinctDocument: {
    rule: ({ proposalData: { partner_document } }) => {
      return (value) => {
        return removeSpecialChars(value) !== partner_document
      }
    },
    message: () =>
      "O número de documento não pode ser o mesmo do(a) companheiro(a)"
  },

  validIssuingDate: {
    rule: ({ proposalData: { birthdate } }) => {
      return (value) => {
        // eslint-disable-next-line no-use-before-define
        const formattedDate = toDate(value)
        const nascimento = new Date(birthdate)
        const dataEmissao = new Date(formattedDate)
        return nascimento < dataEmissao
      }
    },
    message: () => "A data de emissão deve ser posterior à data de nascimento"
  },

  hasSpecialChars: {
    rule: () => (value) => {
      if (!value) return true
      const reg = /[^a-zA-Z\u00C0-\u00FF\d\s]+/gim
      return !value.match(reg)
    },
    message: () => "digite apenas números ou letras"
  },

  limitCharacter: {
    rule: () => (value) => {
      return characterLength(value)
    },
    message: () => "documento inválido"
  }
}

export const isUnderAge = (birthday, age = 18) => {
  const instances = birthday.split("/")
  const [day, month, year] = instances
  return (
    new Date(Number(year) + age, Number(month) - 1, day, "00", "00", "00") >
    new Date()
  )
}

export const isAboveOrEqualAge = (birthdate, age = 99) => {
  const instances = birthdate.split("/")
  const [day, month, year] = instances
  return (
    new Date() >=
    new Date(Number(year) + (age + 1), Number(month) - 1, day, "00", "00", "00")
  )
}

export const characterLength = (value) => {
  return value.length >= 2 && value.length <= 20
}

export default validatorsDictionary

const toDate = (dateString) => {
  if (!dateString) return 0
  const [day, month, year] = dateString.split("/")
  const date = `${month}/${day}/${year}`
  return date
}

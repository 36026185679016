import useField from "./hooks"

import "./style.scss"

const Field = ({
  field,
  form: {
    control,
    unregister,
    setValue,
    setError,
    context,
    validatorsDictionary,
    formValidators,
    updateFormValidators,
    clearErrors,
  },
  ...props
}) => {
  const { FieldComponent } = useField({
    field,
    context,
    validatorsDictionary,
    props,
    control,
    unregister,
    setValue,
    setError,
    formValidators,
    updateFormValidators,
    clearErrors,
  })

  if (!props.loading) {
    return FieldComponent
  } else {
    return <div className="ds-field--is-loading"></div>
  }
}

export default Field

import Text from "@mobi/ds/components/Text"

import "./style.scss"

const HiddenRadioOption = ({ image, imageAlt, Icon, label, description }) => {
	return (
		<div className="fieldHiddenRadio__labelContainer" role="radio" aria-hidden>
			{image && (
				<img
					src={image}
					alt={imageAlt}
					className="fieldHiddenRadio__image"
					aria-hidden
				/>
			)}
			{Icon && (
				<div className="fieldHiddenRadio__iconContainer">
					<Icon aria-hidden className="fieldHiddenRadio__icon" />
				</div>
			)}
			<div className="fieldHiddenRadio__textContainer" role="text" aria-hidden>
				<Text as="p" className="fieldHiddenRadio__label" aria-hidden>
					{label}
				</Text>
				{!!description && (
					<Text as="p" className="fieldHiddenRadio__description" aria-hidden>
						{description}
					</Text>
				)}
			</div>
		</div>
	)
}

export default HiddenRadioOption

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"
import IconTooltip from "@mobi/ds/static/icons/outlined/tooltip.svg"

import "./style.scss"

const RejectedResidenceCard = ({ isAmerican, handleAnotherSimulation }) => (
  <div className="rejectedResidenceCard">
    <div className="rejectedResidenceCard__container">
      <IconTooltip className="rejectedResidenceCard__icon" aria-hidden="true" />
      <div className="rejectedResidenceCard__textWrapper">
        <Text as="p" className="rejectedResidenceCard__text">
          <If
            condition={isAmerican}
            renderIf={
              "Não é possível seguir com a contratação do consórcio para pessoas com nacionalidade americana"
            }
            renderElse={
              "Não é possível seguir com a contratação do seu consórcio com residência fiscal nesse País"
            }
          />
        </Text>
      </div>
    </div>
    <Button
      fluid
      className="rejectedResidenceCard__button"
      type="button"
      value="simule agora"
      variant="action"
      onClick={handleAnotherSimulation}
    >
      simular com outro cpf
    </Button>
  </div>
)

export default RejectedResidenceCard

import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"

import MotorcycleIlustration from "images/woman-motorcycle.svg"

import useCrossModal from "./hooks"

import "./style.scss"

const CrossModal = () => {
  const { isVisible, setIsVisible, buttons } = useCrossModal()

  const { primary, secondary } = buttons

  return (
    <div className="crossModal">
      <Modal
        type="center"
        show={isVisible}
        primary={primary}
        secondary={secondary}
        customClass="crossModal__modal"
        onCloseClick={() => setIsVisible(false)}
      >
        <div className="crossModal__content">
          <MotorcycleIlustration className="crossModal__image" aria-hidden />
          <Text as="h1" className="crossModal__title">
            Olá, nós somos o Consórcio Itaú
          </Text>

          <Text as="p" className="crossModal__description">
            Você saiu do fluxo de iCarros e esta entrando na experiência do Itaú
            que é responsável pelos consórcios
          </Text>
        </div>
      </Modal>
    </div>
  )
}

export default CrossModal

import { Controller } from "react-hook-form"

import mergeDeep from "@mobi/utils/object/mergeDeep"

import { IdsSelect } from "@ids/react"

const messages = {
  required: "Campo obrigatório",
  emptyMessage: "Nenhuma opção encontrada",
  isSearchingMessage: "buscando...",
  generalError: "Um erro ocorreu ao consultar os dados",
  minLength: (value) =>
    `digite ao menos ${value} caracteres para fazer a busca`,
  isValidValue: "Nenhuma opção encontrada",
}

export default function IdsSelectField({
  name,
  formProps: { control },
  defaultValue,
  validators: { rules: propRules = {}, messages: propMessages = {} } = {},
  disabled,
  required,
  options,
  hasEmptyOption = true,
  ...rest
}) {
  const rules = mergeDeep(propRules, { required })
  const customMessages = mergeDeep(messages, propMessages)

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <IdsSelect
          name={field.name}
          ref={field.ref}
          value={field.value}
          disabled={field.disabled || disabled}
          required={required}
          onChange={field.onChange}
          onBlur={field.onBlur}
          formFieldProps={{
            errorMessage: customMessages[fieldState.error?.type],
            invalid:
              fieldState.isTouched && fieldState.isDirty && fieldState.invalid,
          }}
          {...rest}
        >
          {hasEmptyOption && <option />}
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </IdsSelect>
      )}
    />
  )
}

export const contentImage = {
  softlead: {
    title: "vamos nos conhecer",
    subtitle: "realize seu sonho com facilidade, sem juros e sem entrada",
    image: {
      url: "https://a.storyblok.com/f/159381/x/17084d7470/woman-window-ilustration.svg",
      alt: "Ilustração de uma mulher sentada em uma janela tomando um café vista de lado"
    }
  },
  simulation: {
    title: "seja contemplado por sorteio ou lance.",
    subtitle:
      "dê um lance nos sorteios mensais pra acelerar seu sonho somos um dos consórcios que mais contempla",
    image: {
      url: "https://a.storyblok.com/f/159381/x/82b26dd8e2/man-truck-ilustration.svg",
      alt: "Ilustração de um homem pilotando um caminhão laranjado visto de frente"
    }
  },
  lead: {
    title: "estamos quase lá",
    subtitle:
      "lembre-se que seu consórcio estará ativo após o pagamento da primeira parcela",
    image: {
      url: "https://a.storyblok.com/f/159381/x/2e9fe990f5/women-motorcycle-ilustration.svg",
      alt: "Ilustração de uma mulher pilotando uma moto laranjada vista de lado"
    }
  }
}

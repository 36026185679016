import { Helmet } from "react-helmet"

import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Modal from "@mobi/ds/components/Modal"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import Splitted from "../../components/Splitted"

import useSms from "./hooks"

import "./style.scss"

import Loader from "./Loader/index"
import SmsEdit from "./SmsEdit/index"

const Sms = () => {
  const {
    stepTitle,
    onSubmitCallback,
    handlePrevStep,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    telephone,
    setTelephone,
    counter,
    formattedCounter,
    handleResendToken,
    tokenLabels,
    counterAnnouncer,
    focusOnFirstInput,
    infoMessage,
    handleCloseInfoAlert,
    toggleModal,
    modalShow,
    modalType,
    successMessage,
    setSuccessMessage,
    handleCloseSuccessAlert,
    handleResetTimer,
    refTimeout,
    loading,
    info,
    editNumberButtonHandler,
    clearVerificationCode
  } = useSms()
  const { token } = fields
  const { isValid } = form

  return (
    <>
      <Helmet>
        <title>{stepTitle}</title>
      </Helmet>

      <If
        condition={loading}
        renderIf={<Loader info={info} dialog />}
        renderElse={
          <div className="leadSms">
            <Splitted
              contentType="softlead"
              data-aos="fade"
              data-aos-duration="150"
              data-aos-offset="-1000"
            >
              <div
                className="leadSms__formWrapper"
                data-aos="fade-left"
                data-aos-duration="500"
                data-aos-offset="-1000"
              >
                <Stepper title="vamos nos conhecer" index={2} max={3} />
                <If
                  condition={!modalShow}
                  renderIf={
                    <HousingAlert
                      type="error"
                      message={errorMessage}
                      setMessage={setErrorMessage}
                      focusOnText
                      closeAlertCallback={focusOnFirstInput}
                      overrideFocus
                    />
                  }
                />
                <HousingAlert
                  message={infoMessage}
                  closeAlertCallback={handleCloseInfoAlert}
                  focusDelay={1000}
                  focusOnText
                />

                <HousingAlert
                  type="success"
                  focusOnText
                  message={successMessage}
                  closeAlertCallback={handleCloseSuccessAlert}
                  focusDelay={1000}
                />

                <h1 className="leadSms__title">
                  insira o código que enviamos para o seu celular via SMS
                </h1>
                <Form onSubmit={onSubmitCallback} form={form}>
                  <Field
                    field={token}
                    form={form}
                    size={4}
                    labels={tokenLabels}
                    onlyNumbers
                    inputMode="numeric"
                  />
                  <If
                    condition={counterAnnouncer}
                    renderIf={
                      <div
                        className="leadSms__counterAnnouncer"
                        role="alert"
                        aria-live="assertive"
                      >
                        {counterAnnouncer}
                      </div>
                    }
                  />
                  <If
                    condition={counter > 0}
                    renderIf={
                      <div
                        role="text"
                        aria-label={`Não recebeu o SMS? peça um novo em ${formattedCounter()}`}
                      >
                        <Text as="p" className="leadSms__text bold" aria-hidden>
                          Não recebeu o SMS?
                        </Text>

                        <Text
                          id="dialogDesc"
                          as="p"
                          className="leadSms__text"
                          aria-hidden
                        >
                          {`você pode pedir um novo código em ${formattedCounter()}`}
                        </Text>
                      </div>
                    }
                    renderElse={
                      <>
                        <Text as="p" className="leadSms__text bold">
                          Não recebeu o SMS?
                        </Text>
                        <Button
                          variant="action"
                          className="leadSms__link"
                          onClick={() => handleResendToken()}
                          type="link"
                        >
                          reenviar código
                        </Button>
                      </>
                    }
                  />
                  <>
                    <span
                      role="text"
                      aria-label={`Seu número não é ${telephone}?`}
                    >
                      <Text as="p" className="leadSms__text second" aria-hidden>
                        Seu número não é{" "}
                        <span className="leadSms__text bold">{`${telephone}`}</span>
                        ?
                      </Text>
                    </span>
                    <Button
                      variant="action"
                      className="leadSms__link"
                      onClick={() => editNumberButtonHandler()}
                      type="button"
                    >
                      editar número
                    </Button>
                  </>

                  <Sender
                    onPrev={handlePrevStep}
                    isNextDisabled={!isValid}
                    nextLabel="confirmar o código token"
                    data_testid="submit"
                  />
                </Form>
              </div>
            </Splitted>
          </div>
        }
      />
      <Modal
        type={modalType}
        show={modalShow}
        onCloseClick={() => toggleModal(false)}
      >
        <SmsEdit
          telephone={telephone}
          setTelephone={setTelephone}
          setSuccessMessage={setSuccessMessage}
          handleResetTimer={handleResetTimer}
          toggleModal={toggleModal}
          refTimeout={refTimeout}
          clearVerificationCode={clearVerificationCode}
        />
      </Modal>
    </>
  )
}

export default Sms

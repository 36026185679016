import { useContext, useEffect, useRef, useState } from "react"
import { useForm as useFormHook } from "react-hook-form"

import { getDefaultValues } from "../utils"

const useForm = ({
  fields,
  mode = "onChange",
  context = {},
  validatorsDictionary = {},
  shouldUnregister = true,
}) => {
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    setError,
    formState: { isValid, errors },
    clearErrors,
    resetField,
    getFieldState,
    getValues,
    watch,
  } = useFormHook({
    mode,
    shouldUnregister,
    ...(fields && { defaultValues: getDefaultValues(fields) }),
  })

  const [formValidators, setFormValidators] = useState({})
  const initialized = useRef({
    data: false,
    validators: false,
  })
  const useFormContext = useContext(context)
  const { proposalContext, errorFields } = useFormContext

  const defaultValidatorObject = {
    rules: { validate: {} },
    messages: {},
  }

  useEffect(() => {
    if (!initialized.current.data) {
      initialized.current.data = true
      return
    }
    if (!initialized.current.validators) {
      initialized.current.validators = true
      return
    }
    trigger()
  }, [proposalContext, formValidators])

  const updateFormValidators = (
    inputName = null,
    inputValidators = defaultValidatorObject,
  ) => {
    if (!inputName || !inputValidators) return
    setFormValidators((prevState) => ({
      ...prevState,
      [inputName]: inputValidators,
    }))
  }

  return {
    form: {
      control,
      trigger,
      handleSubmit,
      isValid,
      setValue,
      setError,
      context,
      validatorsDictionary,
      formValidators,
      updateFormValidators,
      clearErrors,
      resetField,
      errors,
      getFieldState,
      getValues,
      watch,
    },
  }
}

export default useForm

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const STEP_CONTEXT = {
  stepId: "consorcio-e2e-simulation-5",
  stepName: "simulation",
  stepNumber: 5
}

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:ConsorcioE2E:SimularValorTotalOuValorParcela",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "5-simulation",
      step_name: "simulation",
      step_order: 5
    }
  }

  trackPageview({ ga, segment })
}

const back = () => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:SimularValorTotalOuValorParcela:Voltar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:SimularValorTotalOuValorParcela"
    }
  }
  const segment = {
    event: "return step",
    data: {
      step: "simulation"
    }
  }

  trackEvent({ ga, segment })
}

const advance = ({
  urgency,
  min_asset,
  max_asset,
  min_installment,
  max_installment,
  simulation_type,
  asset_value,
  installment_value
}) => {
  const ga = {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:SimularValorTotalOuValorParcela:Continuar",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:SimularValorTotalOuValorParcela"
    }
  }

  const segment = {
    event: "advance step",
    data: {
      step: "simulation",
      urgency,
      min_asset,
      max_asset,
      min_installment,
      max_installment,
      simulation_type,
      asset_value: simulation_type === "asset_value" ? asset_value : 0,
      installment_value:
        simulation_type === "installment_value" ? installment_value : 0
    }
  }

  trackEvent({ ga, segment })
}

const purchaseTime = (value) => {
  if (!value) return
  const purchaseTimeParser = {
    future: {
      gaText: "LongoPrazo"
    },
    asap: {
      gaText: "OQuantoAntes"
    }
  }

  const selectedValue = purchaseTimeParser[value] ?? {}
  const { gaText } = selectedValue

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:SimularValorTotalOuValorParcela:Expectativa:${gaText}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:SimularValorTotalOuValorParcela"
    }
  }

  trackEvent({ ga })
}

const simulationType = (value) => {
  if (!value) return
  const simulationTypeParser = {
    asset_value: {
      gaText: "ValorTotal"
    },
    installment_value: {
      gaText: "ValorDaParcela"
    }
  }
  const selectedValue = simulationTypeParser[value] ?? {}
  const { gaText } = selectedValue

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:SimularValorTotalOuValorParcela:ComoPrefereSimular:${gaText}`,
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:SimularValorTotalOuValorParcela"
    }
  }

  trackEvent({ ga })
}

export { pageview, back, advance, purchaseTime, simulationType }

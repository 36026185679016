const cards = [
  {
    image: {
      alt: "ilustração de uma mão fazendo sinal de positivo",
      filename: "imagem-card-cadastro-positivo"
    },
    title: "cadastro positivo",
    description:
      "Ative seu Cadastro Positivo para nos fornecer mais informações sobre você;"
  },
  {
    image: {
      alt: "ilustração cartão de CPF mostrando frente e verso",
      filename: "imagem-card-receita-federal"
    },
    title: "receita federal",
    description:
      "Consulte a Receita Federal para ver se existe alguma pendência no seu CPF;"
  },
  {
    image: {
      alt: "ilustração de um calendário ao fundo com moedas e um relógio a frente",
      filename: "imagem-card-negociacao-de-dividas"
    },
    title: "renegociações de dívidas",
    description:
      "Verfique se não existem renegociações em andamento, contas atrasadas, etc."
  }
]

export { cards }

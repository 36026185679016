import { Helmet } from "react-helmet"

import NextSteps from "@garantidos/components/NextSteps"

import FinishedHiringHeader from "./components/FinishedHiringHeader"
import FinishedHiringMarketplace from "./components/FinishedHiringMarketplace"
import LikertComponent from "./components/LikertComponent"
import WhatsappShare from "./components/WhatsappShare"

import useFinishedHiring from "./hooks"

import "./style.scss"

import { blok } from "./data"

const FinishedHiring = () => {
  const { marketplaceCallback, title } = useFinishedHiring()
  return (
    <div className="finishedHiring">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FinishedHiringHeader />
      <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="500">
        <NextSteps
          blok={blok}
          className="finishedHiring__nextSteps innerContainer"
        />
      </div>
      <LikertComponent />
      <WhatsappShare />
      <FinishedHiringMarketplace
        marketplaceCallback={marketplaceCallback}
        cards="consorcioDefault"
      />
    </div>
  )
}

export default FinishedHiring

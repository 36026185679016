import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"
import IconWhatsapp from "@mobi/ds/static/icons/outlined/whatsapp.svg"

import useWhatsappShare from "./hooks"

import "./style.scss"

const WhatsappShare = () => {
  const { handleButtonClick } = useWhatsappShare()
  return (
    <div className="share">
      <div className="share__container">
        <IconWhatsapp className="share__icon" aria-hidden />
        <div className="share__content">
          <Text as="p" className="share__title">
            Agora que você está mais perto do seu sonho, compartilhe essa
            possibilidade com alguém!
          </Text>
          <Text as="p" className="share__subtitle">
            Conhece alguém que também está em busca de um consórcio? Compartilhe
            com seus amigos!
          </Text>
          <a
            href="https://api.whatsapp.com/send?&text=Ol%C3%A1!%20Simulei%20um%20cons%C3%B3rcio%20Ita%C3%BA%20e%20lembrei%20de%20vc.%20Simule%20tamb%C3%A9m%20e%20aproveite%20todos%20os%20benef%C3%ADcios.%0A%0Ahttps%3A%2F%2Fmeu.itau%2Fconsorcios"
            className="share__button"
            rel="noreferrer"
            target="_blank"
            onClick={handleButtonClick}
            aria-label="compartilhar no WhatsApp"
            role="button"
          >
            compartilhar
          </a>
        </div>
      </div>
    </div>
  )
}

export default WhatsappShare

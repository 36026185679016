import If from "@mobi/components/If"
import Loader from "@mobi/ds/components/Loader"
import { Step, StepProvider } from "@mobi/libraries/step"

import useStepHandler from "./hooks"

import "./style.scss"

const StepHandler = ({
  context,
  steps,
  cpfPayloadKey = "cpf",
  retrieveProposalRoute = "retrieve-proposal",
  retrieveProposalRecaptcha = false,
  scrollOnChange = { scroll: true, preventScroll: [] },
  onChangeCallback = null,
  startStep = "",
  fallbackStep = "",
  mockData = null,
  useLoaderComponent = true,
}) => {
  const {
    currentStep,
    loaded,
    isLoading,
    isLoaderComponentVisible,
    isStepVisible,
  } = useStepHandler({
    context,
    cpfPayloadKey,
    retrieveProposalRoute,
    retrieveProposalRecaptcha,
    scrollOnChange,
    onChangeCallback,
    startStep,
    mockData,
    useLoaderComponent,
  })

  return (
    <>
      <If
        condition={isLoaderComponentVisible}
        renderIf={
          <Loader
            ariaBusy={false}
            isVisible={isLoading}
            loadingText="Carregando página"
            finishedLoadingText="Carregamento finalizado"
          />
        }
      />
      <If
        condition={loaded}
        renderIf={
          <StepProvider
            steps={steps}
            start={startStep || currentStep || fallbackStep}
          >
            <div
              className={`stepsContainer${
                isStepVisible ? "--fadeIn" : "--fadeOut"
              }`}
            >
              <Step />
            </div>
          </StepProvider>
        }
      />
    </>
  )
}

export default StepHandler

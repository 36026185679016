import { useEffect, useState } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { scrollToTop } from "@garantidos/utils"
// TODO: normalizar como consumir os utils. Se de cada pasta individualmente, ou se por um index na pasta de utils
import { toPhone } from "@garantidos/utils/js/formatters/phone"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import validatorsDictionary from "pages/Proposal/hooks/validators"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useSms = () => {
  const {
    sms: { title: stepTitle }
  } = steps

  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })

  const { setValue } = form

  const { next, prev } = useStep()
  const {
    proposalData,
    fetchProposalData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    stepInfo,
    cpfData,
    resendToken
  } = useProposalContext()

  const isMobile = useMediaQuery("mobile")

  const [telephone, setTelephone] = useState(
    toPhone(proposalData.proponent_phone)
  )
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState("confirmando código")
  const [counter, setCounter] = useState(1)
  const [counterAnnouncer, setCounterAnnouncer] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [infoMessage, setInfoMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState("")

  const { formatSendPayload } = usePayloadFormatters(fields)
  const { previousStep, nextStep } = stepInfo
  const { hashedCpf } = cpfData
  const { redirectToRejectionPage } = useRejectionRedirect()

  let refTimeout = null

  const tokenLabels = [
    "digite o primeiro número do código",
    "digite o segundo número do código",
    "digite o terceiro número do código",
    "digite o quarto número do código"
  ]

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      trackers.confirmToken()
      setLoading(true)

      const payload = formatSendPayload(formData)

      await sendProposalData({
        payload,
        step: "sms-confirmation",
        hasLoading: false
      })
      setInfo("código confirmado")
      setTimeout(() => {
        next(nextStep)
      }, 2000)
    } catch (error) {
      setSuccessMessage("")
      setLoading(false)
      if (error.data?.token_invalid) {
        setTimeout(() => {
          trackers.wrongCode()
        }, 500)
        error.message = error.requestMessage
        errorHandler(error)
        return
      }
      if (error?.statusCode === 409) {
        redirectToRejectionPage(errorsEnum.SAME_DOCUMENT_PROPOSAL)
      }
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()
      await fetchProposalData({ step: previousStep })
      prev(previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const getCounter = () => {
    const seconds = `${counter % 60}`
    const minutes = `${Math.floor(counter / 60)}`
    return {
      seconds,
      minutes
    }
  }

  const formattedCounter = () => {
    const { seconds, minutes } = getCounter()
    const minuteLabel = minutes <= 1 ? "minuto" : "minutos"
    const secondsLabel = seconds <= 1 ? "segundo" : "segundos"
    return minutes === "0"
      ? `${seconds.padStart(2, "0")} ${secondsLabel}`
      : `${minutes} ${minuteLabel} e ${seconds.padStart(
          2,
          "0"
        )} ${secondsLabel}`
  }

  useEffect(() => {
    if (counter === 30) {
      setCounterAnnouncer(
        `você pode pedir um novo código em ${formattedCounter()} caso não tenha recebido`
      )
      setTimeout(() => {
        setCounterAnnouncer(false)
      }, 1000)
    }
    if (counter !== undefined && counter !== null && counter === 0) {
      trackers.finishedTimer()
      setCounterAnnouncer(
        `você pode utilizar o código recebido ou pedir um novo caso não tenha recebido `
      )
      setTimeout(() => {
        setCounterAnnouncer(false)
      }, 1000)
    }
    if (counter > 0) {
      refTimeout = setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter])

  const handleResendToken = async () => {
    setCounter(0)
    try {
      trackers.resendToken()
      await resendToken({})
      setCounter(120)
      setInfoMessage("Reenviamos um novo código para o número informado")
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleResetTimer = () => {
    clearTimeout(refTimeout)
    setCounter(120)
  }

  const clearVerificationCode = () => {
    setValue("verification_code", "")
  }

  const focusOnFirstInput = () => {
    document.getElementById("verification_code-digit-0").focus()
  }

  const handleCloseInfoAlert = () => {
    focusOnFirstInput()
    setInfoMessage("")
  }

  const handleCloseSuccessAlert = () => {
    focusOnFirstInput()
    setSuccessMessage("")
  }

  const closeAllAlerts = () => {
    setErrorMessage("")
    setInfoMessage("")
    setSuccessMessage("")
  }

  const toggleModal = (state = null) => {
    if (!modalShow) {
      trackers.editNumber()
    }
    if (state === null) {
      setModalShow(!modalShow)
    } else {
      setModalShow(state)
    }
  }

  const editNumberButtonHandler = () => {
    toggleModal(true)
    setTimeout(
      () =>
        document
          .getElementsByClassName("ds-modal-container__close-button")[0]
          .focus(),
      1500
    )
    closeAllAlerts()
  }

  useEffect(() => {
    setCounter(119)
    setTimeout(() => {
      scrollToTop()
    }, 100)
  }, [])

  useEffect(() => {
    if (loading) return
    trackers.pageview(hashedCpf)
  }, [loading])

  return {
    stepTitle,
    onSubmitCallback,
    handlePrevStep,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    telephone,
    setTelephone,
    counter,
    formattedCounter,
    handleResendToken,
    loading,
    info,
    tokenLabels,
    counterAnnouncer,
    infoMessage,
    handleCloseInfoAlert,
    toggleModal,
    modalType: isMobile ? "center" : "pop",
    modalShow,
    setModalShow,
    successMessage,
    setSuccessMessage,
    handleCloseSuccessAlert,
    handleResetTimer,
    refTimeout,
    editNumberButtonHandler,
    clearVerificationCode
  }
}

export default useSms

import { Helmet } from "react-helmet"

import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"

import Splitted from "../../components/Splitted"
import StepsHeader from "../../components/StepsHeader"

import linksEnum from "pages/Proposal/constants/linksEnum"

import useSoftlead from "./hooks"

import "./style.scss"

const Softlead = () => {
  const {
    onSubmitCallback,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    preventNumberOnInput,
    title,
    handleLinkTracking
  } = useSoftlead()
  const { cpf, name, email, phone } = fields
  const { isValid } = form

  return (
    <>
      <StepsHeader />

      <div className="softlead">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Splitted
          contentType="softlead"
          data-aos="fade"
          data-aos-duration="150"
          data-aos-offset="-1000"
        >
          <div
            className="softlead__formWrapper"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-offset="-1000"
          >
            <Stepper title="vamos nos conhecer" index={1} max={3} />
            <HousingAlert
              message={errorMessage}
              setMessage={setErrorMessage}
              focusOnText
              type="error"
            />
            <Text
              as="h1"
              size="lg"
              className="softlead__title"
              aria-label="vamos começar nos conhecendo, carinha piscando"
            >
              vamos começar nos conhecendo ;)
            </Text>
            <Form onSubmit={onSubmitCallback} form={form}>
              <Field field={cpf} form={form} data-testid={cpf.name} />
              <Field
                field={name}
                form={form}
                data-testid={name.name}
                maxLength="50"
                onInput={preventNumberOnInput}
              />
              <Field
                maxLength="60"
                field={email}
                form={form}
                data-testid={email.name}
                allowSuggestions={false}
              />
              <Field field={phone} form={form} data-testid={phone.name} />

              <Text as="p" className="softlead__text">
                Ao continuar, autorizo os gestores de banco de dados do cadastro
                positivo a disponibilizarem ao Itaú Unibanco S.A o meu histórico
                de crédito. Estou ciente dos&nbsp;
                <a
                  className="softlead__link"
                  href={linksEnum.AUTHORIZATION_TERM}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                    handleLinkTracking({
                      gaLabel: "TermosDeAutorizacao",
                      ga4Label: "termos-de-autorizacao"
                    })
                  }
                >
                  termos de autorização
                </a>
                &nbsp; e que poderei efetuar o&nbsp;
                <a
                  className="softlead__link"
                  href={linksEnum.POSITIVE_RECORD}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                    handleLinkTracking({
                      gaLabel: "Cancelamento",
                      ga4Label: "cancelamento"
                    })
                  }
                >
                  cancelamento
                </a>
                &nbsp; a qualquer momento.
              </Text>

              <Text as="p" className="softlead__text">
                Autorizo também a consulta ao&nbsp;
                <a
                  className="softlead__link"
                  href={linksEnum.CREDIT_INFORMATION_SYSTEM}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                    handleLinkTracking({
                      gaLabel: "SistemaInformacoesDeCredito",
                      ga4Label: "sistema-de-informacoes-de-credito"
                    })
                  }
                >
                  sistema de informações de crédito
                </a>
                &nbsp; (SCR) do Banco Central, estou ciente que meus dados serão
                registrados no SCR e de acordo com a&nbsp;
                <a
                  className="softlead__link"
                  href={linksEnum.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                    handleLinkTracking({
                      gaLabel: "PoliticaPrivacidade",
                      ga4Label: "politica-de-privacidade"
                    })
                  }
                >
                  política de privacidade
                </a>
                .
              </Text>

              <Text as="p" className="softlead__text">
                Também autorizo que o Itaú acesse as minhas informações e estou
                ciente de que poderei ser contatado por e-mail, telefone,
                WhatsApp ou SMS, para recebimento de informações e ofertas de
                produtos e serviços do Conglomerado Itaú Unibanco.
              </Text>

              <Text as="p" className="softlead__text">
                Saiba mais sobre como tratamos dados pessoais na nossa Política
                de Privacidade disponível em nossos sites e aplicativos.
              </Text>

              <Sender
                showPrev={false}
                isNextDisabled={!isValid}
                nextLabel="avançar"
                data_testid="submit"
                id="softlead-next-button"
              />
            </Form>
          </div>
        </Splitted>
      </div>
    </>
  )
}

export default Softlead

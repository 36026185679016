const headerTexts = {
  default: {
    icon: "enviar",
    title: "Suas informações foram enviadas com sucesso",
    subtitle:
      "Iremos entrar em contato com você em breve. Os valores e as condições podem mudar devido a disponibilidade do consórcio."
  },
  afterAsset: {
    icon: "enviar",
    title: "Suas informações foram enviadas ;)",
    subtitle:
      "Vamos entrar em contato com você em breve! Os valores e condições podem mudar, de acordo com a disponibilidade do consórcio, ok?"
  },
  rejectedCpf: {
    icon: "brilho-nos-olhos",
    title: "pronto, registramos o seu interesse em adquirir um consórcio",
    subtitle:
      "Caso conseguirmos aprovação no futuro, podemos entrar em contato para ajudar você acelerar a realização do seu sonho! Confira algumas dicas abaixo para aumentar as suas chances de aprovação."
  }
}
export { headerTexts }

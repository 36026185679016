import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"
import Tracking from "@mobi/ds/components/Tracking"

import "./style.scss"

const NextSteps = ({ blok, className, callback = () => {} }) => {
	const { title, steps, subTextBold, subTextNormal, buttonText, buttonLink } =
		blok

	return (
		<div className={`nextSteps ${className}`}>
			<div className="nextSteps__container">
				<Text as="p" className="nextSteps__title">
					{title}
				</Text>
				<div className="nextSteps__tracking">
					<Tracking trackList={steps} />
				</div>
				<If
					condition={subTextBold}
					renderIf={
						<div className="nextSteps__subtextContainer">
							<Text as="p" className="nextSteps__subTextBold">
								{subTextBold}
							</Text>
							<Text as="p" className="nextSteps__subTextNormal">
								{subTextNormal}
							</Text>
						</div>
					}
				/>
				<If
					condition={buttonLink}
					renderIf={
						<div className="nextSteps__button-container">
							<Button
								className="nextSteps__button"
								tagName="a"
								href={buttonLink}
								target="_blank"
								rel="noreferrer"
								onClick={callback}
							>
								{buttonText}
							</Button>
						</div>
					}
				/>
			</div>
		</div>
	)
}

export default NextSteps

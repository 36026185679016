import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"
import IconTelefone from "@mobi/ds/static/icons/outlined/telefone.svg"

import "./style.scss"

const ContactCard = ({ text = "", onClickPhone = () => {} }) => (
  <div className="contactCard">
    <Text as="p" className="contactCard__text">
      {text}
    </Text>
    <Button
      leftIcon={
        <IconTelefone aria-hidden="true" className="contactCard__icon" />
      }
      className="contactCard__button"
      onClick={onClickPhone}
      variant="action"
    >
      solicitar contato por telefone
    </Button>
  </div>
)

export default ContactCard

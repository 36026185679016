import Text from "@mobi/ds/components/Text"
import IconInovacao from "@mobi/ds/static/icons/outlined/inovacao.svg"

import "./style.scss"

const SuggestionCard = () => {
  return (
    <div className="suggestionCard">
      <div className="suggestionCard__container">
        <IconInovacao className="suggestionCard__icon" aria-hidden="true" />
        <div className="suggestionCard__textWrapper">
          <Text as="p" className="suggestionCard__text">
            <span
              role="text"
              aria-label="dica: use no máximo 30% de sua renda mensal"
            >
              <b>dica:</b> use no máximo 30% de sua renda mensal
            </span>
          </Text>
        </div>
      </div>
    </div>
  )
}

export default SuggestionCard

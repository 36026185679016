import { Helmet } from "react-helmet"

import { Sender } from "@garantidos/components"

import Splitted from "../../components/Splitted"
import Info from "./components/Info"
import TwoWayHeader from "./components/TwoWayHeader"

import useTwoWay from "./hooks"

import "./style.scss"

const TwoWay = () => {
  const {
    onSubmitCallback,
    handlePrevStep,
    errorMessage,
    setErrorMessage,
    title
  } = useTwoWay()

  return (
    <div className="twoWay">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Splitted
        contentType="softlead"
        className="ilustrationSplitted twoWay__splitted"
        headerContent={
          <TwoWayHeader
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        }
      >
        <div className="twoWay__formWrapper">
          <Info />

          <div className="twoWay__buttonsContainer">
            <Sender
              onPrev={handlePrevStep}
              onNext={onSubmitCallback}
              nextLabel="simular"
              data_testid="submit"
            />
          </div>
        </div>
      </Splitted>
    </div>
  )
}

export default TwoWay

import Likert from "@mobi/ds/components/Likert"
import Text from "@mobi/ds/components/Text"

import useLikerComponent from "./hooks"

import "./style.scss"

const LikertComponent = () => {
  const {
    handleSubmitCallback,
    handleExperienceCallback,
    handleOptionCallback
  } = useLikerComponent()

  return (
    <div className="likertComponent">
      <Text as="h2" className="likertComponent__title">
        avalie a experiência
      </Text>
      <Likert
        dataCallback={handleSubmitCallback}
        experienceClickCallback={handleExperienceCallback}
        optionsClickCallback={handleOptionCallback}
      />
    </div>
  )
}

export default LikertComponent

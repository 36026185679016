import { useEffect, useState } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { onlyNumbersOnInput } from "@garantidos/utils"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import validatorsDictionary from "pages/Proposal/hooks/validators"
import { steps } from "pages/Proposal/steps"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useComplementaryLead = () => {
  const [hasBankData, setHasBankData] = useState(false)
  const {
    stepInfo,
    proposalData,
    fetchProposalData,
    sendProposalData,
    cpfData,
    errorMessage,
    setErrorMessage,
    isLoading,
    errorHandler
  } = useProposalContext()

  const { next, prev } = useStep()
  const { hashedCpf } = cpfData
  const { previousStep, nextStep } = stepInfo
  const {
    complementaryLead: { title }
  } = steps

  const { bank_data } = proposalData

  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })

  const { formatSendPayload } = usePayloadFormatters(fields)

  const isMobile = useMediaQuery("mobile")

  const handleSelectChange = (
    property,
    value,
    inputName = "",
    byLabel = false
  ) => {
    if (byLabel) {
      value = fields[inputName].options.find(
        (option) => option.value === value
      )?.label
    }
    trackers.selectChange(property, value)
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      trackers.back()

      await fetchProposalData({ step: previousStep })
      prev(previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const onSubmitCallback = async (formData) => {
    try {
      if (bank_data === undefined) return
      trackers.advance()
      const payload = formatSendPayload(formData)

      await sendProposalData({ payload })
      next(nextStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    bank_data ? setHasBankData(true) : setHasBankData(false)
  }, [bank_data])

  useEffect(() => {
    trackers.pageview(hashedCpf)
  }, [])

  useEffect(() => {
    if (proposalData.bank_data) return
    delete proposalData.bank_account_digit_number
    delete proposalData.bank_account_number
    delete proposalData.bank_agency_number
    delete proposalData.bank_code
  }, [proposalData.bank_data])

  return {
    form,
    handlePrevStep,
    onSubmitCallback,
    errorMessage,
    setErrorMessage,
    hasBankData,
    isMobile,
    handleSelectChange,
    onlyNumbersOnInput,
    title
  }
}
export default useComplementaryLead

const loadingData = {
  info: "só mais alguns segundos",
  texts: [
    "Olá! você sabia que no consórcio você pode ser contemplado com lance?",
    "Que legal! Como funciona?",
    "São verificados os maiores percentuais ofertados e estes são contemplados, como em um leilão.",
    "Qual o percentual mínimo e máximo para realizar as ofertas de lances?",
    "O percentual mínimo para se ofertar em um lance é de 10% do valor de referência + fundo de reserva + taxa de adm e o percentual máximo é o total do saldo devedor da cota.",
    "Ah! Eu posso quitar meu financiamento?",
    "Claro!  Você ainda pode resgatar seu crédito em dinheiro após 180 dias da contemplação",
    "Amei!!! Já quero!!!"
  ],
  image: {
    filename: "ilustra-casa-moto-carro",
    alt: "ilustração de uma casa com um carro e uma moto parados em frente, há uma pessoa em cima da moto"
  }
}

const categoryOptions = [
  {
    id: "category-carros",
    value: "VEICULOS LEVES",
    title: "carros",
    label: "Carros, conquiste seu carro novo ou usado",
    description: "conquiste seu carro novo ou usado",
    image: {
      filename: "consorcio-categoria-carros",
      alt: "Ilustração de uma mulher dirigindo um carro com uma passageira ao lado"
    },
    tracking: "Carro"
  },
  {
    id: "category-imoveis",
    value: "IMOVEL",
    title: "imóveis",
    label: "Imóveis, casas, apartamentos e terrenos urbanos, ou reforma",
    description: "casas, aptos e terrenos urbanos, ou reforma",
    image: {
      filename: "consorcio-categoria-imoveis",
      alt: "Ilustação de um homem segurando uma xícara de café visto através de um janela aberta"
    },
    tracking: "Imovel"
  },
  {
    id: "category-motos",
    value: "MOTOCICLETAS",
    title: "motos",
    label:
      "Motos, novas ou usadas, acima de 150 cilindradas, e motos elétricas",
    description: "motos novas ou usadas, acima de 150 cc, e motos elétricas",
    image: {
      filename: "consorcio-categoria-motos",
      alt: "Ilustração de um motoqueiro visto através de um retrovisor"
    },
    tracking: "Moto"
  },
  {
    id: "category-pesados",
    value: "VEICULOS PESADOS",
    title: "pesados",
    label: "Pesados, caminhões, vans, ônibus e implementos rodoviários",
    description: "caminhões, vans, ônibus e implementos rodoviários",
    image: {
      filename: "consorcio-categoria-pesados",
      alt: "Ilustração de um homem pilotando um caminhão visto de lado"
    },
    tracking: "Pesado"
  }
]

export { loadingData, categoryOptions }

const contentAccordions = [
  {
    title: "assembleia",
    content: [
      "Assembleia é o momento em que são definidos os contemplados do mês, por sorteio ou por lance!",
      "Pra participar, é só estar em dia com as parcelas. Ah, e as assembleias não são reuniões: elas são 100% automatizadas, e os resultados ficam disponíveis para consulta nos canais do Itaú às 18h do dia da assembleia."
    ],
    trackingInfo: "Assembleia"
  },
  {
    title: "lance",
    content: [
      "Lance é um valor opcional que você pode ofertar pra tentar antecipar sua contemplação e, assim, poder usar a carta de crédito para comprar o seu bem.",
      "Se o seu lance for um dos maiores no grupo, a contemplação já acontece naquele mês e o valor é abatido do seu Consórcio: você escolhe se prefere reduzir o valor das suas parcelas, ou a quantidade de parcelas restantes!",
      "Você pode dar lance usando seu próprio dinheiro, parte da sua carta de crédito e seu FGTS (para consórcio de imóveis).",
      "Ah, e você só paga o lance se for contemplado."
    ],
    trackingInfo: "Lance"
  },
  {
    title: "contemplação",
    content: [
      "Chegou a hora de resgatar o seu bem ;)",
      "A contemplação pode acontecer por sorteio ou por lance, e você pode escolher usar seu crédito na hora ou mais pra frente.",
      "Você deve estar com as parcelas em dia e passará por uma análise de crédito. Após a aprovação, você pode seguir com o processo de compra do seu bem.",
      "Para veículos usados e imóveis, será cobrada uma tarifa de avaliação, e ao fim do processo você terá o crédito liberado para comprar seu carro novo, seu imóvel dos sonhos e mais!"
    ],
    trackingInfo: "Contemplacao"
  },
  {
    title: "encerramento",
    content: [
      "O grupo é encerrado quando todo mundo já recebeu o crédito. O valor não usado do fundo reserva será pago de volta aos clientes que estiverem em dia com o consórcio!"
    ],
    trackingInfo: "Encerramento"
  }
]

const footerTexts = [
  "No empréstimo e no financiamento, você recebe o valor do bem na hora da contratação.",
  "Com o consórcio, você se planeja para o futuro pagando parcelas menores e recebe o crédito após a contemplação"
]

export { contentAccordions, footerTexts }

import Icon from "@garantidos/components/Icon"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Input from "@mobi/ds/components/Input"
import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"
import TextMessage from "@mobi/ds/components/TextMessage"

import useAriaSelect from "./hooks"

import "./style.scss"

const AriaSelect = ({
  label,
  placeholder = "selecione",
  searchPlaceholder = "buscar opções",
  name,
  options = [],
  form,
  field,
  required = true,
  onChange,
  dataTestId
}) => {
  const {
    handleModalOpen,
    handleModalClose,
    actualPlaceholder,
    showModal,
    searchText,
    handleOnChange,
    currentOptions,
    selectedOption,
    actualSelectedOption,
    handleOptionSelected,
    handleClearSelection,
    control,
    errorMessage,
    searchStatus,
    hideStatus,
    setSearchText
  } = useAriaSelect({
    placeholder,
    options,
    form,
    field,
    name,
    onChange,
    dataTestId
  })

  return (
    <div
      data-testid={dataTestId}
      className={`ds-aria-select ${
        errorMessage ? "ds-aria-select--error" : ""
      }`}
    >
      <label
        className="ds-aria-select__label"
        id={`ds-aria-select-label-${name}`}
        aria-hidden
        htmlFor={`ds-aria-select-button-${name}`}
      >
        {label}
      </label>
      <button
        type="button"
        id={`ds-aria-select-button-${name}`}
        className={
          selectedOption
            ? "ds-aria-select__button--option-selected"
            : "ds-aria-select__button"
        }
        aria-label={`${label}. ${actualPlaceholder}`}
        aria-describedby={`ds-aria-select-error-message-${name}`}
        onClick={handleModalOpen}
      >
        {actualPlaceholder}
        <Icon
          icon="seta-direita"
          className="ds-aria-select__icon"
          aria-hidden
        />
      </button>
      <TextMessage
        show={!!errorMessage}
        message={errorMessage}
        id={`ds-aria-select-error-message-${name}`}
      />

      <Input.Text
        name={name}
        control={control}
        label={label}
        placeholder={placeholder}
        required={required}
        className="ds-aria-select__hidden-input"
      />
      <Modal
        type="pop"
        show={showModal}
        onCloseClick={() => handleModalClose(selectedOption)}
      >
        <div className="ds-aria-select__modal-content">
          <Text
            as="h2"
            className="ds-aria-select__modal-title"
            id={`ds-aria-select_modal-title-${name}`}
          >
            {label}
          </Text>
          <div className="ds-aria-select__modal-input__wrapper">
            <input
              type="text"
              placeholder={searchPlaceholder}
              className="ds-aria-select__modal-input"
              id={`ds-aria-select-modal-label-${name}`}
              name={`ds-aria-select-modal-name-${name}`}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onBlur={hideStatus}
            />
            <Icon
              icon="busca"
              className="ds-aria-select__modal-input__icon"
              aria-hidden
            />
          </div>
          <div className="ds-aria-select__options-list">
            {currentOptions.map(({ label, value }) => {
              const radioId = `ds-aria-select-list-item-${value}`
              return (
                <div>
                  <label
                    className="ds-aria-select__list-item__label"
                    htmlFor={radioId}
                    key={radioId}
                  >
                    <span aria-hidden>{label}</span>
                    <input
                      name={name}
                      id={radioId}
                      value={value}
                      className="ds-aria-select__list-item__radio"
                      type="radio"
                      checked={actualSelectedOption === value}
                      onChange={handleOptionSelected}
                      aria-label={label}
                      aria-describedby={`ds-aria-select_modal-title-${name}`}
                    />
                  </label>
                </div>
              )
            })}
          </div>
          <If condition={searchStatus}>
            <p
              role="status"
              className="ds-aria-select__modal-status"
              aria-live="polite"
            >
              {searchStatus}
            </p>
          </If>
          <div className="ds-aria-select__buttons-wrapper">
            <Button
              className="ds-aria-select__modal-button"
              id="ds-aria-select-modal-button"
              onClick={() => handleModalClose(actualSelectedOption)}
              fluid
              data-testid="salvar"
            >
              salvar
            </Button>
            <Button
              className="ds-aria-select__modal-button"
              id="ds-aria-select-modal-button"
              onClick={handleClearSelection}
              variant="secondary"
              fluid
            >
              limpar seleção
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AriaSelect

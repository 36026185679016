import { Helmet } from "react-helmet"

import If from "@mobi/components/If"

import FinishedHeader from "./components/FinishedHeader"
import FinishedMarketplace from "./components/FinishedMarketplace/index"
import FinishedTips from "./components/FinishedTips/index"
import LikertComponent from "./components/LikertComponent"

import useFinished from "./hooks"

const Finished = () => {
  const { marketplaceCallback, title, has_credit, has_asset } = useFinished()
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FinishedHeader hasCredit={has_credit} hasAsset={has_asset} />
      <If condition={!has_credit} renderIf={<FinishedTips />} />
      <If condition={has_credit} renderIf={<LikertComponent />} />
      <FinishedMarketplace
        marketplaceCallback={marketplaceCallback}
        cards={has_credit ? "consorcioDefault" : "consorcioNoCredit"}
      />
    </div>
  )
}

export default Finished

const useAssetCard = ({
  isHighlight,
  assetValue,
  installmentValue,
  percentageTotalFee,
  remainingMonths
}) => {
  const buttonVariant = isHighlight
    ? { variant: "primary" }
    : { variant: "secondary" }
  const infoCard = {
    installment_value: {
      mainLabel: `${remainingMonths} parcelas de`,
      mainValue: installmentValue,
      subTextLabel: `crédito de `,
      subTextValue: assetValue,
      taxLabel: `taxa de adm média: `,
      taxValue: percentageTotalFee
    },
    asset_value: {
      mainLabel: `crédito de`,
      mainValue: assetValue,
      subTextLabel: `parcelas: `,
      subTextValue: `${remainingMonths}x ${installmentValue}`,
      labelValue: `${remainingMonths} vezes de ${installmentValue}`,
      taxLabel: `taxa de adm média: `,
      labelTax: `taxa de administração média`,
      taxValue: percentageTotalFee
    }
  }

  return { infoCard, buttonVariant }
}

export default useAssetCard

import { useEffect } from "react"

import configure from "@mobi/ds/configure"

// Utils
import { isBankVariant } from "utils/location/index"

const useBankVariant = (
  pathnameVariant = "personnalite",
  themeVariant = "personnalite"
) => {
  useEffect(() => {
    const variant = isBankVariant(pathnameVariant) ? themeVariant : "varejo"
    configure({ variant })
  }, [])
}

export default useBankVariant

import { useEffect } from "react"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useSmsLoader = () => {
  const { cpfData } = useProposalContext()
  const { hashedCpf } = cpfData

  useEffect(() => {
    trackers.pageview(hashedCpf)
  }, [])
}

export default useSmsLoader

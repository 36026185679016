import { MarketplaceCards } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import "./style.scss"

const FinishedHiringMarketplace = ({ marketplaceCallback, cards }) => (
  <div className="finishedHiringMarketplace">
    <Text
      as="h2"
      className="finishedHiringMarketplace__title"
      data-aos="fade"
      data-aos-duration="200"
      data-aos-offset="-50"
    >
      achamos que pode te interessar
    </Text>
    <div
      className="finishedHiringMarketplace__cardsContainer"
      data-aos="fade"
      data-aos-duration="200"
      data-aos-delay="100"
      data-aos-offset="-50"
    >
      <MarketplaceCards
        app="consorcio"
        marketplaceCallback={marketplaceCallback}
        cards={cards}
      />
    </div>
  </div>
)

export default FinishedHiringMarketplace

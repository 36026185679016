import { useEffect, useRef } from "react"

import { focusOnLogo } from "../../utils"

const useHousingAlert = ({
	message,
	setMessage,
	returnFocus,
	closeAlertCallback,
	overrideFocus,
	focusOnText,
	focusDelay
}) => {
	const alertElementRef = useRef(null)
	const callerElementRef = useRef(null)

	const scrollToAlert = () => {
		alertElementRef.current.scrollIntoView()
	}

	const focusOnOpen = () => {
		const identifier = focusOnText ? "#dsAlert p" : ".ds-alert__close"
		const dsAlert = alertElementRef.current.querySelector(identifier)

		if (dsAlert) {
			dsAlert.setAttribute("tabindex", "0")
			dsAlert.focus()
		}
	}

	const focusOnCaller = (element) => {
		if (element) element.focus()
	}

	const handleCloseAlert = () => {
		if (closeAlertCallback) closeAlertCallback()
		if (!closeAlertCallback || !overrideFocus) {
			if (returnFocus) focusOnCaller(callerElementRef.current)
			else focusOnLogo()
		}
		if (setMessage) setMessage("")
	}

	useEffect(() => {
		if (message) {
			const activeElement = document.activeElement
			if (activeElement) {
				callerElementRef.current = activeElement
			}
			scrollToAlert()
			setTimeout(() => {
				focusOnOpen()
			}, focusDelay)
			return
		}
		callerElementRef.current = null
	}, [message])

	return {
		handleCloseAlert,
		alertElementRef
	}
}

export default useHousingAlert

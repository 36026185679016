import { Helmet } from "react-helmet"

import { Icon, Sender } from "@garantidos/components"

import AriaLabel from "@mobi/components/AriaLabel"
import If from "@mobi/components/If"
import Accordion from "@mobi/ds/components/Accordion"
import Button from "@mobi/ds/components/Button"
import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import ContactCard from "../../components/ContactCard/index"
import Splitted from "../../components/Splitted"
import Info from "../TwoWay/components/Info"
import TwoWayHeader from "../TwoWay/components/TwoWayHeader"

import useSimulationSummary from "./hooks"

import "./style.scss"

const SimulationSummary = () => {
  const {
    title,
    formattedData,
    summaryFaq,
    form,
    onSubmitCallback,
    handleDropFlow,
    handlePrevStep,
    handleAboutButtonClick,
    isAboutModalVisible,
    handleCloseModal
  } = useSimulationSummary()

  const {
    assetValue,
    category,
    installmentValue,
    groupSituation,
    bidInfo = {},
    remainingMonths,
    installmentDueDay,
    totalValue
  } = formattedData

  const isMobile = useMediaQuery("mobile")

  return (
    <>
      <div className="simulationSummary">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Splitted
          contentType="simulation"
          data-aos="fade"
          data-aos-duration="150"
          data-aos-offset="-1000"
          hasFloatingButton
        >
          <div className="simulationSummary__formWrapper">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              data-aos-offset="-1000"
            >
              <Text as="h1" className="simulationSummary__title">
                resumo da carta
              </Text>
              <AriaLabel
                ariaText={`crédito de ${assetValue} pra comprar ${category}, por apenas ${installmentValue} por mês`}
              >
                <Text
                  as="p"
                  className="simulationSummary__subtitle"
                  aria-hidden
                >
                  crédito de <span>{assetValue}</span> pra comprar {category},
                  por apenas <span>{installmentValue}</span> por mês
                </Text>
              </AriaLabel>

              <div className="simulationSummary__infoCard">
                <div className="simulationSummary__infoCardContent">
                  <Icon
                    icon="lideranca"
                    className="simulationSummary__infoCardIcon"
                  />
                  <Text as="p" className="simulationSummary__infoCardText">
                    <b>{groupSituation}</b>
                  </Text>
                </div>
                <If
                  condition={bidInfo}
                  renderIf={
                    <>
                      <div className="simulationSummary__infoCardContent">
                        <Icon
                          icon="antecipacao-de-recebimento"
                          className="simulationSummary__infoCardIcon"
                        />
                        <Text
                          as="p"
                          className="simulationSummary__infoCardText"
                        >
                          <b>{bidInfo?.highlight}</b>
                        </Text>
                      </div>
                      <Text
                        as="p"
                        className="simulationSummary__infoCardText--complementary"
                      >
                        {bidInfo?.text}
                      </Text>
                    </>
                  }
                />
              </div>

              <div className="simulationSummary__summaryCard">
                <Text as="h2" className="simulationSummary__summaryTitle--card">
                  resumo do seu consórcio
                </Text>

                <div
                  className="simulationSummary__summaryCardItem"
                  aria-label={`valor do crédito ${assetValue}`}
                  role="text"
                >
                  <Text
                    as="p"
                    className="simulationSummary__summaryLabel"
                    aria-hidden
                  >
                    valor do crédito
                  </Text>
                  <Text
                    as="p"
                    className="simulationSummary__summaryValue"
                    aria-hidden
                  >
                    {assetValue}
                  </Text>
                </div>

                <div
                  className="simulationSummary__summaryCardItem"
                  aria-label={`valor médio das parcelas ${installmentValue}`}
                  role="text"
                >
                  <Text
                    as="p"
                    className="simulationSummary__summaryLabel"
                    aria-hidden
                  >
                    valor médio das parcelas
                  </Text>
                  <Text
                    as="p"
                    className="simulationSummary__summaryValue"
                    aria-hidden
                  >
                    {installmentValue}
                  </Text>
                </div>

                <div
                  className="simulationSummary__summaryCardItem"
                  aria-label={`quantidade de parcelas ${remainingMonths}`}
                  role="text"
                >
                  <Text
                    as="p"
                    className="simulationSummary__summaryLabel"
                    aria-hidden
                  >
                    quantidade de parcelas
                  </Text>
                  <Text
                    as="p"
                    className="simulationSummary__summaryValue"
                    aria-hidden
                  >
                    {remainingMonths}
                  </Text>
                </div>

                <div
                  className="simulationSummary__summaryCardItem"
                  aria-label="data da 1ª parcela até 3 dias úteis"
                  role="text"
                >
                  <Text
                    as="p"
                    className="simulationSummary__summaryLabel"
                    aria-hidden
                  >
                    data da 1ª parcela
                  </Text>
                  <Text
                    as="p"
                    className="simulationSummary__summaryValue"
                    aria-hidden
                  >
                    até 3 dias úteis
                  </Text>
                </div>

                <div
                  className="simulationSummary__summaryCardItem"
                  aria-label={`data das demais parcelas, todo dia ${installmentDueDay}`}
                  role="text"
                >
                  <Text
                    as="p"
                    className="simulationSummary__summaryLabel"
                    aria-hidden
                  >
                    data das demais parcelas
                  </Text>
                  <Text
                    as="p"
                    className="simulationSummary__summaryValue"
                    aria-hidden
                  >
                    {`todo dia ${installmentDueDay}`}
                  </Text>
                </div>

                <div
                  className="simulationSummary__summaryCardItem"
                  aria-label={`total a pagar + taxas ${totalValue}`}
                  role="text"
                >
                  <Text
                    as="p"
                    className="simulationSummary__summaryLabel"
                    aria-hidden
                  >
                    total a pagar + taxas
                  </Text>
                  <Text
                    as="p"
                    className="simulationSummary__summaryValue"
                    aria-hidden
                  >
                    {totalValue}
                  </Text>
                </div>
              </div>

              <Text as="h2" className="simulationSummary__summaryTitle--bold">
                o que compõe as suas parcelas
              </Text>

              <Accordion items={summaryFaq} />

              <div className="simulationSummary__about">
                <Text as="h2" className="simulationSummary__summaryTitle">
                  ficou com alguma dúvida?
                </Text>

                <Button
                  fluid
                  variant="secondary"
                  className="heroForm__button"
                  type="submit"
                  onClick={handleAboutButtonClick}
                >
                  conhecer melhor o consórcio
                </Button>
              </div>

              <ContactCard
                text="Você também pode pedir que um dos nossos especialistas te ajudem a escolher o melhor consórcio para o seu momento. "
                onClickPhone={() => handleDropFlow("PHONE")}
              />

              <div className="simulationSummary__hire">
                <Text
                  as="h2"
                  className="simulationSummary__summaryTitle--multiline"
                >
                  gostou desta opção? contrate agora mesmo!
                </Text>
                <Text as="p" className="simulationSummary__summaryDescription">
                  Faltam poucos passos pra você contratar seu consórcio
                </Text>
              </div>
            </div>
            <If
              condition={!isMobile}
              renderIf={
                <Sender
                  onPrev={handlePrevStep}
                  onNext={onSubmitCallback}
                  nextLabel="iniciar contratação"
                  data_testid="submit"
                  floatingButton
                  id="summary-simulation-next-button"
                />
              }
            />
          </div>
        </Splitted>
        <If
          condition={isMobile}
          renderIf={
            <Sender
              onPrev={handlePrevStep}
              onNext={onSubmitCallback}
              nextLabel="iniciar contratação"
              data_testid="submit"
              floatingButton
              id="summary-simulation-next-button"
            />
          }
        />
      </div>
      <Modal
        show={isAboutModalVisible}
        type="pop"
        onCloseClick={() => handleCloseModal()}
      >
        <TwoWayHeader showOnModal />
        <Info showOnModal />
      </Modal>
    </>
  )
}

export default SimulationSummary

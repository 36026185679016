import { UF_OPTIONS } from "pages/Proposal/constants/dropdownOptions"

export const fields = {
  cep: {
    type: "cep",
    name: "cep",
    label: "CEP",
    sendFormat: "cep"
  },
  street: {
    type: "text",
    name: "street",
    label: "endereço"
  },
  state: {
    type: "select",
    name: "state",
    label: "UF",
    sendFormat: "select",
    fetchFormat: "select",
    placeholder: "selecione",
    options: UF_OPTIONS
  },
  city: {
    type: "text",
    name: "city",
    label: "cidade"
  },
  neighborhood: {
    type: "text",
    name: "neighborhood",
    label: "bairro"
  },
  number: {
    type: "text",
    name: "number",
    label: "número"
  },
  complement: {
    type: "text",
    name: "complement",
    label: "complemento (opcional)",
    rules: {
      hasSpecialChars: true
    }
  }
}

import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const DEFAULT_PAGE_EVENT = "ErroInstabilidade"

const contactTypeParser = {
  PHONE: {
    gaText: "ContatoTelefone"
  },
  WHATSAPP: {
    gaText: "ContatoWhatsApp"
  }
}

const pageview = (pageEvents, errorCode, fromStep) => {
  const ga = {
    label: `IT:NL:NCC:ConsorcioE2E:${
      pageEvents[errorCode]?.gaText ?? DEFAULT_PAGE_EVENT
    }`
  }
  const segment = {
    event: "page viewed",
    data: {
      step: pageEvents[errorCode]?.segmentInfo,
      step_name: fromStep?.name
    }
  }

  trackPageview({ ga, segment })
}

const formErrored = (fromStep) => {}

const buttonCallback = (pageEvents, errorCode, trackEventObject, fromStep) => {
  const { gaText, segmentInfo = {} } = trackEventObject
  const { event, data = {} } = segmentInfo

  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2e:${
      pageEvents[errorCode]?.gaText ?? DEFAULT_PAGE_EVENT
    }:${gaText}`
  }

  data.step_name = fromStep?.name

  const segment = {
    event,
    data
  }

  trackEvent({ ga, segment })
}

const dropFlow = ({
  pageEvents,
  errorCode,
  contactType,
  stepContexts,
  fromStep
}) => {
  const ga = {
    action: "ConsorcioE2E",
    label: `BTN:ConsorcioE2E:${
      pageEvents[errorCode]?.gaText ?? DEFAULT_PAGE_EVENT
    }:${contactTypeParser[contactType]?.gaText}`,
    page: {
      nome: `IT:NL:NCC:ConsorcioE2E:${
        pageEvents[errorCode]?.gaText ?? DEFAULT_PAGE_EVENT
      }`
    }
  }

  const segment = {
    event: "send lead",
    data: {
      step: pageEvents[errorCode]?.segmentInfo,
      step_name: fromStep?.name,
      contact_type: contactType
    }
  }

  trackEvent({ ga, segment })
}

const formSubmit = (errorCode, stepContexts) => {}

const elementClicked = (errorCode, stepContexts, contactType) => {}

export {
  pageview,
  formErrored,
  buttonCallback,
  dropFlow,
  formSubmit,
  elementClicked
}

import { useEffect, useState } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { focusOnLogo, formatters } from "@garantidos/utils"

import Button from "@mobi/ds/components/Button"
import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import validatorsDictionary from "pages/Proposal/hooks/validators"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import {
  buttonInfos,
  image,
  infos,
  pageTitles,
  titles,
  trackingInfos
} from "./data"
import { fields } from "./fields"
import * as trackers from "./trackings"

const DEFAULT_PAGE_TITLE = "Formulário de contato | Consórcio | Itaú"
const DEFAULT_TITLE = "infelizmente, não pudemos continuar sua simulação"
const DEFAULT_INFO = [
  "Desculpe, estamos passando por instabilidade.",
  "Por favor, insira o valor do bem desejado e entraremos em contato para continuar sua simulação!"
]
const DEFAULT_BUTTON_INFO = {
  label: "enviar interesse",
  variant: "primary",
  gaEventObject: {
    action: "ConsorcioE2E",
    label: "BTN:ConsorcioE2E:ErroInstabilidade:ContatoTelefone",
    page: {
      nome: "IT:NL:NCC:ConsorcioE2E:ErroInstabilidade"
    }
  },
  segmentTrackingInfo: {
    event: "send lead",
    step: "alternative flow",
    step_name: "generic error"
  }
}

const useAlternativeFlow = () => {
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const isMobile = useMediaQuery("mobile")
  const { next, prev } = useStep()
  const {
    stepInfo,
    sendProposalData,
    proposalData,
    updateProposalData,
    fetchProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    cpfData
  } = useProposalContext()
  const { formatSendPayload } = usePayloadFormatters(fields)
  const { hashedCpf } = cpfData

  function getErrorCode() {
    if (stepInfo.currentStep === "offerUnavailable") {
      return errorsEnum.CPF_NO_CREDIT
    } else {
      const { errorCode } = proposalData
      return errorCode
    }
  }

  const errorCode = getErrorCode()

  const { redirectToRejectionPage } = useRejectionRedirect()
  const [trackingObject, setTrackingObject] = useState("")
  const [segmentTrackingInfos, setSegmentTrackingInfos] = useState({})
  const { previousStep } = stepInfo

  const setTrackers = (gaTracking, segmentTracking) => {
    setTrackingObject(gaTracking)
    setSegmentTrackingInfos(segmentTracking)
  }

  const restartFlow = async () => {
    try {
      await fetchProposalData({ step: "softlead" })
      prev("softlead")
    } catch (error) {
      errorHandler(error)
    }
  }

  const handlePrevStep = async () => {
    try {
      await fetchProposalData({ step: previousStep })

      prev(previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const buttonActions = {
    restart: restartFlow,
    prevStep: handlePrevStep
  }

  const handleClick = async (
    action,
    gaTracking,
    segmentTracking,
    payload = {}
  ) => {
    if (isLoading) return
    const callback = buttonActions[action]
    if (!callback) return
    trackers.buttonCallback(gaTracking, segmentTracking)
    await callback(payload)
  }

  const onSubmitCallback = async (formData, event) => {
    if (isLoading) return
    try {
      const buttonValue = event?.nativeEvent?.submitter?.value || "PHONE"

      trackers.advance(trackingObject, segmentTrackingInfos)
      trackers.formSubmit()
      trackers.elementClicked()

      const payload = formatSendPayload(formData)
      payload.contact_whatsapp = buttonValue === "WHATSAPP"

      await sendProposalData({ payload, flow: "bypass", step: "send-lead" })
      await updateProposalData({
        payload: { fromRejection: true }
      })

      next("finished")
    } catch (error) {
      if (error?.statusCode === 409) {
        redirectToRejectionPage(errorsEnum.SAME_DOCUMENT_PROPOSAL)
        return
      }
      errorHandler(error)
    }
  }

  const setFieldLimits = () => {
    const payload = {
      min_asset_value: 100,
      max_asset_value: 1_000_000
    }
    updateProposalData({ payload })
  }

  const mountButtons = (errorCode) => {
    const { isValid } = form
    const buttonInfo = buttonInfos[errorCode]
    if (!buttonInfo) {
      const { label, variant, gaEventObject, segmentTrackingInfo } =
        DEFAULT_BUTTON_INFO
      const button = (
        <Button
          variant={variant}
          fluid
          type="submit"
          disabled={!isValid}
          onClick={() => setTrackers(gaEventObject, segmentTrackingInfo)}
        >
          {label}
        </Button>
      )
      return button
    }
    const buttons = buttonInfo.map(
      ({ label, Icon, variant, value, gaEventObject, action, segmentInfo }) => (
        <Button
          key={`${label}-${errorCode}`}
          {...(Icon
            ? {
                leftIcon: (
                  <Icon
                    aria-hidden="true"
                    className="alternativeFlow__buttonIcon"
                  />
                )
              }
            : {})}
          className="alternativeFlow__button"
          variant={variant || "primary"}
          fluid
          type="submit"
          disabled={!isValid}
          onClick={() => {
            setTrackers(gaEventObject, segmentInfo)
            handleClick(action, gaEventObject, segmentInfo)
          }}
          {...(value ? { value } : {})}
        >
          {label}
        </Button>
      )
    )
    return buttons
  }

  useEffect(() => {
    setTimeout(focusOnLogo, 200)
    trackers.pageview(trackingInfos, errorCode, hashedCpf)
    setFieldLimits()
  }, [])

  return {
    pageTitle: pageTitles[errorCode] || DEFAULT_PAGE_TITLE,
    image,
    title: titles[errorCode] || DEFAULT_TITLE,
    info: infos[errorCode] || DEFAULT_INFO,
    Buttons: mountButtons(errorCode),
    form,
    fields,
    isMobile,
    onSubmitCallback,
    errorMessage,
    setErrorMessage,
    assetLimits: {
      max: formatters.toCurrency(proposalData.max_asset_value ?? 1_000_000),
      min: formatters.toCurrency(proposalData.min_asset_value ?? 100)
    },
    proposalData,
    errorCode
  }
}

export default useAlternativeFlow

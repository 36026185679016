import { trackEvent } from "@garantidos/utils/tracking"

const likertInteraction = (eventLabel, fromError, likertComment) => {
  const ga = {
    action: "ConsorcioE2E",
    category: "Likert",
    label: `BTN:ConsorcioE2E:ContatoSolicitadoSucesso:${eventLabel}`,
    page: {
      nome: fromError
        ? "IT:NL:NCC:ConsorcioE2E:SucessoSolicitacaoContatoErroInstabilidade"
        : "IT:NL:NCC:ConsorcioE2E:ContatoSolicitadoSucesso"
    }
  }

  const segment = {
    event: "likert interaction",
    data: {
      experience: eventLabel,
      comment: likertComment
    }
  }

  trackEvent({ ga, segment })
}

export { likertInteraction }

import { Helmet } from "react-helmet"

import { HousingAlert, Sender } from "@garantidos/components"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Modal from "@mobi/ds/components/Modal"
import Stepper from "@mobi/ds/components/Stepper"
import Text from "@mobi/ds/components/Text"
import IconSorriso from "@mobi/ds/static/icons/outlined/sorriso.svg"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import Splitted from "../../components/Splitted"
import CardInfo from "./components/CardInfo/index"
import EmailEdit from "./components/EmailEdit"
import SummaryInfo from "./components/SummaryInfo/index"

import useSummary from "./hooks"

import "./style.scss"

const Summary = () => {
  const {
    formattedData,
    handlePrevStep,
    onSubmitCallback,
    totalValues,
    installmentsValues,
    summaryValues,
    errorMessage,
    setErrorMessage,
    editEmailButtonHandler,
    email,
    setEmail,
    toggleModal,
    modalShow,
    modalType,
    successMessage,
    setSuccessMessage,
    handleCloseSuccessAlert
  } = useSummary()

  const isMobile = useMediaQuery("mobile")
  const { title, installmentValue } = formattedData

  return (
    <div className="summary">
      <Helmet>
        <title>{`${title} | Consórcio | Itaú`}</title>
      </Helmet>
      <Splitted
        contentType="lead"
        data-aos="fade"
        data-aos-duration="150"
        data-aos-offset="-1000"
        hasFloatingButton
      >
        <div className="summary__formWrapper">
          <div
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-offset="-1000"
          >
            <Stepper title="resumo" index={4} max={4} />
            <HousingAlert
              type="success"
              focusOnText
              message={successMessage}
              closeAlertCallback={handleCloseSuccessAlert}
              focusDelay={1000}
            />
            <If
              condition={errorMessage}
              renderIf={
                <div className="summary__alert">
                  <HousingAlert
                    message={errorMessage}
                    setMessage={setErrorMessage}
                    focusOnText
                    type={
                      errorMessage === "Proposta já enviada"
                        ? "success"
                        : "error"
                    }
                  />
                </div>
              }
            />
            <Text as="h1" className="summary__title">
              {title}
            </Text>
            <Text as="h2" className="summary__subtitle">
              confirme as informações e finalize a sua contratação
            </Text>

            <CardInfo value={installmentValue} Icon={IconSorriso} />

            <SummaryInfo
              totalValues={totalValues}
              installmentsValues={installmentsValues}
              summaryValues={summaryValues}
              email={email}
              setEmail={setEmail}
            />
            <Button
              variant="action"
              className="summary__link"
              onClick={() => editEmailButtonHandler()}
              type="button"
            >
              editar e-mail
            </Button>
          </div>
          <div className="summary__buttonContainer">
            <If
              condition={!isMobile}
              renderIf={
                <Sender
                  onPrev={handlePrevStep}
                  onNext={onSubmitCallback}
                  nextLabel="confirmar contratação"
                  data_testid="submit"
                  floatingButton
                  id="summary-next-button"
                />
              }
            />
          </div>
        </div>
      </Splitted>
      <If
        condition={isMobile}
        renderIf={
          <Sender
            onPrev={handlePrevStep}
            onNext={onSubmitCallback}
            nextLabel="confirmar contratação"
            data_testid="submit"
            floatingButton
            id="summary-next-button"
          />
        }
      />

      <Modal
        type={modalType}
        show={modalShow}
        onCloseClick={() => toggleModal(false)}
      >
        <EmailEdit
          setSuccessMessage={setSuccessMessage}
          toggleModal={toggleModal}
          newEmail={email}
          setNewEmail={setEmail}
        />
      </Modal>
    </div>
  )
}

export default Summary
